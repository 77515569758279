import * as React from "react";
const CloseIcon = (props) => (
  <svg width={24} height={24} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_23_126)">
      <path
        d="M20.4891 3.51091C15.8069 -1.1703 8.19233 -1.1703 3.51013 3.51091C-1.17004 8.19212 -1.17004 15.8087 3.51013 20.4899C5.85123 22.83 8.92566 23.9995 12.0001 23.9995C15.0746 23.9995 18.148 22.8299 20.4891 20.4899C25.1703 15.8087 25.1703 8.19212 20.4891 3.51091ZM16.9514 15.5375C17.3426 15.9287 17.3426 16.561 16.9514 16.9522C16.8586 17.0452 16.7484 17.119 16.627 17.1693C16.5056 17.2196 16.3755 17.2455 16.2441 17.2453C16.1127 17.2455 15.9825 17.2196 15.8612 17.1693C15.7398 17.119 15.6295 17.0452 15.5367 16.9522L12.0001 13.4145L8.46442 16.9512C8.2768 17.1387 8.02239 17.2442 7.75708 17.2443C7.62568 17.2444 7.49555 17.2186 7.37417 17.1683C7.25278 17.118 7.14253 17.0442 7.04973 16.9512C6.65856 16.56 6.65856 15.9267 7.04973 15.5365L10.5854 11.9999L7.04874 8.46323C6.65758 8.07206 6.65758 7.43876 7.04874 7.04858C7.43893 6.65741 8.07222 6.65741 8.46339 7.04858L12 10.5852L15.5367 7.04858C15.9278 6.65741 16.5602 6.65741 16.9513 7.04858C17.3425 7.43876 17.3425 8.07206 16.9513 8.46323L13.4147 11.9999L16.9514 15.5375Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_23_126">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CloseIcon;
