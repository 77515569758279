import { DatePicker, Modal } from "antd";
import React, { useEffect, useState } from "react";
import CloseIconSmall from "../../Components/CloseIconSmall";
import { dayjsDateFormat } from "../../utils";
import PrevIcon from "../../Components/PrevIcon";
import NextIcon from "../../Components/NextIcon";
import { useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";

const CustomDateTimeRangePicker = (props) => {
  const {
    preSelectedDate,
    isDateTimeRangePicker,
    isCalenderOpen,
    setDateTimeRangePicker,
    setIsCalenderOpen,
    setDate,
  } = props;

  let defaultValue = undefined;
  if (preSelectedDate) {
    defaultValue = dayjs(preSelectedDate, "D/M/YYYY");
  }

  // useEffect(() => {
  //   console.log("Preselected Date: ", preSelectedDate);
  // }, [preSelectedDate]);
  // const [selectedDate, setSelectedDate] = useState(
  //   preSelectedDate ? preSelectedDate : ""
  // );
  // const convertToDateObject = (dateString) => {
  //   // Split the dateString into day, month, and year parts
  //   const momentDate = moment(dateString, "DD/MM/YYYY");

  //   // Validate if the moment object is valid
  //   if (!momentDate.isValid()) {
  //     throw new Error("Invalid date");
  //   }

  //   return momentDate;
  // };

  // const [selectedDate, setSelectedDate] = useState(
  //   convertToDateObject(preSelectedDate)
  // );
  // const submitHandler = () => {
  //   setDate(selectedDate);
  //   setIsCalenderOpen(false);
  //   setTimeout(() => {
  //     setDateTimeRangePicker(false);
  //   }, 120);
  // };

  const { isStepActive } = useSelector((state) => state.addTask);

  const cancelHandler = () => {
    setIsCalenderOpen(false);

    setTimeout(() => {
      setDateTimeRangePicker(false);
    }, 120);
  };

  const handleDatePickerChange = (date) => {
    const newDate = new Date(date.$d);
    const formattedDate = `${newDate.getDate()}/${
      newDate.getMonth() + 1
    }/${newDate.getFullYear()}`;
    console.log("Formatted Date: ", formattedDate);
    // setSelectedDate(formattedDate);
    setDate(formattedDate);
    setIsCalenderOpen(false);
    setTimeout(() => {
      setDateTimeRangePicker(false);
    }, 120);
  };

  // useEffect(() => {
  //   if (preSelectedDate) {
  //     console.log("Date set in calender");
  //     setSelectedDate(preSelectedDate);
  //   }
  // }, [preSelectedDate]);
  return (
    <>
      <Modal
        open={isDateTimeRangePicker}
        closable={true}
        width={"22rem"}
        height={"25rem"}
        // footer={
        //   isStepActive ? (
        //     <div className="d-flex justify-content-center">
        //       <button className="btn btn-primary px-4" onClick={submitHandler}>
        //         Set
        //       </button>
        //     </div>
        //   ) : (
        //     <div className="d-flex justify-content-center">
        //       <button className="btn btn-primary px-4" onClick={submitHandler}>
        //         Submit
        //       </button>
        //     </div>
        //   )
        // }
        footer
        centered={true}
        destroyOnClose={true}
        maskClosable={false}
        closeIcon={<CloseIconSmall />}
        className={"date-time-range-picker-modal"}
        style={{ margin: 0, minHeight: "400px" }}
        onCancel={cancelHandler}
      >
        <div className="date-time-picker-container w-100">
          <DatePicker
            className="custom-date-time-picker w-100"
            popupClassName={"custom-date-time-picker-popup"}
            format={dayjsDateFormat}
            open={isCalenderOpen}
            prevIcon={<PrevIcon />}
            defaultValue={defaultValue}
            nextIcon={<NextIcon />}
            superNextIcon={null}
            superPrevIcon={null}
            onChange={handleDatePickerChange}
            allowClear={true}
          />
        </div>
      </Modal>
    </>
  );
};

export default CustomDateTimeRangePicker;
