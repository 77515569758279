import React, { useEffect, useState } from 'react';
import { AnalyticsList } from '../components/List';
import { useDispatch } from 'react-redux';
import { getAnalyticsStatusAPI } from '../Redux/fetchItemsAPI';
import { moveTitleAPI } from '../Redux/updateItemAPI';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const assignIdsToData = (data) => {
  let categoryIdCounter = 1; // Counter to generate unique category IDs
  let titleIdCounter = 1; // Counter to generate unique title IDs

  // Iterate over each category and its titles
  return data.map((category) => {
    // Assign an ID to the category
    const categoryId = String(categoryIdCounter++); // Ensure the category ID is a string

    // Assign an ID to each title in the category
    const titlesWithIds = category.titles.map((title) => {
      return {
        id: String(titleIdCounter++), // Ensure the title ID is a string
        title: title,
      };
    });

    // Return the category with a unique ID and titles with unique IDs
    return {
      ...category, // Keep the existing category properties
      id: categoryId, // Add the unique category ID
      titles: titlesWithIds, // Replace titles with the new array of titles having IDs
    };
  });
};

const AnalyticsSettings = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const response = await dispatch(getAnalyticsStatusAPI());
    setLoading(false);
    // console.log('Response: ', response);
    if (Array.isArray(response) && response.length > 0) {
      console.log(assignIdsToData(response));
      setData(assignIdsToData(response));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   console.log('--------------State: ', data);
  // }, [data]);

  const TaskCompoSkeleton = () => {
    const componentCount = 3;
    const tasksCount = 6;
    const listWidth = 280;
    const taskHeight = 40;

    return (
      <>
        {/* Loop the entire component structure 5 times */}
        {[...Array(componentCount)].map((_, index) => (
          <div className="TaskCompo rounded-bottom cursor-pointer" key={index}>
            <div className="draggableCard">
              {[...Array(tasksCount)].map((_, index) => (
                <div className="d-flex ms-2 mt-2">
                  <Skeleton height={taskHeight} width={listWidth} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </>
    );
  };

  const handleTitleMove = (sourceCategoryId, targetCategoryId, titleIdToMove, sourceTitle, destinationCategory) => {
    setData((prevData) => {
      // Create a deep copy of the previous state to ensure immutability
      const updatedData = JSON.parse(JSON.stringify(prevData));

      // Find the source and target categories by their IDs
      const sourceCategoryIndex = updatedData.findIndex((category) => category.id === sourceCategoryId);
      const targetCategoryIndex = updatedData.findIndex((category) => category.id === targetCategoryId);

      // If either the source or target category doesn't exist, return the original state
      if (sourceCategoryIndex === -1 || targetCategoryIndex === -1) {
        return prevData;
      }

      // Find the title in the source category by its ID
      const titleToMove = updatedData[sourceCategoryIndex].titles.find((item) => item.id === titleIdToMove);

      // If the title to move isn't found, return the original state
      if (!titleToMove) {
        return prevData;
      }

      // Remove the title from the source category's titles list
      const updatedSourceTitles = updatedData[sourceCategoryIndex].titles.filter((item) => item.id !== titleIdToMove);

      // Update the source category's titles list after removing the title
      updatedData[sourceCategoryIndex] = {
        ...updatedData[sourceCategoryIndex],
        titles: updatedSourceTitles,
      };

      // Add the title to the target category's titles list
      updatedData[targetCategoryIndex] = {
        ...updatedData[targetCategoryIndex],
        titles: [...updatedData[targetCategoryIndex].titles, titleToMove],
      };

      const backendPayload = {
        titles: [titleToMove.title],
        category: updatedData[targetCategoryIndex].category,
        existingCategory: updatedData[sourceCategoryIndex].category,
      };

      console.log('Data To Send: ', backendPayload);

      dispatch(moveTitleAPI(backendPayload));
      // Return the updated data to be used as the new state
      return updatedData;
    });
  };

  return (
    <div className="Dashboard position-relative mt-4 h-100">
      <div className="DashboardHeader d-flex flex-column flex-sm-row justify-content-between align-items-center">
        <div className="col-12">
          <h4 className="ff_bd f30 mb-sm-0 dashBoardTitle mb-md-0 txt-primary">{'Analytics Settings'}</h4>
        </div>
      </div>

      <div className="d-flex gap-3 overflow-x-auto cursor-grab project-container subContainer mt-5">
        {loading ? (
          <TaskCompoSkeleton />
        ) : (
          data.map((item, index) => (
            <AnalyticsList
              key={item.id}
              listId={item.id}
              title={item.category}
              data={item.titles}
              moveFunction={handleTitleMove}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default AnalyticsSettings;
