import React, { useCallback, useEffect, useRef, useState } from 'react';
import PlusIcon from '../assets/plusIcon.svg';
import { AdminInputBoxLarge } from '../../Auth/components/Inputbox';
import CloseIcon from '../assets/redCloseIcon.svg';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import DraggableInfoCard, { StatusCard, TaskCardSkeleton } from './DraggableInfoCard';
import { useDispatch, useSelector } from 'react-redux';
import AddTaskModals from './AddTaskModals';
import {
  reorderTaskToOtherListHorizontally,
  reorderTaskToOtherStatusHorizontally,
  reorderTaskWithinGroup,
} from '../Redux/reorderAPI';
import { Button, Dropdown, Input, Modal, message } from 'antd';
import { ACCESS, PageLoader, ROLE, SiteLoader } from '../../utils';
import { DropboxOutlined, DeleteOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { archiveListAPI } from '../Redux/archiveItemAPI';
import { deleteListAPI } from '../Redux/deleteItemAPI';
import { updateListTitleAPI } from '../Redux/updateItemAPI';
import { fetchListTasksNew, fetchStatusTasksNew } from '../Redux/fetchItemsAPI';
import { useLocation } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export const DraggableList = ({ title, bgColor, moveList, index, isStatusView }) => {
  const [isNewCard, setIsNewCard] = useState(false);
  const [newCardTitle, setNewCardTitle] = useState('');
  const [taskData, setTaskData] = useState([
    {
      projectTitle: 'Project Title',
      taskTitle:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
      status: 'In-Progress',
    },
    {
      projectTitle: 'Project Title',
      taskTitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      status: 'In-Progress',
    },
    {
      projectTitle: 'Project Title',
      taskTitle:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
      status: 'In-Progress',
    },
    {
      projectTitle: 'Project Title',
      taskTitle: 'Task Title Appear Here',
      status: 'Pending',
    },
    {
      projectTitle: 'Project Title',
      taskTitle: 'Task Title Appear Here',
      status: 'Pending',
    },
    {
      projectTitle: 'Project Title',
      taskTitle: 'Task Title Appear Here',
      status: 'Done',
    },
    {
      projectTitle: 'Project Title',
      taskTitle: 'Task Title Appear Here',
      status: 'Done',
    },
    {
      projectTitle: 'Project Title',
      taskTitle: 'Task Title Appear Here',
      status: 'Done',
    },
    {
      projectTitle: 'Project Title',
      taskTitle: 'Task Title Appear Here',
      status: 'Done',
    },
  ]);

  const [, ref] = useDrag({
    type: ItemTypes.LIST,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.LIST,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveList(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const clickHandler = (e) => {
    e.preventDefault();
    setIsNewCard(true);
  };

  const addHandler = () => {
    setIsNewCard(false);
    taskData.push({
      projectTitle: 'Project Title',
      taskTitle: newCardTitle,
    });
  };

  const closeHandler = () => {
    setIsNewCard(false);
  };

  const moveCard = (fromIndex, toIndex) => {
    const updatedTaskData = [...taskData];
    const [movedCard] = updatedTaskData.splice(fromIndex, 1);
    updatedTaskData.splice(toIndex, 0, movedCard);
    setTaskData(updatedTaskData);

    localStorage.setItem('taskData', JSON.stringify(updatedTaskData));
  };

  useEffect(() => {
    const storedTaskData = localStorage.getItem('taskData');
    if (storedTaskData) {
      setTaskData(JSON.parse(storedTaskData));
    }
  }, []);

  return (
    <div ref={(node) => ref(drop(node))} className="TaskCompo rounded-bottom cursor-pointer w-100">
      <div className={`TaskMajorTitle ${bgColor}`}>
        <p className="ff_md f18 text-center txt-white mb-0">{title}</p>
      </div>
      {isNewCard ? (
        <div className="d-flex flex-column">
          <AdminInputBoxLarge
            taskTitle={newCardTitle}
            setTaskTitle={setNewCardTitle}
            nameOfClass={'mx-2 my-2'}
            placeHolderText={'Enter a task title'}
          />
          <div className="d-flex flex-row align-items-start">
            <button
              className="minWidth146 adminAntModelAddButton f16 txt-white justify-content-center mx-2 mb-2 py-2"
              onClick={addHandler}
            >
              Add Card
            </button>
            <img src={CloseIcon} alt="closeIcon" className="cursor-pointer" onClick={closeHandler} />
          </div>
        </div>
      ) : (
        <div className="addCard d-flex flex-row justify-content-center cursor-pointer">
          <img src={PlusIcon} alt="icon" />
          <p className="ff_rg f14 txt-secondary mb-0 py-2 ms-2" onClick={clickHandler}>
            Add a Card
          </p>
        </div>
      )}
      <div className="draggableCard">
        {taskData?.map((ele, index) => {
          return (
            <div className="d-flex ms-2" key={index}>
              {!isStatusView && (
                <span
                  className={`ma_verticalLine ${
                    (ele.status === 'Pending' && 'c-pending') ||
                    (ele.status === 'In-Progress' && 'c-inProgress') ||
                    (ele.status === 'Done' && 'c-done') ||
                    (ele.status === 'Urgent' && 'c-urgent')
                  }`}
                />
              )}
              <DraggableInfoCard
                projectTitle={ele.projectTitle}
                taskTitle={ele?.taskTitle}
                key={index}
                moveCard={moveCard}
                status={ele.status}
                isStatusView={isStatusView}
                index={index}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const DraggableListAdmin = ({ statusId, title, bgColor, moveList, index, isStatusView }) => {
  const [isNewCard, setIsNewCard] = useState(false);
  const [newCardTitle, setNewCardTitle] = useState('');
  let { data } = useSelector((state) => state.adminDashboardData);
  const [taskData, setTaskData] = useState([]);

  useEffect(() => {
    if (!data || !data.data) {
      return;
    }

    const filteredTaskByStatusItem = data.data.find((status) => status.status === statusId);
    if (filteredTaskByStatusItem) {
      setTaskData([...filteredTaskByStatusItem.tasks]);
    }
  }, [data, statusId]);

  const [, ref] = useDrag({
    type: ItemTypes.LIST,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.LIST,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveList(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const clickHandler = (e) => {
    e.preventDefault();
    setIsNewCard(true);
  };

  const addHandler = () => {
    setIsNewCard(false);
    taskData.push({
      projectTitle: 'Project Title',
      taskTitle: newCardTitle,
    });
  };

  const closeHandler = () => {
    setIsNewCard(false);
  };

  const moveCard = (fromIndex, toIndex) => {
    const updatedTaskData = [...taskData];
    const [movedCard] = updatedTaskData.splice(fromIndex, 1);
    updatedTaskData.splice(toIndex, 0, movedCard);
    setTaskData(updatedTaskData);

    localStorage.setItem('taskData', JSON.stringify(updatedTaskData));
  };

  // useEffect(() => {
  //   const storedTaskData = localStorage.getItem("taskData");
  //   if (storedTaskData) {
  //     setTaskData(JSON.parse(storedTaskData));
  //   }
  // }, []);

  return (
    <div ref={(node) => ref(drop(node))} className="TaskCompo rounded-bottom cursor-pointer w-100">
      <div className={`TaskMajorTitle`} style={{ backgroundColor: bgColor }}>
        <p className="ff_md f18 text-center txt-white mb-0">{`${title} (${taskData.length})`}</p>
      </div>
      {/* {isNewCard ? (
        <div className="d-flex flex-column">
          <AdminInputBoxLarge
            taskTitle={newCardTitle}
            setTaskTitle={setNewCardTitle}
            nameOfClass={"mx-2 my-2"}
            placeHolderText={"Enter a task title"}
          />
          <div className="d-flex flex-row align-items-start">
            <button
              className="minWidth146 adminAntModelAddButton f16 txt-white justify-content-center mx-2 mb-2 py-2"
              onClick={addHandler}
            >
              Add Card
            </button>
            <img
              src={CloseIcon}
              alt="closeIcon"
              className="cursor-pointer"
              onClick={closeHandler}
            />
          </div>
        </div>
      ) : (
        <div className="addCard d-flex flex-row justify-content-center cursor-pointer">
          <img src={PlusIcon} alt="icon" />
          <p
            className="ff_rg f14 txt-secondary mb-0 py-2 ms-2"
            onClick={clickHandler}
          >
            Add a Card
          </p>
        </div>
      )} */}
      <div className="draggableCard pt-2">
        {Object.keys(taskData).length > 0 &&
          taskData?.map((ele, index) => {
            return (
              <div className="d-flex ms-2" key={index}>
                {/* {!isStatusView && (
                  <span
                    className={`ma_verticalLine ${
                      (ele.status === "Pending" && "c-pending") ||
                      (ele.status === "In-Progress" && "c-inProgress") ||
                      (ele.status === "Done" && "c-done") ||
                      (ele.status === "Urgent" && "c-urgent")
                    }`}
                  />
                )} */}
                <DraggableInfoCard
                  task={ele}
                  projectTitle={ele.project.title}
                  taskTitle={ele?.title}
                  key={index}
                  moveCard={moveCard}
                  status={ele.status}
                  isStatusView={isStatusView}
                  index={index}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const DraggableListProject = React.memo(
  ({
    access,
    listId,
    displayOrder,
    projectDetails,
    title,
    bgColor,
    moveList,
    index,
    isStatusView,
    searchMemberId,
    date,
  }) => {
    const listRef = useRef(null);
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem('user'));
    const fetchTasksLoading = useSelector((state) => state.loading[`fetchTasksNew-${listId}Loading`]);
    const [isNewCard, setIsNewCard] = useState(false);
    const [newCardTitle, setNewCardTitle] = useState('');
    let { taskData } = useSelector((state) => state.taskData);
    let { projects } = useSelector((state) => state.dashboard);
    const [listTitle, setListTitle] = useState(title);
    const [tasks, setTasks] = useState([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      if (!taskData) {
        return;
      }

      const filteredTaskByListItem = taskData.find((list) => list._id === listId);
      if (filteredTaskByListItem) {
        setTasks([...filteredTaskByListItem?.tasks]);
      }
    }, [listId, taskData]);

    useEffect(() => {
      if (!listId || !title || !projectDetails) {
        return;
      }
      if (location.pathname.includes('/t/')) {
        return;
      }

      dispatch(fetchListTasksNew(listId, title, projectDetails, searchMemberId, date));
    }, [dispatch, listId, projectDetails, title, searchMemberId, date]);

    useEffect(() => {
      setListTitle(title);
    }, []);

    const [, dragList] = useDrag({
      type: ItemTypes.LIST,
      item: { index, listId: listId, displayOrder: displayOrder },
    });

    const [, dropList] = useDrop({
      accept: ItemTypes.LIST,
      drop: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveList(draggedItem.listId, draggedItem.displayOrder, draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });

    const [{ isOver, canDrop }, dropTask] = useDrop({
      accept: ItemTypes.CARD,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: (draggedItem, monitor) => {
        const listElement = listRef.current;

        if (!listElement) return;

        const rect = listElement.getBoundingClientRect();
        const clientOffset = monitor.getClientOffset();

        if (!rect || !clientOffset) return;

        const relativeY = clientOffset.y - rect.top;

        const targetIndex = relativeY < 150 ? 0 : tasks.length;
        // console.log('Target Index: ', targetIndex);
        // console.log('Dragged Item: ', draggedItem, 'Dropped List Id: ', listId);
        if (tasks.length === 0) {
          // Handle dropping into empty regular list
          console.log('Empty list drop');
          dispatch({
            type: 'MOVE_TASK_LIST',
            payload: {
              taskId: draggedItem.taskId,
              sourceListId: draggedItem.listId,
              destinationListId: listId,
              // sourceIndex: draggedItem.index,
              destinationIndex: 0,
            },
          });
          const dataToSend = {
            taskId: draggedItem.taskId,
            fromIndex: draggedItem.index,
            newIndex: 0,
            fromList: draggedItem.listId,
            toList: listId,
          };
          dispatch(reorderTaskToOtherListHorizontally(dataToSend));

          draggedItem.index = 0;
          draggedItem.listId = listId;
        } else {
          if (draggedItem.listId === listId) {
            //Same list drop
            if (targetIndex !== draggedItem.index + 1 && targetIndex !== draggedItem.index) {
              //Remove edge cases(same task dnd at bottom or at top)
              moveCard(draggedItem.taskId, draggedItem.listId, draggedItem.index, targetIndex);
              draggedItem.index = index;
            }
          } else {
            //Different list drop
            dispatch({
              type: 'MOVE_TASK_LIST',
              payload: {
                taskId: draggedItem.taskId,
                sourceListId: draggedItem.listId,
                destinationListId: listId,
                // sourceIndex: draggedItem.index,
                destinationIndex: targetIndex,
              },
            });
            const dataToSend = {
              taskId: draggedItem.taskId,
              fromIndex: draggedItem.index,
              newIndex: targetIndex,
              fromList: draggedItem.listId,
              toList: listId,
            };
            dispatch(reorderTaskToOtherListHorizontally(dataToSend));
          }
        }
      },
    });

    const dropZoneStyle = {
      // padding: '20px',
      borderRadius: '10px',
      minHeight: '200px',
      border: '1px solid',
      borderColor: isOver ? (canDrop ? '#5db4ef' : 'red') : 'gray',
      backgroundColor: isOver ? (canDrop ? '#e0f1fc' : 'rgba(255, 0, 0, 0.1)') : 'transparent',
      transition: 'all 0.3s ease',
      cursor: 'move',
    };

    const clickHandler = (e) => {
      if (access === ACCESS.VIEW) {
        return;
      }
      e.preventDefault();
      // setIsNewCard(true);
      // console.log("Clicked Add Task");
      // dispatch({
      //   type: "IS_STEP_ACTIVE",
      //   payload: { isStepActive: true, listId: listId },
      // });
      // dispatch({ type: "CHANGE_STEP", payload: 1 });
      dispatch({
        type: 'IS_ADD_TASK_STEP_ACTIVE',
        payload: true,
      });
      dispatch({
        type: 'CHANGE_STEP_PAYLOAD',
        payload: {
          step: 1,
          listId: listId,
          projectDetails: { ...projectDetails },
        },
      });
    };

    const addHandler = () => {
      setIsNewCard(false);
      tasks.push({
        projectTitle: 'Project Title',
        taskTitle: newCardTitle,
      });
    };

    const closeHandler = () => {
      setIsNewCard(false);
    };

    const reorderTaskWithinList = useCallback(
      (taskId, dropIndex) => {
        const dataToSend = {
          taskId: taskId,
          newPriorityNumber: dropIndex,
          list: listId,
        };
        dispatch(reorderTaskWithinGroup(dataToSend));
        console.log('Data To Send: ', dataToSend);
      },
      [listId]
    );

    const delayedReorderTaskWithinList = useCallback(debounce(reorderTaskWithinList, 1000), [reorderTaskWithinList]);

    const moveCard = (taskId, listId, fromIndex, toIndex) => {
      // console.log('Target From Index: ', fromIndex, 'To Index: ', toIndex);
      // console.log("List move called");
      // console.log('Move called');
      const updatedTaskData = [...tasks];
      const [movedCard] = updatedTaskData.splice(fromIndex, 1);
      updatedTaskData.splice(toIndex, 0, movedCard);
      setTasks(updatedTaskData);

      localStorage.setItem('taskData', JSON.stringify(updatedTaskData));
      delayedReorderTaskWithinList(taskId, toIndex);
    };

    // useEffect(() => {
    //   const storedTaskData = localStorage.getItem("taskData");
    //   if (storedTaskData) {
    //     setTaskData(JSON.parse(storedTaskData));
    //   }
    // }, []);

    const saveTitle = useCallback(
      (title) => {
        const dataToSend = {
          title: title,
        };
        dispatch(updateListTitleAPI(listId, dataToSend));
      },
      [listId]
    );

    const saveTitleDelayed = useCallback(debounce(saveTitle, 1000), [saveTitle]);

    // const handleListTitleChnage = (e) => {
    //   console.log('Title change: ', e.target.value);
    //   //delayed save title to backend
    //   if (e.target.value.length <= 28) {
    //     saveTitleDelayed(e.target.value);
    //     setListTitle(e.target.value);
    //   }
    // };

    const handleListTitleChnage = (e) => {
      const { selectionStart, selectionEnd } = e.target;

      // Delayed save title to backend
      if (e.target.value.length <= 28) {
        saveTitleDelayed(e.target.value);
        setListTitle(e.target.value);
      }

      setDisplayTitle((prevTitle) => {
        const updatedTitle = e.target.value;
        setTimeout(() => {
          e.target.selectionStart = selectionStart;
          e.target.selectionEnd = selectionEnd;
        }, 0);
        return updatedTitle;
      });
    };

    const items = [
      {
        label: 'Archive',
        key: '1',
        icon: <DropboxOutlined />,
      },
      {
        label: 'Delete',
        key: '2',
        danger: true,
        icon: <DeleteOutlined />,
      },
    ];

    const onClick = ({ key }) => {
      // message.info(`Click on item ${key}`);
      switch (key) {
        case '1':
          setArchiveModalOpen(true);
          break;
        case '2':
          setDeleteModalOpen(true);
          break;
        default:
          message.info('Undefined menu');
      }
    };

    const DeleteConfirmationModal = ({ listName, modalVisible, setModalVisible, onConfirm }) => {
      const handleDelete = () => {
        if (onConfirm) {
          onConfirm();
        }
        setModalVisible(false);
      };

      return (
        <>
          <Modal
            title="Delete the list"
            open={modalVisible}
            centered
            onCancel={() => setModalVisible(false)}
            footer={[
              <button key="cancel" className="ff f16 c-white" onClick={() => setModalVisible(false)}>
                Cancel
              </button>,
              <button key="delete" className="ff f16 c-white txt-error" onClick={handleDelete}>
                Remove
              </button>,
            ]}
          >
            <p>Aye you sure you want to delete list {<span style={{ fontWeight: 'bold' }}>{listName}</span>}?</p>
          </Modal>
        </>
      );
    };

    const ArchiveConfirmationModal = ({ listName, modalVisible, setModalVisible, onConfirm }) => {
      const handleArchive = () => {
        if (onConfirm) {
          onConfirm();
        }
        setModalVisible(false);
      };

      return (
        <>
          <Modal
            title="Archive the list"
            open={modalVisible}
            centered
            onCancel={() => setModalVisible(false)}
            footer={[
              <button key="cancel" className="ff f16 c-white" onClick={() => setModalVisible(false)}>
                Cancel
              </button>,
              <button key="delete" className="ff f16 c-white txt-yellow" onClick={handleArchive}>
                Archive
              </button>,
            ]}
          >
            <p>Aye you sure you want to archive list {<span style={{ fontWeight: 'bold' }}>{listName}</span>}?</p>
          </Modal>
        </>
      );
    };

    const archiveListHandler = () => {
      dispatch(archiveListAPI(listId, { isActive: false }));
    };

    const deleteListHandler = () => {
      dispatch(deleteListAPI(listId));
    };

    const [isEditing, setIsEditing] = useState(false);
    const [displayTitle, setDisplayTitle] = useState(title);

    const handleFocus = () => {
      setIsEditing(true);
    };

    const handleBlur = () => {
      setIsEditing(false);
    };

    useEffect(() => {
      if (!isEditing) {
        // setDisplayTitle(`${listTitle} (${tasks.length})`);
        setDisplayTitle(`${listTitle}${tasks?.length > 0 ? ` (${tasks.length})` : ''}`);
      } else {
        setDisplayTitle(listTitle);
      }
    }, [isEditing, listTitle, tasks.length]);
    return (
      <>
        {/* <AddTaskModals projectDetails={projectDetails} listId={listId} /> */}
        <ArchiveConfirmationModal
          listName={title}
          modalVisible={archiveModalOpen}
          setModalVisible={setArchiveModalOpen}
          onConfirm={archiveListHandler}
        />
        <DeleteConfirmationModal
          listName={title}
          modalVisible={deleteModalOpen}
          setModalVisible={setDeleteModalOpen}
          onConfirm={deleteListHandler}
        />
        <div
          ref={(node) => {
            dropTask(dropList(node));
            dragList(node);
          }}
          className="TaskCompo rounded-bottom cursor-pointer w-100"
          // style={{ opacity: isOver ? '0.7' : '1' }}
          style={isOver ? dropZoneStyle : {}}
        >
          {/* <div className={bgColor}> */}
          <div className={`TaskMajorTitle ${bgColor}`} ref={listRef}>
            {/* <p className="ff_md f18 text-center txt-white mb-0">{title}</p> */}
            <Input.TextArea
              className={`ff ff_md fs-6 text-center txt-white mb-0 ${bgColor}`}
              autoSize={{ minRows: 1, maxRows: 6 }}
              style={{ border: 'none', padding: '0' }}
              value={displayTitle}
              onChange={handleListTitleChnage}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            />

            <div className="ms-auto">
              {user.role === ROLE.ADMIN && (
                <Dropdown
                  menu={{
                    items,
                    onClick,
                  }}
                  trigger={['click']}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Button className={`${bgColor}`} icon={<FontAwesomeIcon icon={faEllipsisVertical} />} />
                  </a>
                </Dropdown>
              )}
            </div>
          </div>
          {/* <div className="task-count-container">
            <p className="task-count-text">{`${tasks.length} Tasks`}</p>
          </div> */}
          {/* </div> */}

          {access === ACCESS.EDIT &&
            (isNewCard ? (
              <div className="d-flex flex-column">
                <AdminInputBoxLarge
                  taskTitle={newCardTitle}
                  setTaskTitle={setNewCardTitle}
                  nameOfClass={'mx-2 my-2'}
                  placeHolderText={'Enter a task title'}
                />
                <div className="d-flex flex-row align-items-start">
                  <button
                    className="minWidth146 adminAntModelAddButton f16 txt-white justify-content-center mx-2 mb-2 py-2"
                    onClick={addHandler}
                  >
                    Add Card
                  </button>
                  <img src={CloseIcon} alt="closeIcon" className="cursor-pointer" onClick={closeHandler} />
                </div>
              </div>
            ) : (
              <div className="addCard d-flex flex-row justify-content-center cursor-pointer">
                {/* <img src={PlusIcon} alt="icon" /> */}
                <p className="ff_rg f14 txt-secondary mb-0 py-2 ms-2" onClick={clickHandler}>
                  <span className="fs-5 me-1">+</span>
                  Add Task
                </p>
              </div>
            ))}

          {fetchTasksLoading ? (
            <div className="draggableCard">
              {/* <PageLoader /> */}
              <>
                {[...Array(6)].map((_, index) => (
                  <TaskCardSkeleton />
                ))}
              </>
            </div>
          ) : (
            <div className="draggableCard">
              {tasks &&
                tasks?.map((ele, index) => {
                  const projectItem = projects.find((project) => project._id === ele.project);
                  return (
                    <div className="d-flex ms-2" key={index}>
                      {/* {!isStatusView && (
                        <span
                          className={`ma_verticalLine ${
                            (ele.status === 'Pending' && 'c-pending') ||
                            (ele.status === 'In-Progress' && 'c-inProgress') ||
                            (ele.status === 'Done' && 'c-done') ||
                            (ele.status === 'Urgent' && 'c-urgent')
                          }`}
                        />
                      )} */}
                      <DraggableInfoCard
                        task={ele}
                        categoryId={listId}
                        taskTitle={ele?.title}
                        key={index}
                        moveCard={moveCard}
                        status={ele.status}
                        isStatusView={isStatusView}
                        index={index}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </>
    );
  }
);

export const DraggableListProjectByStatus = React.memo(
  ({
    access,
    statusId,
    displayOrder,
    title,
    bgColor,
    moveList,
    index,
    isStatusView,
    projectDetails,
    searchMemberId,
    date,
  }) => {
    const statusListRef = useRef(null);
    const [isNewCard, setIsNewCard] = useState(false);
    const [newCardTitle, setNewCardTitle] = useState('');
    let { taskData: taskDataByStatus } = useSelector((state) => state.taskDataByStatus);
    const [taskData, setTaskData] = useState([]);
    const fetchTasksLoading = useSelector((state) => state.loading[`fetchTasksNew-${statusId}Loading`]);

    const dispatch = useDispatch();

    // useEffect(() => {
    //   console.log("Draggable list render");
    // }, []);

    useEffect(() => {
      dispatch(fetchStatusTasksNew(statusId, title, bgColor, searchMemberId, date, projectDetails));
    }, [bgColor, dispatch, statusId, title, searchMemberId, date, projectDetails]);

    useEffect(() => {
      if (!statusId || !(Object.keys(taskDataByStatus).length > 0)) {
        return;
      }

      if (!taskDataByStatus) {
        return;
      }
      const taskDataOfThisStatus = taskDataByStatus?.find((tasks) => tasks._id === statusId);
      setTaskData(taskDataOfThisStatus?.tasks);
    }, [statusId, taskDataByStatus]);

    const [, dragList] = useDrag({
      type: ItemTypes.LIST,
      item: { index, statusListId: statusId, displayOrder: displayOrder },
    });

    const [, dropList] = useDrop({
      accept: ItemTypes.LIST,
      drop: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveList(draggedItem.statusListId, draggedItem.displayOrder, draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });

    const [{ isOver, canDrop }, dropTask] = useDrop({
      accept: ItemTypes.CARD,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: (draggedItem, monitor) => {
        const listElement = statusListRef.current;

        if (!listElement) return;

        const rect = listElement.getBoundingClientRect();
        const clientOffset = monitor.getClientOffset();

        if (!rect || !clientOffset) return;

        const relativeY = clientOffset.y - rect.top;

        const targetIndex = relativeY < 150 ? 0 : taskData.length;
        if (taskData.length === 0) {
          // console.log('Dragged Item: ', draggedItem, 'Dropped Status Id: ', statusId);
          // Handle dropping into empty regular list
          // console.log('Empty status drop');
          dispatch({
            type: 'MOVE_TASK_STATUS',
            payload: {
              taskId: draggedItem.taskId,
              sourceStatusId: draggedItem.statusId,
              destinationStatusId: statusId,
              sourceIndex: draggedItem.index,
              destinationIndex: 0,
            },
          });

          const dataToSend = {
            taskId: draggedItem.taskId,
            fromIndex: draggedItem.index,
            newIndex: 0,
            fromStatus: draggedItem.statusId,
            toStatus: statusId,
          };
          dispatch(reorderTaskToOtherStatusHorizontally(dataToSend));

          draggedItem.index = 0;
          draggedItem.statusId = statusId;
        } else {
          if (draggedItem.statusId === statusId) {
            if (targetIndex !== draggedItem.index + 1 && targetIndex !== draggedItem.index) {
              moveCard(draggedItem.taskId, draggedItem.statusId, draggedItem.index, targetIndex);
              draggedItem.index = index;
            }
          } else {
            if (draggedItem.allStatus.some((status) => status._id === statusId)) {
              dispatch({
                type: 'REMOVE_TASK_FROM_STATUS',
                payload: {
                  taskId: draggedItem.taskId,
                  sourceStatusId: draggedItem.statusId,
                },
              });
              const dataToSend = {
                taskId: draggedItem.taskId,
                fromIndex: draggedItem.index,
                newIndex: index,
                fromStatus: draggedItem.statusId,
                toStatus: statusId,
              };

              dispatch(reorderTaskToOtherStatusHorizontally(dataToSend));
              draggedItem.index = index;
              draggedItem.statusId = statusId;
            } else {
              //categoryId drop target id
              //draggedItem.statusId drag source id
              //draggedItem.index from where to remove task
              //index to where append task
              dispatch({
                type: 'MOVE_TASK_STATUS',
                payload: {
                  taskId: draggedItem.taskId,
                  sourceStatusId: draggedItem.statusId,
                  destinationStatusId: statusId,
                  sourceIndex: draggedItem.index,
                  destinationIndex: targetIndex,
                },
              });

              const dataToSend = {
                taskId: draggedItem.taskId,
                fromIndex: draggedItem.index,
                newIndex: targetIndex,
                fromStatus: draggedItem.statusId,
                toStatus: statusId,
              };
              dispatch(reorderTaskToOtherStatusHorizontally(dataToSend));

              draggedItem.index = index;
              draggedItem.statusId = statusId;
            }
          }
        }
      },
    });

    const dropZoneStyle = {
      // padding: '20px',
      borderRadius: '10px',
      minHeight: '200px',
      border: '1px solid',
      borderColor: isOver ? (canDrop ? '#5db4ef' : 'red') : 'gray',
      backgroundColor: isOver ? (canDrop ? '#e0f1fc' : 'rgba(255, 0, 0, 0.1)') : 'transparent',
      transition: 'all 0.3s ease',
      cursor: 'move',
    };

    const clickHandler = (e) => {
      if (access === ACCESS.VIEW) {
        return;
      }
      e.preventDefault();
      // e.preventDefault();
      // setIsNewCard(true);
      dispatch({
        type: 'IS_ADD_TASK_STEP_ACTIVE',
        payload: true,
      });
      dispatch({
        type: 'CHANGE_STEP_PAYLOAD',
        payload: {
          step: 1,
          projectDetails: { ...projectDetails },
        },
      });
    };

    const addHandler = () => {
      setIsNewCard(false);
      taskData.push({
        projectTitle: 'Project Title',
        taskTitle: newCardTitle,
      });
    };

    const closeHandler = () => {
      setIsNewCard(false);
    };

    const reorderTaskWithinStatus = useCallback(
      (taskId, dropIndex) => {
        const dataToSend = {
          taskId: taskId,
          newPriorityNumber: dropIndex,
          status: statusId,
        };
        dispatch(reorderTaskWithinGroup(dataToSend));
        console.log('Data To Send: ', dataToSend);
      },
      [statusId]
    );

    const delayedReorderTaskWithinStatus = useCallback(debounce(reorderTaskWithinStatus, 1000), [
      reorderTaskWithinStatus,
    ]);

    const moveCard = (taskId, statusId, fromIndex, toIndex, categoryId) => {
      // if (categoryId === statusId) {
      //   console.log("Same category");
      // } else {
      //   console.log("Different category");
      // }
      // console.log("Status move called");
      const updatedTaskData = [...taskData];
      const [movedCard] = updatedTaskData.splice(fromIndex, 1);
      updatedTaskData.splice(toIndex, 0, movedCard);
      setTaskData(updatedTaskData);

      localStorage.setItem('taskData', JSON.stringify(updatedTaskData));
      delayedReorderTaskWithinStatus(taskId, toIndex);
    };

    // useEffect(() => {
    //   const storedTaskData = localStorage.getItem("taskData");
    //   if (storedTaskData) {
    //     setTaskData(JSON.parse(storedTaskData));
    //   }
    // }, []);

    return (
      <>
        {/* <AddTaskModals projectDetails={projectDetails} /> */}
        <div
          ref={(node) => {
            dropTask(dropList(node));
            dragList(node);
          }}
          className="TaskCompo rounded-bottom cursor-pointer w-100"
          style={isOver ? dropZoneStyle : {}}
        >
          {/* <div style={{ backgroundColor: bgColor }}> */}
          <div className={`TaskMajorTitle`} style={{ backgroundColor: bgColor }} ref={statusListRef}>
            <p className="ff_md f18 text-center txt-white mb-0">
              {/* {title} {taskData?.length !== undefined ? `(${taskData.length})` : ''} */}
              {title} {taskData?.length > 0 ? `(${taskData.length})` : ''}
            </p>
          </div>
          {/* <div className="task-count-container">
            <p className="task-count-text">{`${taskData.length} Tasks`}</p>
          </div> */}
          {/* </div> */}
          {/* {access === ACCESS.EDIT &&
            (isNewCard ? (
              <div className="d-flex flex-column">
                <AdminInputBoxLarge
                  taskTitle={newCardTitle}
                  setTaskTitle={setNewCardTitle}
                  nameOfClass={'mx-2 my-2'}
                  placeHolderText={'Enter a task title'}
                />
                <div className="d-flex flex-row align-items-start">
                  <button
                    className="minWidth146 adminAntModelAddButton f16 txt-white justify-content-center mx-2 mb-2 py-2"
                    onClick={addHandler}
                  >
                    Add Card
                  </button>
                  <img src={CloseIcon} alt="closeIcon" className="cursor-pointer" onClick={closeHandler} />
                </div>
              </div>
            ) : (
              <div className="addCard d-flex flex-row justify-content-center cursor-pointer">
                <img src={PlusIcon} alt="icon" />
                <p className="ff_rg f14 txt-secondary mb-0 py-2 ms-2" onClick={clickHandler}>
                  Add Task
                </p>
              </div>
            ))} */}

          {fetchTasksLoading ? (
            <div className="draggableCard mt-1">
              {/* <PageLoader /> */}
              <>
                {[...Array(6)].map((_, index) => (
                  <TaskCardSkeleton />
                ))}
              </>
            </div>
          ) : (
            <div className="draggableCard pt-2">
              {taskData?.map((ele, index) => {
                return (
                  <div className="d-flex ms-2" key={index}>
                    {/* {!isStatusView && (
                  <span
                    className={`ma_verticalLine ${
                      (ele.status === "Pending" && "c-pending") ||
                      (ele.status === "In-Progress" && "c-inProgress") ||
                      (ele.status === "Done" && "c-done") ||
                      (ele.status === "Urgent" && "c-urgent")
                    }`}
                  />
                )} */}
                    <DraggableInfoCard
                      projectTitle={ele?.project?.title}
                      categoryId={statusId}
                      taskTitle={ele?.title}
                      task={ele}
                      key={ele._id}
                      moveCard={moveCard}
                      status={ele?.status}
                      isStatusView={isStatusView}
                      index={index}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </>
    );
  }
);

export const AnalyticsList = React.memo(({ title, data, moveFunction, listId }) => {
  const listRef = useRef(null);

  // const dropZoneStyle = {
  //   // padding: '20px',
  //   borderRadius: '10px',
  //   minHeight: '200px',
  //   border: '1px solid',
  //   borderColor: isOver ? (canDrop ? '#5db4ef' : 'red') : 'gray',
  //   backgroundColor: isOver ? (canDrop ? '#e0f1fc' : 'rgba(255, 0, 0, 0.1)') : 'transparent',
  //   transition: 'all 0.3s ease',
  //   cursor: 'move',
  // };

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: ItemTypes.STATUS,
    drop: (item) => {
      // moveTask(item, list.id);
      console.log('Item dropped: ', item);
      // console.log('Source category: ', item.category, 'Target category: ', listId, 'Title: ', item.titleId, item);
      if (item.category !== listId) moveFunction(item.category, listId, item.titleId);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const dropZoneStyle = {
    // padding: '20px',
    borderRadius: '10px',
    minHeight: '200px',
    border: '1px solid',
    borderColor: isOver ? (canDrop ? '#5db4ef' : 'red') : 'gray',
    backgroundColor: isOver ? (canDrop ? '#e0f1fc' : 'rgba(255, 0, 0, 0.1)') : 'transparent',
    transition: 'all 0.3s ease',
    cursor: 'move',
  };

  const [displayTitle, setDisplayTitle] = useState(title);
  return (
    <>
      <div
        className="TaskCompo rounded-bottom cursor-pointer w-100"
        ref={drop}
        style={isOver ? dropZoneStyle : {}}
        // style={{ opacity: isOver ? '0.7' : '1' }}
        // style={isOver ? dropZoneStyle : {}}
      >
        {/* <div className={bgColor}> */}
        <div className={`TaskMajorTitle c-sky`} ref={listRef}>
          <p className="ff_md f18 text-center txt-white mb-0">
            {/* {title} {taskData?.length !== undefined ? `(${taskData.length})` : ''} */}
            {title}
          </p>
        </div>

        {false ? (
          <div className="draggableCard">
            {/* <PageLoader /> */}
            <>
              {[...Array(6)].map((_, index) => (
                <TaskCardSkeleton />
              ))}
            </>
          </div>
        ) : (
          <div className="draggableCard mt-2">
            {data?.map((ele, index) => {
              return (
                <div className="d-flex ms-2" key={ele.id}>
                  <StatusCard status={ele} categoryId={listId} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
});
