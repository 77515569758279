import { message } from 'antd';
import axios from '../../helpers/axios';
import { dispatchToast } from '../../utils';
const BACKEND_API_URL = process.env.REACT_APP_API_URL;
export const archiveProjectAPI = (projectId, data, navigate, socket) => async (dispatch) => {
  axios
    .put(`${BACKEND_API_URL}/projects/archive/${projectId}`, data)
    .then((result) => {
      if (socket) {
        socket.emit('projectDelete', { _id: projectId });
      }
      dispatch({
        type: 'DELETE_PROJECT',
        payload: projectId,
      });
      navigate('/dashboard');
      message.success('Project archived');
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    });
};
export const archiveTaskAPI = (taskId, data, projectSlug, navigate) => async (dispatch) => {
  axios
    .put(`${BACKEND_API_URL}/tasks/archive/${taskId}`, data)
    .then((result) => {
      message.success('Task archived');
      dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
      dispatch({ type: 'SANITIZE_ADD_TASK_STORE' });
      dispatch({ type: 'IS_STEP_ACTIVE', payload: false });
      dispatch({ type: 'CHANGE_STEP', payload: 0 });
      navigate(`/projects/${projectSlug}`);
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    });
};

export const archiveEmployeeAPI = (memberId, data, type, navigate) => async (dispatch) => {
  axios
    .put(`${BACKEND_API_URL}/users/archive/${memberId}`, data)
    .then((result) => {
      dispatch({
        type: 'DELETE_MEMBER',
        payload: { memberId: memberId, type: type },
      });
      navigate('/dashboard');
      message.success('Member archived');
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    });
};

export const archiveListAPI = (listId, data) => async (dispatch) => {
  axios
    .put(`${BACKEND_API_URL}/lists/archive/${listId}`, data)
    .then((result) => {
      if (data.isActive === false) {
        dispatch({ type: 'REMOVE_LIST', payload: { listId: listId } });
      }
      message.success('List archived');
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    });
};
