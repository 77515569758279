import { message } from 'antd';
import axios from '../../helpers/axios';
import { API_URL, dispatchToast } from '../../utils';
import { dispatchLoading } from '../../utils';
import {
  fetchAllTasksForAdminDashboardByStatusAPI,
  fetchAllTasksForProjectViewByListAPI,
  fetchProjectMembers,
} from './fetchItemsAPI';
import { useSocket } from '../../helpers/SocketContext';

const BACKEND_API_URL = process.env.REACT_APP_API_URL;

export const UpdateProjectAPI =
  (projectId, data, navigate, projectSlug, newMembers, socket, removedMembers) => async (dispatch) => {
    dispatchLoading(dispatch, 'addProject', true);
    axios
      .put(`${BACKEND_API_URL}/projects/${projectId}`, data)
      .then((result) => {
        if (result.status === 200) {
          if (Object.keys(newMembers).length > 0) {
            socket.emit('projectAdd', { ...result.data, teamMembers: newMembers });
          }
          if (Object.keys(removedMembers).length > 0) {
            socket.emit('teamMemberRemove', { _id: projectId, teamMembers: removedMembers });
          }
          dispatch({
            type: 'UPDATE_PROJECT_TITLE',
            payload: {
              id: projectId,
              newTitle: result.data.title,
              newSlug: result.data.slug,
            },
          });
          dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
          dispatch(fetchProjectMembers(projectSlug));
          dispatchToast(dispatch, 'success', `Project Updated Successfully`);
          navigate(`/projects/${result.data.slug}`);
        }
      })
      .catch((error) => {
        dispatchToast(dispatch, 'error', error.response.data.message);
      })
      .finally(() => {
        dispatchLoading(dispatch, 'addProject', false);
        dispatch({ type: 'CLOSE_ADD_NEW_PROJECT_MODAL' });
      });
  };

export const updateTaskAPI = (data, taskId, projectId, socket) => async (dispatch) => {
  // dispatchLoading(dispatch, "addProject", true);
  axios
    .put(`${BACKEND_API_URL}/tasks/${taskId}`, data)
    .then((result) => {
      dispatch({
        type: 'FETCH_TASK_DETAILS_FOR_TASK_DETAILS_MODAL',
        payload: result.data,
      });
      socket.emit(`taskUpdate`, result.data);
      dispatch({ type: 'SET_UPDATE_FLAG', payload: true });

      message.success('Task Updated');
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {});
};

export const updateTaskDescAPI = (data, taskId, socket, extras) => async (dispatch) => {
  // dispatchLoading(dispatch, "addProject", true);
  axios
    .put(`${BACKEND_API_URL}/tasks/${taskId}`, data)
    .then((result) => {
      dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
      socket.emit(`taskUpdate`, result.data);
      if (extras) {
        extras();
      }

      // dispatch({
      //   type: "ADD_NEW_LIST",
      //   payload: { _id: result.data._id, title: result.data.title },
      // });
      // if (result.status === 201) {
      //   dispatch(fetchStatusApiForProject(data.project));
      // }
      // dispatchToast(dispatch, "success", `Project Added Successfully`);
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {
      // dispatchLoading(dispatch, "addProject", false);
      // dispatch({ type: "CLOSE_ADD_NEW_PROJECT_MODAL" });
    });
};

export const updateCheckListAPI = (data, taskId) => async (dispatch) => {
  // dispatchLoading(dispatch, "addProject", true);
  axios
    .put(`${BACKEND_API_URL}/tasks/checklists/${taskId}`, data)
    .then((result) => {
      // dispatch({
      //   type: "ADD_NEW_LIST",
      //   payload: { _id: result.data._id, title: result.data.title },
      // });
      // if (result.status === 201) {
      //   dispatch(fetchStatusApiForProject(data.project));
      // }
      // dispatchToast(dispatch, "success", `Project Added Successfully`);
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {
      // dispatchLoading(dispatch, "addProject", false);
      // dispatch({ type: "CLOSE_ADD_NEW_PROJECT_MODAL" });
    });
};

export const updateCheckListItemAPI = (data) => async (dispatch) => {
  // dispatchLoading(dispatch, "addProject", true);
  axios
    .put(`${BACKEND_API_URL}/tasks/update-item`, data)
    .then((result) => {
      dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
      message.success('Checklist Updated');
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {
      // dispatchLoading(dispatch, "addProject", false);
      // dispatch({ type: "CLOSE_ADD_NEW_PROJECT_MODAL" });
    });
};

export const updateListTitleAPI = (listId, data) => async (dispatch) => {
  axios
    .put(`${BACKEND_API_URL}/lists/${listId}`, data)
    .then((result) => {
      message.success('List title updated');
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    });
};

export const updateLabelTitleAPI = (statusId, data) => async (dispatch) => {
  axios
    .put(`${BACKEND_API_URL}/status/${statusId}`, data)
    .then((result) => {
      dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
      message.success('Label title updated');
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    });
};

export const editCommentAPI = (data) => async (dispatch) => {
  try {
    const response = await axios.put(`${BACKEND_API_URL}/tasks/comment`, data);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting data:', error.response.data.message);
    message.error(error.response.data.message);
    return null;
  }
};

export const moveTitleAPI = (data) => async (dispatch) => {
  try {
    const response = await axios.put(`${BACKEND_API_URL}/status/categories`, data);
    if (response.data) {
      message.success(response.data.message)
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting data:', error.response.data.message);
    message.error(error.response.data.message);
    return null;
  }
};

