import React from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";

import { firstCharacters } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EnteredDataRowProject = ({ email, name, access, deleteEntry }) => {
  return (
    <div className="mb-2">
      <Row className="align-items-center">
        <Col xs="auto">
          <div className="NavIconBG2" style={{ height: "42px", width: "42px" }}>
            <label className="ff ff_md f14 txt-lightGray cursor-pointer">
              {firstCharacters(name)}
            </label>
          </div>
        </Col>
        <Col>
          <Row>{name}</Row>
          <Row>{email}</Row>
        </Col>
        <Col xs="auto" className="ml-auto">
          {access}
        </Col>
        <Col xs="auto" className="ml-auto">
          <FontAwesomeIcon
            icon={faTrash}
            className="text-danger"
            onClick={deleteEntry}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EnteredDataRowProject;
