import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AddTaskDetailModal, AddTaskModal } from "./Modals";

const AddTaskModals = ({ projectDetails }) => {
  const { isStepActive, currentStep, listId } = useSelector(
    (state) => state.addTask
  );

  // useEffect(() => {
  //   if (!isStepActive) {
  //     console.log("Add Task Modal Close");
  //     console.log("Add Task Detail Modal Close");
  //     return;
  //   }

  //   if (currentStep === 1) {
  //     console.log("Add Task Modal Open");
  //   } else {
  //     console.log("Add Task Modal Close");
  //   }

  //   if (currentStep === 2) {
  //     console.log("Add Task Detail Modal Open");
  //   } else {
  //     console.log("Add Task Detail Modal Close");
  //   }
  // }, [currentStep, isStepActive]);

  useEffect(() => {
    if (isStepActive === true && currentStep === 2) {
      console.log("Add Task Modals render");
    }
  }, []);

  return (
    <>
      {isStepActive && (
        <>
          {/* Step 1 : Add Task */}
          {currentStep === 1 && (
            <AddTaskModal
              modalOpen={isStepActive === true && currentStep === 1}
              addHandler={() => {}}
              cancelHandler={() => {}}
              passedListId={listId}
              projectDetails={projectDetails}
            />
          )}
          {/* Step 2 : Add Task Detail */}
          {currentStep === 2 && (
            <AddTaskDetailModal
              modalOpen={isStepActive === true && currentStep === 2}
              addHandler={() => {}}
              cancelHandler={() => {}}
            />
          )}
        </>
      )}
    </>
  );
};

export default AddTaskModals;
