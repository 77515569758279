import * as React from "react";
const CloseIconSmall = (props) => (
  <svg width={20} height={20} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_42_1399)">
      <path
        d="M13.6594 2.34061C10.5379 -0.780202 5.46155 -0.780202 2.34009 2.34061C-0.78003 5.46141 -0.78003 10.5391 2.34009 13.6599C3.90082 15.22 5.95044 15.9997 8.00009 15.9997C10.0497 15.9997 12.0987 15.22 13.6594 13.6599C16.7802 10.5391 16.7802 5.46141 13.6594 2.34061ZM11.3009 10.3584C11.5617 10.6191 11.5617 11.0407 11.3009 11.3015C11.2391 11.3635 11.1656 11.4127 11.0847 11.4462C11.0037 11.4798 10.917 11.497 10.8294 11.4969C10.7418 11.497 10.655 11.4797 10.5741 11.4462C10.4932 11.4127 10.4197 11.3635 10.3578 11.3015L8.00005 8.94301L5.64295 11.3008C5.51787 11.4258 5.34826 11.4961 5.17138 11.4962C5.08379 11.4963 4.99703 11.4791 4.91611 11.4455C4.83519 11.412 4.76168 11.3628 4.69982 11.3008C4.43904 11.04 4.43904 10.6178 4.69982 10.3577L7.05693 7.99991L4.69916 5.64215C4.43838 5.38137 4.43838 4.95918 4.69916 4.69905C4.95929 4.43828 5.38148 4.43828 5.64226 4.69905L8.00002 7.05682L10.3578 4.69905C10.6186 4.43828 11.0401 4.43828 11.3009 4.69905C11.5617 4.95918 11.5617 5.38137 11.3009 5.64215L8.94312 7.99991L11.3009 10.3584Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_42_1399">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CloseIconSmall;
