import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Document, Page } from 'react-pdf';
import ReactPlayer from 'react-player';
import '@cyntler/react-doc-viewer/dist/index.css';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import PDFViewer from './PDFViewer';

function getFileExtension(url) {
  return url?.split('.').pop().split(/\#|\?/)[0];
}

const AttachmentPreviewModal = () => {
  const dispatch = useDispatch();
  const { isVisible, index } = useSelector((state) => state.preview);
  const { attachments } = useSelector((state) => state.addTask);
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(0);

  const currentAttachment = attachments[currentAttachmentIndex];
  const fileExtension = getFileExtension(currentAttachment);

  useEffect(() => {
    setCurrentAttachmentIndex(index);
  }, [index]);

  let viewerComponent;

  switch (fileExtension) {
    case 'mp4':
    case 'mov':
    case 'webm':
    case 'avi':
    case 'mkv':
      viewerComponent = <ReactPlayer url={currentAttachment} controls width="100%" height="100%" />;
      break;
    case 'pdf':
      // viewerComponent = (
      //   <Document file={currentAttachment}>
      //     <Page pageNumber={1} />
      //   </Document>
      // );
      viewerComponent = <PDFViewer file={currentAttachment} />;
      break;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      // console.log("Image");
      viewerComponent = (
        <img src={currentAttachment} alt="Attachment" style={{ maxWidth: '100%', maxHeight: '100%' }} />
      );
      break;
    default:
      // console.log("Default");
      viewerComponent = <DocViewer documents={[{ uri: currentAttachment }]} pluginRenderers={DocViewerRenderers} />;
  }

  const handleNextAttachment = () => {
    if (currentAttachmentIndex < attachments.length - 1) {
      setCurrentAttachmentIndex(currentAttachmentIndex + 1);
    }
  };

  const handlePreviousAttachment = () => {
    if (currentAttachmentIndex > 0) {
      setCurrentAttachmentIndex(currentAttachmentIndex - 1);
    }
  };

  return (
    <Modal
      open={isVisible}
      footer={null}
      onCancel={() => {
        setCurrentAttachmentIndex(0);
        dispatch({ type: 'CLOSE_FILE_PREVIEW' });
      }}
      centered
      style={{ textAlign: 'center' }}
      className="previewModal"
    >
      {viewerComponent}
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <Button
          onClick={handlePreviousAttachment}
          disabled={currentAttachmentIndex === 0}
          className="nextprevBtn prevBtn"
        >
          <svg width={29} height={29} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14.5" cy="14.5" r="14.5" transform="rotate(-180 14.5 14.5)" fill="white" />
            <path
              d="M16 20L11 15L16 10"
              stroke="#222222"
              strokeWidth={3}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
        <Button
          onClick={handleNextAttachment}
          disabled={currentAttachmentIndex === attachments.length - 1}
          className="nextprevBtn nextBtn"
        >
          <svg width={29} height={29} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14.5" cy="14.5" r="14.5" fill="white" />
            <path d="M13 9L18 14L13 19" stroke="#222222" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Button>
      </div>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <Button onClick={() => window.open(currentAttachment)} className="downloadBtn">
          Download
        </Button>
      </div>
    </Modal>
  );
};

export default AttachmentPreviewModal;
