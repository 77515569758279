import axios from "../../helpers/axios";
import { API_URL, ROLE, dispatchToast } from "../../utils";
import { dispatchLoading } from "../../utils";
const BACKEND_API_URL = process.env.REACT_APP_API_URL;
export const AddEntityAPI =
  (data, closeActionType, entityType) => async (dispatch) => {
    dispatchLoading(dispatch, "addEntity", true);
    axios
      .post(`${BACKEND_API_URL}/auth/register`, data)
      .then((result) => {
        if (result.status === 201) {
          if (data.role === ROLE.EMPLOYEE) {
            dispatch({ type: "ADD_EMPLOYEES_SUCCESS", payload: result?.data });
          }
          if (data.role === ROLE.CLIENT) {
            dispatch({ type: "ADD_CLIENTS_SUCCESS", payload: result?.data });
          }
          if (data.role === ROLE.PROJECTMANAGER) {
            dispatch({
              type: "ADD_PROJECTMANAGER_SUCCESS",
              payload: result?.data,
            });
          }
        }

        dispatchToast(dispatch, "success", `${entityType} Added Successful`);
      })
      .catch((error) => {
        dispatchToast(dispatch, "error", error.response.data.message);
      })
      .finally(() => {
        dispatchLoading(dispatch, "addEntity", false);
        dispatch({ type: closeActionType });
      });
  };
