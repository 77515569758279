import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import Clock from '../assets/clockIcon.svg';
import Person from '../assets/person.svg';
import Chat from '../assets/chat.svg';
import Link from '../assets/link.svg';
import View from '../assets/view.svg';
import { Tag } from 'antd';
import { FormatDateForTaskCard, ROLE, decrypt } from '../../utils';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  reorderTaskToOtherGroupHorizontally,
  reorderTaskToOtherListHorizontally,
  reorderTaskToOtherStatusHorizontally,
} from '../Redux/reorderAPI';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getEmptyImage } from 'react-dnd-html5-backend';

const user = JSON.parse(localStorage.getItem('user'));

const DraggableInfoCard = React.memo(
  ({ task, projectTitle, categoryId, taskTitle, index, moveCard, status, isStatusView }) => {
    const naviagte = useNavigate();
    const location = useLocation();
    const { searchTerm } = useSelector((state) => state.search);
    const { viewType } = useSelector((state) => state.viewType);
    const dispatch = useDispatch();
    const [completedChecklistItems, setCompletedChecklistItems] = useState(0);
    const [totalChecklistItems, setTotalChecklistItems] = useState(0);
    // console.log('Data: ', task._id, task.statusInfo._id, task.statusDetails);
    const [, ref, preview] = useDrag({
      type: ItemTypes.CARD,
      item: () => {
        // Condition to set different fields
        if (isStatusView) {
          return {
            index,
            taskId: task._id,
            statusId: categoryId,
            allStatus: task.statusDetails,
            task: task,
          };
        } else {
          return {
            index,
            taskId: task._id,
            listId: task.listDetails._id,
            task: task,
          };
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    // function getEmptyImage();

    const [, drop] = useDrop({
      accept: ItemTypes.CARD,
      drop: (draggedItem) => {
        if (draggedItem.index !== index) {
          if (isStatusView) {
            console.log('Dragged Item: ', draggedItem);
            //Same list drag (status)
            if (categoryId === draggedItem.statusId) {
              console.log('Same list ', index);
              moveCard(draggedItem.taskId, draggedItem.statusId, draggedItem.index, index);
              draggedItem.index = index;
            }
            //Different list drag(status)
            else {
              //Drag and delete if it is being dragged into list with same status, which is already assigned to it
              if (draggedItem.allStatus.some((status) => status._id === categoryId)) {
                dispatch({
                  type: 'REMOVE_TASK_FROM_STATUS',
                  payload: {
                    taskId: draggedItem.taskId,
                    sourceStatusId: draggedItem.statusId,
                  },
                });
                const dataToSend = {
                  taskId: draggedItem.taskId,
                  fromIndex: draggedItem.index,
                  newIndex: index,
                  fromStatus: draggedItem.statusId,
                  toStatus: categoryId,
                };

                dispatch(reorderTaskToOtherStatusHorizontally(dataToSend));
                draggedItem.index = index;
                draggedItem.statusId = categoryId;
              } else {
                console.log('Different list ', index);
                //categoryId drop target id
                //draggedItem.statusId drag source id
                //draggedItem.index from where to remove task
                //index to where append task
                dispatch({
                  type: 'MOVE_TASK_STATUS',
                  payload: {
                    taskId: draggedItem.taskId,
                    sourceStatusId: draggedItem.statusId,
                    destinationStatusId: categoryId,
                    sourceIndex: draggedItem.index,
                    destinationIndex: index,
                  },
                });

                const dataToSend = {
                  taskId: draggedItem.taskId,
                  fromIndex: draggedItem.index,
                  newIndex: index,
                  fromStatus: draggedItem.statusId,
                  toStatus: categoryId,
                };
                dispatch(reorderTaskToOtherStatusHorizontally(dataToSend));

                draggedItem.index = index;
                draggedItem.statusId = categoryId;
              }
            }
          } else {
            //Same list drag for (list)
            if (categoryId === draggedItem.listId) {
              console.log('Same list ', index);
              moveCard(draggedItem.taskId, draggedItem.listId, draggedItem.index, index);
              draggedItem.index = index;
            }
            //Different list drag (list)
            else {
              //dispatch
              // console.log(
              //   "Category Id:",
              //   categoryId,
              //   "Task List Id:",
              //   draggedItem.listId
              // );
              console.log('Different list ', 'categoryId: ', categoryId, 'Item List: ', draggedItem.listId);
              dispatch({
                type: 'MOVE_TASK_LIST',
                payload: {
                  taskId: draggedItem.taskId,
                  sourceListId: draggedItem.listId,
                  destinationListId: categoryId,
                  // sourceIndex: draggedItem.index,
                  destinationIndex: index,
                },
              });
              const dataToSend = {
                taskId: draggedItem.taskId,
                fromIndex: draggedItem.index,
                newIndex: index,
                fromList: draggedItem.listId,
                toList: categoryId,
              };
              dispatch(reorderTaskToOtherListHorizontally(dataToSend));

              draggedItem.index = index;
              draggedItem.listId = categoryId;
            }
          }
          // draggedItem.index = index;
        }
      },
    });

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
    }, []);
    useEffect(() => {
      if (location.pathname.includes('dashboard')) {
        if (task) {
          let totalChecked = 0;
          let totalCount = 0;

          task?.checkLists?.forEach((checkList) => {
            checkList.items.forEach((item) => {
              totalCount++;
              if (item.isChecked) {
                totalChecked++;
              }
            });
          });
          console.log('Checklists: ', totalCount, totalChecked);
          setCompletedChecklistItems(totalChecked);
          setTotalChecklistItems(totalCount);
        }
      }
    }, [location.pathname, task]);

    const statusClasses = {
      'In-Progress': 'txt-inProgress',
      Pending: 'txt-pending',
      Done: 'txt-done',
    };

    const defaultClass = 'txt-secondary';

    const nameOfClass = isStatusView ? defaultClass : statusClasses[status];

    const [decryptedTaskTitle, setDecryptedTaskTitle] = useState('');
    const [decryptedTaskDesc, setDecryptedTaskDesc] = useState('');

    useEffect(() => {
      // Function to decrypt the task title
      const decryptTaskTitle = async () => {
        try {
          const decryptedText = await decrypt(task.title);
          setDecryptedTaskTitle(decryptedText);
        } catch (error) {
          console.error('Error decrypting task title:', error);
          // Handle error if necessary
        }
      };

      decryptTaskTitle(); // Call the decryption function
    }, [task?.title]);

    useEffect(() => {
      if (!task.description) {
        return;
      }
      // Function to decrypt the task title
      const decryptTaskDesc = async () => {
        try {
          const decryptedText = await decrypt(task.description);
          setDecryptedTaskDesc(decryptedText);
        } catch (error) {
          console.error('Error decrypting task title:', error);
          // Handle error if necessary
        }
      };

      decryptTaskDesc(); // Call the decryption function
    }, [task.description]);

    const openTaskDetailsModal = () => {
      const url = location.pathname;
      const urlParts = url.split('/');
      if (urlParts[1] === 'dashboard') {
        dispatch({ type: 'IS_ADD_TASK_STEP_ACTIVE', payload: true });
        dispatch({
          type: 'CHANGE_STEP_PAYLOAD',
          payload: {
            step: 2,
            projectDetails: {
              _id: task.project._id,
              title: task.project.title,
              slug: task.project.slug,
            },
            taskId: task.slug,
            isEditFlow: true,
          },
        });
      } else {
        if (viewType === 'status') {
          dispatch({ type: 'STAGE_TASK', payload: task });
        }
        naviagte(`/p/${urlParts[2]}/t/${task.slug}`);
      }
    };

    function calculateChecklistTotals(checkLists) {
      let totalChecked = 0;
      let totalCount = 0;

      // Iterate through each checklist
      checkLists.forEach((checkList) => {
        checkList.items.forEach((item) => {
          totalCount++;
          if (item.isChecked) {
            totalChecked++;
          }
        });
      });

      return { totalChecked, totalCount };
    }

    const doesTaskMatchSearchTerm = (task, searchTerm, decryptedTaskTitle) => {
      if (!searchTerm.trim()) {
        return true;
      }

      const lowercasedSearchTerm = searchTerm.toLowerCase();
      const taskTitle = decryptedTaskTitle?.toLowerCase() || '';
      const projectTitle = task?.project?.title?.toLowerCase() || '';
      const taskDescription = decryptedTaskDesc.toLowerCase() || '';

      return (
        taskTitle.includes(lowercasedSearchTerm) ||
        projectTitle.includes(lowercasedSearchTerm) ||
        taskDescription.includes(lowercasedSearchTerm)
      );
    };

    if (!doesTaskMatchSearchTerm(task, searchTerm, decryptedTaskTitle)) {
      return null;
    }

    return (
      <div
        onClick={openTaskDetailsModal}
        ref={(node) => ref(drop(node))}
        className={`InfoCard d-flex flex-column mx-2 ms-0 p-2 flex-grow-1 mb-2`}
      >
        {/* <Tag
          className="align-self-end me-0 mt-1 border-0"
          color="#FF7272"
          bordered={false}
        >
          Urgent
        </Tag> */}
        {task?.statusDetails && (
          <div className="d-flex mb-1">
            {task.statusDetails.map((status, index) => {
              return (
                <Tag className="" key={index} color={status.color} bordered={false}>
                  {status.title}
                </Tag>
              );
            })}
          </div>
        )}
        {projectTitle && <p className={`ff_rg f14 mb-1 ${nameOfClass}`}>{projectTitle}</p>}
        <p className=" f18 mb-2">
          <span style={{ fontWeight: 'bold' }}>{`#${task.number}`}</span>
          {`-${decryptedTaskTitle}`}
        </p>
        <div className="d-flex flex-row gap-2 gap-sm-3 gap-md-2">
          {location.pathname.includes('dashboard') ? (
            <InfoCardIcons
              endDate={FormatDateForTaskCard(task?.scheduledDate)}
              membersCount={task?.assignee?.length}
              commentsCount={task?.comments?.length}
              attachmentsCount={task?.attachments?.length}
              checklistCount={`${completedChecklistItems}/${totalChecklistItems}`}
              key={index}
            />
          ) : (
            <InfoCardIcons
              endDate={FormatDateForTaskCard(task?.scheduledDate)}
              membersCount={task?.assignee?.length}
              commentsCount={task?.comments}
              attachmentsCount={task?.attachments}
              checklistCount={`${task?.checkedChecklist}/${task?.totalChecklistItems}`}
              key={index}
            />
          )}
        </div>
      </div>
    );
  }
);

export default DraggableInfoCard;

export const InfoCardIcons = ({ endDate, membersCount, commentsCount, attachmentsCount, checklistCount }) => {
  return (
    <>
      <div className="d-flex flex-row addcardImgIcon">
        <img src={Clock} alt="icon" />
        <p className="ff_rg f14 align-items-center mb-0 txt-light-sm ms-1">{endDate}</p>
      </div>
      {user?.role !== ROLE.CLIENT && (
        <div className="d-flex flex-row addcardImgIcon">
          <img src={Person} alt="icon" />
          <p className="ff_rg f14 align-items-center mb-0 txt-light-sm ms-1">{membersCount}</p>
        </div>
      )}

      <div className="d-flex flex-row addcardImgIcon">
        <img src={Chat} alt="icon" />
        <p className="ff_rg f14 align-items-center mb-0 txt-light-sm ms-1">{commentsCount}</p>
      </div>
      <div className="d-flex flex-row addcardImgIcon">
        <img src={Link} alt="icon" />
        <p className="ff_rg f14 align-items-center mb-0 txt-light-sm ms-1">{attachmentsCount}</p>
      </div>
      <div className="d-flex flex-row addcardImgIcon">
        <img src={View} alt="icon" />
        <p className="ff_rg f14 align-items-center mb-0 txt-light-sm ms-1">{checklistCount}</p>
      </div>
    </>
  );
};

export const TaskCardSkeleton = () => {
  return (
    <div className="d-flex ms-2">
      <div className="InfoCard d-flex flex-column mx-2 ms-0 flex-grow-1 mb-2" style={{ backgroundColor: '#ebebeb' }}>
        <Skeleton height={76} width="100%" />
      </div>
    </div>
  );
};

export const StatusCard = React.memo(({ status, categoryId }) => {
  const naviagte = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.STATUS,
    item: { titleId: status.id, category: categoryId, title: status.title },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      // ref={(node) => ref(drop(node))}
      ref={drag}
      className={`InfoCard d-flex flex-column mx-2 ms-0 p-2 flex-grow-1 mb-2`}
      key={status.id}
    >
      <p className=" f18 mb-2">{`${status.title}`}</p>
      <div className="d-flex flex-row gap-2 gap-sm-3 gap-md-2"></div>
    </div>
  );
});
