import { message } from 'antd';
import axios from '../../helpers/axios';
import { dispatchToast, ViewType } from '../../utils';
const BACKEND_API_URL = process.env.REACT_APP_API_URL;
export const deleteCheckListAPI = (data, taskId, checklistId, socket) => async (dispatch) => {
  // dispatchLoading(dispatch, "addProject", true);
  axios
    .put(`${BACKEND_API_URL}/tasks/delete-checklists/${taskId}`, data)
    .then((result) => {
      console.log('Deleted');
      dispatch({
        type: 'DELETE_CHECKLIST',
        payload: checklistId,
      });
      socket.emit('deleteChecklist', taskId, {
        type: 'DELETE_CHECKLIST',
        payload: checklistId,
      });
      dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
      // if (result.status === 201) {
      //   dispatch(fetchStatusApiForProject(data.project));
      // }
      // dispatchToast(dispatch, "success", `Project Added Successfully`);
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {
      // dispatchLoading(dispatch, "addProject", false);
      // dispatch({ type: "CLOSE_ADD_NEW_PROJECT_MODAL" });
    });
};

export const deleteAttachmentInTaskAPI = (data, taskId, socket) => async (dispatch) => {
  // dispatchLoading(dispatch, "addProject", true);
  axios
    .put(`${BACKEND_API_URL}/tasks/${taskId}`, data)
    .then((result) => {
      socket.emit(`taskUpdate`, result.data);
      // dispatch({
      //   type: "ADD_NEW_LIST",
      //   payload: { _id: result.data._id, title: result.data.title },
      // });
      // if (result.status === 201) {
      //   dispatch(fetchStatusApiForProject(data.project));
      // }
      // dispatchToast(dispatch, "success", `Project Added Successfully`);
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {
      // dispatchLoading(dispatch, "addProject", false);
      // dispatch({ type: "CLOSE_ADD_NEW_PROJECT_MODAL" });
    });
};

export const deleteProjectAPI = (projectId, navigate, socket) => async (dispatch) => {
  axios
    .delete(`${BACKEND_API_URL}/projects/${projectId}`)
    .then((result) => {
      if (socket) {
        socket.emit('projectDelete', { _id: projectId });
      }

      dispatch({
        type: 'DELETE_PROJECT',
        payload: projectId,
      });
      navigate('/dashboard');
      message.success('Project successfully deleted');
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    });
};

export const deleteTaskAPI = (task, projectSlug, navigate, viewType) => async (dispatch) => {
  axios
    .delete(`${BACKEND_API_URL}/tasks/${task._id}`)
    .then((result) => {
      if (viewType === ViewType.ListView) {
        dispatch({ type: 'DELETE_TASK_LISTVIEW', payload: { idOfList: task.list, idOfTask: task._id } });
      } else if (viewType === ViewType.StatusView) {
        task.status.forEach((item) => {
          dispatch({
            type: 'DELETE_TASK_STATUSVIEW',
            payload: { idOfStatus: item._id, idOfTask: task._id },
          });
        });
      }
      message.success('Task successfully deleted');
      dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
      dispatch({ type: 'SANITIZE_ADD_TASK_STORE' });
      dispatch({ type: 'IS_STEP_ACTIVE', payload: false });
      dispatch({ type: 'CHANGE_STEP', payload: 0 });
      navigate(`/projects/${projectSlug}`, {
        state: { doNotRefetch: true },
      });
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    });
};

export const deleteMemberAPI = (memberId, type, navigate) => async (dispatch) => {
  axios
    .delete(`${BACKEND_API_URL}/users/${memberId}`)
    .then((result) => {
      dispatch({
        type: 'DELETE_MEMBER',
        payload: { memberId: memberId, type: type },
      });
      navigate('/dashboard');
      message.success('Member successfully deleted');
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    });
};

export const deleteListAPI = (listId) => async (dispatch) => {
  axios
    .delete(`${BACKEND_API_URL}/lists/${listId}`)
    .then((result) => {
      // dispatch({ type: "DELETE_MEMBER", payload: memberId });
      dispatch({ type: 'REMOVE_LIST', payload: { listId: listId } });
      message.success('List successfully deleted');
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    });
};

export const deleteStatusAPI = (statusId) => async (dispatch) => {
  axios
    .delete(`${BACKEND_API_URL}/status/${statusId}`)
    .then((result) => {
      // dispatch({ type: "DELETE_MEMBER", payload: memberId });
      dispatch({ type: 'REMOVE_STATUS', payload: { statusId: statusId } });
      message.success('Status successfully deleted');
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    });
};

export const deleteChecklistItemAPI = (taskId, checklistId, checklistIemId, socket) => async (dispatch) => {
  axios
    .delete(`${BACKEND_API_URL}/tasks/item/${taskId}/${checklistId}/${checklistIemId}`)
    .then((result) => {
      dispatch({
        type: 'DELETE_CHECKLIST_ITEM',
        payload: {
          mainCheckListId: checklistId,
          childItemId: checklistIemId,
        },
      });
      socket.emit('deleteChecklist', taskId, {
        type: 'DELETE_CHECKLIST_ITEM',
        payload: {
          mainCheckListId: checklistId,
          childItemId: checklistIemId,
        },
      });
      dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
      message.success('Item deleted');
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    });
};

export const deleteCommentAPI = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${BACKEND_API_URL}/tasks/comment/delete-comment`, data);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting data:', error.response.data.message);
    message.error(error.response.data.message);
    return null;
  }
};
