import { message } from 'antd';
import axios from '../../helpers/axios';
import { API_URL, dispatchToast } from '../../utils';
import { dispatchLoading } from '../../utils';
import {
  fetchAllTasksForAdminDashboardByStatusAPI,
  fetchAllTasksForProjectViewByListAPI,
  fetchListApiForProject,
  fetchStatusApiForProject,
} from './fetchItemsAPI';
const BACKEND_API_URL = process.env.REACT_APP_API_URL;
export const AddProjectAPI = (data, navigate, socket) => async (dispatch) => {
  dispatchLoading(dispatch, 'addProject', true);
  axios
    .post(`${BACKEND_API_URL}/projects`, data)
    .then((result) => {
      if (result.status === 201) {
        dispatch({ type: 'ADD_PROJECTS_SUCCESS', payload: result?.data });
        socket.emit('projectAdd', result?.data);

        navigate(`/projects/${result.data.slug}`);
      }
      dispatchToast(dispatch, 'success', `Project Added Successfully`);
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {
      dispatchLoading(dispatch, 'addProject', false);
      dispatch({ type: 'CLOSE_ADD_NEW_PROJECT_MODAL' });
    });
};

export const AddTaskAPI =
  (data, passedProjectId, passedProjectName, passedProjectSlug, navigate, isSave, socket) => async (dispatch) => {
    // dispatchLoading(dispatch, "addProject", true);
    dispatch({
      type: 'SET_LOADING',
      payload: { scope: 'addTask', status: true },
    });
    axios
      .post(`${BACKEND_API_URL}/tasks`, data)
      .then((result) => {
        // dispatch({ type: "ADD_NEW_TASK", payload: result?.data });
        dispatch({ type: 'SET_TASK_ADD_FLAG', payload: true });

        if (result.status === 201) {
          if (isSave) {
            console.log('Result: ', result.data);
            dispatch({ type: 'SANITIZE_ADD_TASK_STORE' });
            dispatch({ type: 'IS_STEP_ACTIVE', payload: false });
            dispatch({ type: 'CHANGE_STEP', payload: 0 });
            navigate(`/projects/${passedProjectSlug}`, {
              state: { doNotRefetch: true },
            });
          } else {
            navigate(`/p/${passedProjectSlug}/t/${result.data.slug}`);
          }

          if (data.newlist) {
            const list = result.data.listDetails;
            const projectDetails = result.data.project;
            const listData = {
              _id: list._id,
              title: list.title,
              displayOrder: list.displayOrder,
            };

            const listDataWithTask = {
              _id: list._id,
              title: list.title,
              project: {
                _id: projectDetails._id,
                title: projectDetails.title,
              },
              tasks: [{ ...result.data, comments: 0, attachments: 0, checkedChecklist: 0, totalChecklistItems: 0 }],
            };

            // console.log('List Data: ', listData, 'Task Data: ', listDataWithTask);
            dispatch({
              type: 'ADD_LIST_WITH_TASK',
              payload: { listDataAdd: listData, listDataWithTaskNew: listDataWithTask },
            });
          } else {
            const taskData = {
              ...result.data,
              comments: 0,
              attachments: 0,
              checkedChecklist: 0,
              totalChecklistItems: 0,
            };
            const payload = { listIdOfNewTask: data.list, taskData: taskData };
            dispatch({ type: 'APPEND_TASKS_LISTVIEW', payload: payload });
          }

          // if (passedProjectId) {
          //   dispatch(fetchAllTasksForProjectViewByListAPI(passedProjectId));
          // } else {
          //   dispatch(fetchAllTasksForAdminDashboardByStatusAPI());
          // }
          // dispatch({ type: "IS_ADD_TASK_STEP_ACTIVE", payload: true });
          // dispatch({
          //   type: "CHANGE_STEP_PAYLOAD",
          //   payload: {
          //     step: 2,
          //     projectDetails: {
          //       _id: passedProjectId,
          //       title: passedProjectName,
          //       slug: passedProjectSlug,
          //     },
          //     taskId: result.data.slug,
          //   },
          // });
        }
        // dispatchToast(dispatch, "success", `Project Added Successfully`);
      })
      .catch((error) => {
        // dispatchToast(dispatch, "error", error.response.data.message);
        // console.log('Error: ', error);
        message.error(error.response.data.message);
      })
      .finally(() => {
        // dispatchLoading(dispatch, "addProject", false);
        // dispatch({ type: "CLOSE_ADD_NEW_PROJECT_MODAL" });
        dispatch({
          type: 'SET_LOADING',
          payload: { scope: 'addTask', status: false },
        });
      });
  };

export const AddListAPI = (data) => async (dispatch) => {
  // dispatchLoading(dispatch, "addProject", true);
  axios
    .post(`${BACKEND_API_URL}/lists`, data)
    .then((result) => {
      if (result.status === 201) {
        dispatch({
          type: 'ADD_NEW_LIST',
          payload: {
            _id: result.data._id,
            title: result.data.title,
            displayOrder: result.data.displayOrder,
          },
        });
        // dispatch(fetchListApiForProject(data.project));
      }
      // dispatchToast(dispatch, "success", `Project Added Successfully`);
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {
      // dispatchLoading(dispatch, "addProject", false);
      // dispatch({ type: "CLOSE_ADD_NEW_PROJECT_MODAL" });
    });
};

export const AddStatusAPI = (data, slug) => async (dispatch) => {
  // dispatchLoading(dispatch, "addProject", true);
  axios
    .post(`${BACKEND_API_URL}/status`, data)
    .then((result) => {
      // dispatch({
      //   type: "ADD_NEW_LIST",
      //   payload: { _id: result.data._id, title: result.data.title },
      // });
      if (result.status === 201) {
        dispatch(fetchStatusApiForProject(slug));
      }
      // dispatchToast(dispatch, "success", `Project Added Successfully`);
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {
      // dispatchLoading(dispatch, "addProject", false);
      // dispatch({ type: "CLOSE_ADD_NEW_PROJECT_MODAL" });
    });
};

export const AddCheckListAPI = (data, taskId, socket) => async (dispatch) => {
  // dispatchLoading(dispatch, "addProject", true);
  axios
    .post(`${BACKEND_API_URL}/tasks/checklist/${taskId}`, data)
    .then((result) => {
      dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
      dispatch({ type: 'SET_CHECKLIST_ADD_FLAG', payload: true });

      // dispatch({
      //   type: "ADD_NEW_LIST",
      //   payload: { _id: result.data._id, title: result.data.title },
      // });
      if (result.status === 200) {
        dispatch({
          type: 'ADD_CHECKLIST_DATA',
          payload: result.data.checkList,
        });
        socket.emit('taskUpdateChecklist', taskId, result.data.checkList);
      }
      // dispatchToast(dispatch, "success", `Project Added Successfully`);
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {
      // dispatchLoading(dispatch, "addProject", false);
      // dispatch({ type: "CLOSE_ADD_NEW_PROJECT_MODAL" });
    });
};

export const AddCheckListItemAPI = (data, taskId, checkListId, socket) => async (dispatch) => {
  // dispatchLoading(dispatch, "addProject", true);
  axios
    .post(`${BACKEND_API_URL}/tasks/${taskId}/checklist/${checkListId}/items/`, data)
    .then((result) => {
      // dispatch({
      //   type: "ADD_NEW_LIST",
      //   payload: { _id: result.data._id, title: result.data.title },
      // });
      if (result.status === 200) {
        dispatch({ type: 'SET_UPDATE_FLAG', payload: true });

        dispatch({
          type: 'ADD_CHECKLIST_ITEM_DATA',
          payload: {
            checkListId: checkListId,
            checkListItems: result.data.newItems.map((item) => {
              return {
                _id: item._id,
                name: item.name,
                isChecked: false,
              };
            }),
          },
        });

        socket.emit('taskUpdateChecklistItem', taskId, {
          checkListId: checkListId,
          checkListItems: result.data.newItems.map((item) => {
            return {
              _id: item._id,
              name: item.name,
              isChecked: false,
            };
          }),
        });
      }
      // dispatchToast(dispatch, "success", `Project Added Successfully`);
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {
      // dispatchLoading(dispatch, "addProject", false);
      // dispatch({ type: "CLOSE_ADD_NEW_PROJECT_MODAL" });
    });
};

export const addAttachmentToTaskAPI = (data, taskId, socket) => async (dispatch) => {
  // dispatchLoading(dispatch, "addProject", true);
  axios
    .put(`${BACKEND_API_URL}/tasks/${taskId}`, data)
    .then((result) => {
      dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
      socket.emit(`taskUpdate`, result.data);
      // dispatch({
      //   type: "ADD_NEW_LIST",
      //   payload: { _id: result.data._id, title: result.data.title },
      // });
      // if (result.status === 201) {
      //   dispatch(fetchStatusApiForProject(data.project));
      // }
      // dispatchToast(dispatch, "success", `Project Added Successfully`);
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {
      // dispatchLoading(dispatch, "addProject", false);
      // dispatch({ type: "CLOSE_ADD_NEW_PROJECT_MODAL" });
    });
};

export const addCommentAPI = (data, taskId, socket) => async (dispatch) => {
  // dispatchLoading(dispatch, "addProject", true);
  axios
    .post(`${BACKEND_API_URL}/tasks/comment/${taskId}`, data)
    .then((result) => {
      dispatch({ type: 'SET_UPDATE_FLAG', payload: true });

      dispatch({
        type: 'UPDATE_COMMENTS',
        payload: result.data.task.comments,
      });

      socket.emit('taskUpdates', taskId, {
        type: 'UPDATE_COMMENTS',
        payload: result.data.task.comments,
      });
      // if (result.status === 201) {
      //   dispatch(fetchStatusApiForProject(data.project));
      // }
      // dispatchToast(dispatch, "success", `Project Added Successfully`);
    })
    .catch((error) => {
      dispatchToast(dispatch, 'error', error.response.data.message);
    })
    .finally(() => {
      // dispatchLoading(dispatch, "addProject", false);
      // dispatch({ type: "CLOSE_ADD_NEW_PROJECT_MODAL" });
    });
};

export const moveTaskAPI = (taskId, data) => async (dispatch) => {
  try {
    const response = await axios.post(`${BACKEND_API_URL}/tasks/${taskId}/move-or-copy`, data);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting data:', error.response.data.message);
    message.error(error.response.data.message);
    return null;
  }
};
