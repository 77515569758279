import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Col, Progress, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCheckListAPI, deleteChecklistItemAPI } from '../Redux/deleteItemAPI';
import { Input, Popconfirm } from 'antd';
import { AddCheckListItemAPI } from '../Redux/addItemAPI';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { updateCheckListItemAPI } from '../Redux/updateItemAPI';
import { ACCESS } from '../../utils';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { moveChecklist, reorderChecklist } from '../Redux/reorderAPI';
import { useSocket } from '../../helpers/SocketContext';
const { TextArea } = Input;

const CheckListComponent = React.forwardRef((props, ref) => {
  let { task, checkLists, access } = useSelector((state) => state.addTask);
  // useEffect(() => {
  //   console.log("Ref Useeffect");
  //   if (ref.current) {
  //     console.log("Scrolling");
  //     ref.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //   }
  // }, [checkLists]);

  // useEffect(() => {
  //   console.log('Checklist render:', checkLists);
  // }, [checkLists]);

  return (
    <>
      {checkLists?.map((checkList, index) => (
        <SingleCheckList
          ref={index === checkLists.length - 1 ? ref : null}
          key={checkList._id}
          taskId={task._id}
          checkList={checkList}
          access={access}
        />
      ))}
    </>
  );
});

export default CheckListComponent;

const SingleCheckList = React.forwardRef(({ taskId, checkList, access }, ref) => {
  const socket = useSocket();
  const checkListId = checkList._id;
  const [checkListInput, setCheckListInput] = useState('');
  const [items, setItems] = useState(checkList?.items);
  const dispatch = useDispatch();

  useEffect(() => {
    setItems(checkList?.items);
  }, [checkList?.items]);

  const saveCheckListItemHandler = () => {
    if (access === ACCESS.VIEW) {
      return;
    }
    if (!checkListInput) {
      return;
    }
    const itemsArray = checkListInput.split('\n').filter((item) => item.trim() !== '');
    console.log('Checklist Items:', itemsArray);
    const dataToSend = { checklistItems: [...itemsArray] };
    dispatch(AddCheckListItemAPI(dataToSend, taskId, checkList._id, socket));
    setCheckListInput('');
  };

  const toggleCheck = (itemId) => {
    const checkListItem = items.find((item) => item._id === itemId);
    console.log('Checklist Item:', checkListItem);
    if (checkListItem) {
      const dataToSend = {
        taskId: taskId,
        itemId: checkListItem._id,
        isChecked: !checkListItem.isChecked,
      };
      dispatch(updateCheckListItemAPI(dataToSend));
    }
    dispatch({
      type: 'TOGGLE_CHECKLIST_ITEM',
      payload: {
        itemId: itemId,
      },
    });
    socket.emit('toggleChecklistItem', taskId, {
      itemId: itemId,
    });
    // setItems((prevState) => {
    //   const selectedItemIndex = prevState.findIndex(
    //     (item) => item._id === itemId
    //   );
    //   prevState[selectedItemIndex].isChecked =
    //     !prevState[selectedItemIndex].isChecked;
    //   return [...prevState];
    // });
  };

  function calculateCheckedPercentage(checklist) {
    const checkedItems = checklist.items.filter((item) => item.isChecked).length;
    const totalItems = checklist.items.length;
    const percentageChecked = (checkedItems / totalItems) * 100;

    const decimalPart = percentageChecked - Math.floor(percentageChecked);
    let roundedPercentage;

    if (decimalPart > 0.5) {
      roundedPercentage = Math.ceil(percentageChecked);
    } else {
      roundedPercentage = Math.floor(percentageChecked);
    }

    return roundedPercentage;
  }

  const deleteChecklistHandler = () => {
    const dataToSend = {
      deleteChecklist: [checkList._id],
    };
    // console.log("Data to send:", dataToSend);

    dispatch(deleteCheckListAPI(dataToSend, taskId, checkList._id, socket));
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const draggedItem = items[dragIndex];
    const newItems = [...items];
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, draggedItem);
    setItems(newItems);
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  return (
    <>
      <div className="d-flex flex-column col-12">
        <div className="d-flex flex-row justify-content-between">
          <p className="ff ff_md f16 txt-lightGray mb-0" ref={ref}>
            {checkList.title}
          </p>
          {/* <Popconfirm
            title="Delete the checklist"
            description="Are you sure to delete this checklist?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={deleteChecklistHandler}
            okText="Yes"
            cancelText="No"
            className="centered-popconfirm"
          >
            <button className="ff ff_rg f16 c-white txt-error">Delete</button>
          </Popconfirm> */}

          {access === ACCESS.EDIT && (
            <button
              className="ff ff_rg f16 c-white txt-error"
              onClick={() => {
                if (access === ACCESS.VIEW) {
                  return;
                }
                setDeleteModalOpen(true);
              }}
            >
              Delete
            </button>
          )}
        </div>
        <Progress percent={calculateCheckedPercentage(checkList)} strokeColor={'#1f63aa'} />
        {/* <Progress percent={checkListProgress} strokeColor={"#1f63aa"} /> */}
        {items &&
          items?.map((item, index) => {
            return (
              <CheckListItem
                moveItem={moveItem}
                mainChecklistId={checkListId}
                taskId={taskId}
                access={access}
                item={item}
                toggleCheck={toggleCheck}
                key={item._id}
                index={index}
              />
            );
          })}
        {access === ACCESS.EDIT && (
          <>
            <TextArea
              className="mb-2 ff ff_rg f14 form-control"
              value={checkListInput}
              onChange={(e) => {
                let inputValue = e.target.value;

                if (inputValue.length > 0) {
                  inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }

                setCheckListInput(inputValue);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  saveCheckListItemHandler();
                }
              }}
              placeholder="Add checklist point"
              disabled={access === ACCESS.VIEW}
              autoSize={{ minRows: 1.15, maxRows: 6 }} // Use autosize instead of autoSize
            />
            <div className="d-flex flex-row justify-content-between mt-2 mb-3">
              <div className="d-flex flex-row gap-1">
                <button type="submit" className="btn btn-primary px-4" onClick={saveCheckListItemHandler}>
                  Add
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <DeleteConfirmationModal
        checklistName={checkList.title}
        modalVisible={deleteModalOpen}
        setModalVisible={setDeleteModalOpen}
        onConfirm={deleteChecklistHandler}
      />
      <hr className="antModalDivider mt-1 col-12 col-md-9" />
    </>
  );
});

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

const CheckListItem = ({ item, access, toggleCheck, taskId, index, mainChecklistId, moveItem }) => {
  const socket = useSocket();
  const [title, setTitle] = useState(item?.name);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const dispatch = useDispatch();

  const updateChecklistTitle = useCallback((checkListItemId, title) => {
    const dataToSend = {
      taskId: taskId,
      itemId: checkListItemId,
      title: title,
    };
    dispatch(updateCheckListItemAPI(dataToSend));
    console.log('Data To Send: ', dataToSend);
  }, []);

  const delayedChecklistTitleUpdate = useCallback(debounce(updateChecklistTitle, 1000), [updateChecklistTitle]);

  const updateTitleHandler = (e) => {
    setTitle(e.target.value);
    delayedChecklistTitleUpdate(item?._id, e.target.value);
  };

  const [{ dragging }, drag] = useDrag({
    type: ItemTypes.CHECKLIST_ITEM,
    item: { id: item?._id, index, checkListId: mainChecklistId },
    collect: (monitor) => ({
      dragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.CHECKLIST_ITEM,
    drop: (droppedItem, draggedItem) => {
      if (droppedItem.checkListId === mainChecklistId) {
        console.log('Same Checklist', index);
        const dataToSend = {
          taskId: taskId,
          itemId: droppedItem.id,
          newIndex: index,
        };
        dispatch(reorderChecklist(dataToSend, mainChecklistId, droppedItem.index, index));
      } else {
        console.log('Different Checklist', index);
        const dataToSend = {
          taskId: taskId,
          itemId: droppedItem.id,
          newIndex: index,
          currentChecklist: droppedItem.checkListId,
          newChecklist: mainChecklistId,
        };
        dispatch(moveChecklist(dataToSend, droppedItem.checkListId, mainChecklistId, droppedItem.id, index));
        // dispatch({
        //   type: "MOVE_CHECKLIST_ITEM",
        //   payload: {
        //     sourceChecklistId: droppedItem.checkListId,
        //     destinationChecklistId: mainChecklistId,
        //     checklistItemId: droppedItem.id,
        //     destinationIndex: index,
        //   },
        // });
      }
      // console.log(
      //   "Dropped item:",
      //   droppedItem,
      //   "From Index:",
      //   droppedItem.index,
      //   "To Index:",
      //   index
      // );

      // moveItem(droppedItem.index, index);
      // droppedItem.index = index;
      // Perform reorder logic here
    },
    // hover: (draggedItem) => {
    //   if (draggedItem.index !== index) {
    //     moveItem(draggedItem.index, index);
    //     draggedItem.index = index;
    //   }
    // },
  });
  return (
    <div ref={(node) => drag(drop(node))} className={`mb-2 form-check checkListcheckbox d-flex ${dragging ? '' : ''}`}>
      <ItemDeleteConfirmationModal
        checklistItemName={title}
        modalVisible={isDeleteOpen}
        setModalVisible={setIsDeleteOpen}
        onConfirm={() => {
          dispatch(deleteChecklistItemAPI(taskId, mainChecklistId, item?._id, socket));
          // dispatch({
          //   type: "DELETE_CHECKLIST_ITEM",
          //   payload: {
          //     mainCheckListId: mainChecklistId,
          //     childItemId: item?._id,
          //   },
          // });
          // message.info("Item deleted");
        }}
      />
      <input
        type="checkbox"
        disabled={access === ACCESS.VIEW}
        className="form-check-input fs-6"
        checked={item?.isChecked}
        onChange={() => toggleCheck(item?._id)}
        id={item?._id}
      />
      {/* <label className="form-check-label" htmlFor={item?._id}>
        {item.name}
      </label> */}
      <Input.TextArea
        className={`ff ff_md f18 mb-0`}
        autoSize={{ minRows: 1, maxRows: 6 }}
        style={{ border: 'none', padding: '0', marginLeft: '6px' }}
        value={title}
        onChange={updateTitleHandler}
        disabled={access === ACCESS.VIEW}
      />

      {access === ACCESS.EDIT && (
        <Col xs="auto" className="ml-auto">
          <div
            style={{ marginLeft: '20px' }}
            onClick={() => {
              //   message.info("Delete");
              setIsDeleteOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="text-danger" />
          </div>
        </Col>
      )}
    </div>
  );
};

const DeleteConfirmationModal = ({ checklistName, modalVisible, setModalVisible, onConfirm }) => {
  const handleDelete = () => {
    if (onConfirm) {
      onConfirm();
    }
    setModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Delete the checklist"
        open={modalVisible}
        centered
        onCancel={() => setModalVisible(false)}
        footer={[
          <button key="cancel" className="ff f16 c-white" onClick={() => setModalVisible(false)}>
            Cancel
          </button>,
          <button key="delete" className="ff f16 c-white txt-error" onClick={handleDelete}>
            Remove
          </button>,
        ]}
      >
        <p>Aye you sure you want to delete checklist {<span style={{ fontWeight: 'bold' }}>{checklistName}</span>}?</p>
      </Modal>
    </>
  );
};

const ItemDeleteConfirmationModal = ({ checklistItemName, modalVisible, setModalVisible, onConfirm }) => {
  const handleDelete = () => {
    if (onConfirm) {
      onConfirm();
    }
    setModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Delete the checklist item"
        open={modalVisible}
        centered
        onCancel={() => setModalVisible(false)}
        footer={[
          <button key="cancel" className="ff f16 c-white" onClick={() => setModalVisible(false)}>
            Cancel
          </button>,
          <button key="delete" className="ff f16 c-white txt-error" onClick={handleDelete}>
            Remove
          </button>,
        ]}
      >
        <p>
          Aye you sure you want to delete checklist item{' '}
          {<span style={{ fontWeight: 'bold' }}>{checklistItemName}</span>}?
        </p>
      </Modal>
    </>
  );
};
