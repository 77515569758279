var CryptoJS = require('crypto-js');
export const momentDateTimeFormat = 'DD/MM/YYYY HH:mm';
export const dayjsDateFormat = 'DD/MM/YYYY';

export const url = 'http://localhost:3000/';

/** Environment */
export let API_URL;
export let API_KEY;
export let BUCKET_URL;
export let PROFILE_URL;
export const API_VERSION = `v1`;
export const IS_DEV = 0;
// export const alias = "/pms";
export const alias = '/task';

export const OPERATION = {
  MOVE: 'move',
  COPY: 'copy',
};

export const ROLE = {
  ADMIN: 'admin',
  USER: 'user',
  EMPLOYEE: 'employee',
  PROJECTMANAGER: 'project_manager',
  CLIENT: 'client',
};

export const ACCESS = {
  EDIT: 'edit',
  VIEW: 'view',
};

switch (IS_DEV) {
  case 0:
    API_URL = `http://localhost:3000`;
    API_KEY = 'U2FsdGVkX18l+rSEzLZCDGG6Lo/I6Y0Op5wELasQbZ0WtTLKkXoG1J1o/mfjNwXr';
    break;
  case 1:
    API_URL = `https://dev.3rddigital.com/kriyauniverse-api/api/${API_VERSION}`;
    API_KEY =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJzZXJ2ZXJFbnYiOiJwcm9kIiwiY3JlYXRlZF9hdCI6IjIwMjQtMDItMTVUMDQ6NTA6NDcuNTczWiIsImlhdCI6MTcwNzk3MjY0NywiYXVkIjoiYWRtaW4ifQ.aqEfetvbJF2P6o38vJHN1ZPuN_Kk9gtJWaMMgI-pcWM';
    break;
  default:
}

// export const convertToLinks = (text, onLinkClick) => {
//   const urlRegex = /(https?:\/\/[^\s<]+(?:\.[^\s<]+)+)/g;

//   return text
//     ?.split(urlRegex)
//     .map((part, index) => {
//       if (part.match(urlRegex)) {
//         return `<a
//                 key="${index}"
//                 href="${part}"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 onClick="${onLinkClick ? 'event.stopPropagation()' : ''}">
//                   ${part}
//               </a>`;
//       }
//       return part;
//     })
//     .join('');
// };

export const convertToLinks = (text, onLinkClick) => {
  const urlRegex = /(https?:\/\/[^\s<]+(?:\.[^\s<]+)+)/g;

  // Match all URLs, but avoid wrapping URLs that are already inside <a> tags
  return text?.replace(urlRegex, (match) => {
    // Check if the match is already inside an <a> tag (to prevent double wrapping)
    if (/(<a[^>]*>)(.*?)(<\/a>)/i.test(match)) {
      return match; // If the match is inside an anchor, don't wrap it again
    }

    // If it's a plain URL, wrap it in an anchor tag
    return `<a 
              href="${match}" 
              target="_blank" 
              rel="noopener noreferrer" 
              onClick="${onLinkClick ? 'event.stopPropagation()' : ''}">
                ${match}
            </a>`;
  });
};

export async function decrypt(text) {
  try {
    let secretKey = process.env.REACT_APP_SECRET;
    const keyutf = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.enc.Utf8.parse('678025308de70905');
    const decrypted = CryptoJS.AES.decrypt(text, keyutf, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  } catch (error) {
    console.error('Error decrypting text:', error);
    throw error;
  }
}

export const setMetaTag = (name, content) => {
  let element = document.querySelector(`meta[name="${name}"]`) || document.createElement('meta');
  element.name = name;
  element.content = content;
  document.getElementsByTagName('head')[0].appendChild(element);
};

export const setMetaProperty = (property, content) => {
  let element = document.querySelector(`meta[property="${property}"]`) || document.createElement('meta');
  element.setAttribute('property', property);
  element.content = content;
  document.getElementsByTagName('head')[0].appendChild(element);
};

export const ViewType = {
  StatusView: 'status',
  ListView: 'list',
  Dashboard: 'dashboard',
};

export const FormatDateForTaskCard = (dateString) => {
  const date = new Date(dateString);

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const month = months[monthIndex];

  const formattedDate = `${day} ${month}`;

  return formattedDate;
};

export const dispatchLoading = (dispatch, scope, status) =>
  dispatch({ type: 'SET_LOADING', payload: { scope, status } });
export const dispatchToast = (dispatch, scope, status) => dispatch({ type: 'SET_TOAST', payload: { scope, status } });
export const dispatchError = (dispatch, scope, status) => dispatch({ type: 'SET_ERROR', payload: { scope, status } });
export const catchHandler = (dispatch, scope) => (err) => {
  if (err.code === 'ERR_NETWORK') dispatchToast(dispatch, 'error', 'Unable to connect to server');
  else if (err?.response?.status !== 401) dispatchError(dispatch, scope, err?.response?.data?.error);
  else dispatchToast(dispatch, 'error', err?.response?.data?.msg);
  dispatchLoading(dispatch, scope, false);
};
export const elseHandler = (dispatch, scope, data) => {
  dispatchToast(dispatch, 'error', data?.msg);
  dispatchError(dispatch, scope, data?.error);
};

export const convertDateFormat = (dateString) => {
  const [day, month, year] = dateString.split('/');

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

// export const firstCharacters = (inputString) => {
//   const words = inputString.split(" ");

//   const firstCharacterFirstWord =
//     words.length > 0 ? words[0].charAt(0).toUpperCase() : "";
//   const firstCharacterSecondWord =
//     words.length > 1 ? words[1].charAt(0).toUpperCase() : "";
//   let initial = firstCharacterFirstWord + firstCharacterSecondWord;

//   return initial;
// };

export const firstCharacters = (inputString) => {
  const words = inputString.split(' ');

  if (words.length >= 2) {
    return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
  } else if (words.length === 1) {
    return words[0].charAt(0).toUpperCase();
  } else {
    return '';
  }
};

export const Reset = () => {
  // localStorage.setItem("isLoggedIn", false);
  // localStorage.removeItem('token');
};

export const SiteLoader = ({ text }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        width: 'calc(100% - 1rem)',
      }}
    >
      <p className="Loader">
        <div className="d-block">
          <div className="spinner-border" role="status"></div>
        </div>
      </p>
      <p className="ff_md" style={{ fontSize: '1.5rem', lineHeight: '1.1' }}>
        {text !== undefined ? text : 'Loading...'}
      </p>
    </div>
  );
};

export const PageLoader = () => {
  return (
    <div className="PageLoader">
      <div className="">
        <div className="Loader">
          <div className="d-block">
            <div className="spinner-border" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
