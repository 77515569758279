import { Avatar, Modal, Upload, Input, message, Tooltip, Button, Mentions, Col } from 'antd';
import { scroller } from 'react-scroll';
import { useDropzone } from 'react-dropzone';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { AdminInputBoxLarge, InputBox } from '../../Auth/components/Inputbox';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BtnLoader from '../../Components/BtnLoader';
import CloseIcon from '../../Components/CloseIcon';
import PlusIcon from '../assets/plusIcon.svg';
import PlusIconLarge from '../assets/plusIconLarge.svg';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustomDropDown from '../components/CustomDropDown';
import { DatePickerIconSmall } from '../assets/IconList';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AttachMentIcon, CheckListIcon, FileUpload, LabelIcon, MemberIcon } from '../assets/IconList';
import CloseIconSmall from '../../Components/CloseIconSmall';
import CustomDateTimeRangePicker from './CustomDateTimeRangePicker';
import { ACCESS, OPERATION, ROLE, SiteLoader, ViewType, convertToLinks, decrypt } from '../../utils';
import { AddEntityAPI } from '../Redux/addEntityAPI';
import EnteredDataRowProject from './EnteredDataRowProject';
import {
  AddCheckListAPI,
  AddProjectAPI,
  AddStatusAPI,
  AddTaskAPI,
  addAttachmentToTaskAPI,
  addCommentAPI,
  moveTaskAPI,
} from '../Redux/addItemAPI';
import { useLocation, useNavigate } from 'react-router-dom';
import EnteredDataRowTask from './EnteredDataRowTask';
import {
  fetchProjectMembers,
  fetchStatusApiForProject,
  fetchTaskDetails,
  fetchProjectLists,
  fetchUpdatedTask,
} from '../Redux/fetchItemsAPI';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  UpdateProjectAPI,
  editCommentAPI,
  updateCheckListAPI,
  updateTaskAPI,
  updateTaskDescAPI,
} from '../Redux/updateItemAPI';
import CheckListComponent from './CheckListComponent';
import LoadingPreviewComponent from './LoadingPreviewComponent';

import { DropboxOutlined, DeleteOutlined, ShareAltOutlined, ArrowRightOutlined, CopyOutlined } from '@ant-design/icons';
import { deleteCommentAPI, deleteTaskAPI } from '../Redux/deleteItemAPI';
import { archiveTaskAPI } from '../Redux/archiveItemAPI';
import SingleLableComponent from './SingleLableComponent';
import AttachmentPreviewModal from './AttachmentPreviewModal';
import { Helmet } from 'react-helmet';
import { useSocket } from '../../helpers/SocketContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import linkifyHtml from 'linkify-html';
import { htmlToText } from 'html-to-text';

export const MoveTaskModal = React.memo(({ moveDetails, closeModal, taskId }) => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  let { projects } = useSelector((state) => state.dashboard);
  let { lists, projectDetails, task } = useSelector((state) => state.addTask);
  const { viewType } = useSelector((state) => state.viewType);
  const { addTaskLoading } = useSelector((state) => state.loading);
  const [projectOptionsArray, setProjectOptionsArray] = useState([]);
  const [listOptionsArray, setListOptionsArray] = useState([]);

  const [projectName, setProjectName] = useState('');
  const [selectedProjectSlug, setSelectedProjectSlug] = useState('');
  const [projectId, setProjectId] = useState('');
  const [projectData, setProjectData] = useState('');
  const [loading, setLoading] = useState(false);

  const [taskList, setTaskList] = useState('');
  const [taskListId, setTaskListId] = useState('');

  const [taskTitle, setTaskTitle] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    const projectSlugToFetch = projectData.slug;
    const projectIdToFetch = projectData._id;
    if (projectIdToFetch) {
      setListOptionsArray(null);
      dispatch(fetchProjectMembers(projectSlugToFetch));
      dispatch(fetchProjectLists(projectSlugToFetch));
    }
  }, [dispatch, projectData._id, projectData.slug]);

  useEffect(() => {
    const updatedOptions = projects.map((project) => ({
      value: project._id,
      label: project.title,
      slug: project.slug,
    }));
    setProjectOptionsArray(updatedOptions);
  }, [projects]);

  useEffect(() => {
    const updatedOptions = lists.map((list) => ({
      value: list._id,
      label: list.title,
    }));
    setListOptionsArray(updatedOptions);
  }, [lists]);

  const addHandler = async () => {
    const dataToSend = {
      targetProjectId: projectId,
      targetListId: taskListId,
      action: moveDetails.operation === OPERATION.MOVE ? 'move' : 'copy',
    };
    setLoading(true);
    const response = await dispatch(moveTaskAPI(moveDetails._id, dataToSend));
    setLoading(false);
    if (response) {
      closeModal();
      if (moveDetails.operation === OPERATION.MOVE) {
        if (viewType === ViewType.ListView) {
          dispatch({ type: 'DELETE_TASK_LISTVIEW', payload: { idOfList: task.list, idOfTask: task._id } });
        } else if (viewType === ViewType.StatusView) {
          task.status.forEach((item) => {
            dispatch({
              type: 'DELETE_TASK_STATUSVIEW',
              payload: { idOfStatus: item._id, idOfTask: task._id },
            });
          });
        }
      }
      dispatch({ type: 'SANITIZE_ADD_TASK_STORE' });
      dispatch({ type: 'IS_STEP_ACTIVE', payload: false });
      dispatch({ type: 'CHANGE_STEP', payload: 0 });
      navigate(`/projects/${projectDetails.slug}`, {
        state: { doNotRefetch: true },
      });
    }

    // dispatch(
    //   AddTaskAPI(
    //     dataToSend,
    //     Object.keys(projectDetails).length > 0 ? projectDetails._id : projectId,
    //     Object.keys(projectDetails).length > 0 ? projectDetails.title : projectName,
    //     Object.keys(projectDetails).length > 0 ? projectDetails.slug : projectData?.slug,
    //     navigate,
    //     isSave
    //   )
    // );
  };

  const filteredProjectOptions = projectOptionsArray.filter((option) => option.value !== projectDetails._id);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const handleProjectSelectChange = (option) => {
    setSelectedProjectSlug(option.slug);
    setProjectName(option.label);
    setProjectId(option.value);
    const project = projects.find((project) => project._id === option.value);
    if (project) {
      setProjectData(project);
    }
  };

  const handleCategorySelectChange = (option) => {
    setTaskList(option.label);
    setTaskListId(option.value);
  };

  useEffect(() => {
    if (moveDetails) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    }
  }, [moveDetails]);
  return (
    <>
      <Modal
        open={moveDetails}
        onCancel={closeModal}
        maskClosable={false}
        closable={true}
        centered={true}
        closeIcon={<CloseIcon />}
        width={'31.25rem'}
        footer={null}
        className=""
      >
        <h3 className="text-center mt-3 mb-3 txt-primary ff ff_bd f24 responsiveModalLabel mb-1">
          {moveDetails?.operation === OPERATION.MOVE ? 'Move Task' : 'Copy Task'}
        </h3>
        <div className="d-flex flex-column justify-content-center">
          <div className="mb-3">
            <label className="ff ff_md f16 pb-1">{'Project Name'}</label>
            <Select
              ref={inputRef}
              placeholder="Select Project"
              options={filteredProjectOptions}
              onChange={handleProjectSelectChange}
            />
          </div>
          <div className="mb-3">
            <label className="ff ff_md f16 pb-1">{'Task List'}</label>
            <Select placeholder="Select Task List" options={listOptionsArray} onChange={handleCategorySelectChange} />
          </div>
          <div className="d-flex justify-content-evenly sticky-bottom bg-ffffff py-3">
            <button
              className="btn btn-primary btn-lg px-5"
              onClick={() => {
                if (loading) {
                  return;
                }
                addHandler();
              }}
            >
              {loading ? <BtnLoader /> : moveDetails.operation === OPERATION.MOVE ? 'Move' : 'Copy'}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});

export const AddEntityModal = React.memo(() => {
  const inputRef = useRef(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const { isOpen } = useSelector((state) => state.addModal);
  const { label } = useSelector((state) => state.addModal);
  let { addEntityLoading } = useSelector((state) => state.loading);
  let role;

  switch (label) {
    case 'Employee':
      role = ROLE.EMPLOYEE;
      break;
    case 'Client':
      role = ROLE.CLIENT;
      break;
    case 'Project Manager':
      role = ROLE.PROJECTMANAGER;
      break;
    default:
      role = '';
  }

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    }
  }, [isOpen]);

  const addHandler = () => {
    const validationErrors = {};
    if (!email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = 'Invalid email format';
    }
    if (!name.trim()) {
      validationErrors.name = 'Name is required';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      dispatch(AddEntityAPI({ name, email, role }, `CLOSE_${label.replace(/\s/g, '').toUpperCase()}_MODEL`, label));
    }

    // dispatch(
    //   AddEntityAPI(
    //     { name, email, role },
    //     `CLOSE_${label.replace(/\s/g, "").toUpperCase()}_MODEL`,
    //     label
    //   )
    // );

    // setName("");
    // setEmail("");
  };

  const dispatch = useDispatch();
  const cancelHandler = () => {
    setName('');
    setEmail('');
    setErrors({});
    dispatch({ type: `CLOSE_${label.replace(/\s/g, '').toUpperCase()}_MODEL` });
  };

  useEffect(() => {
    if (!isOpen) {
      setName('');
      setEmail('');
      setErrors({});
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onOk={addHandler}
      onCancel={cancelHandler}
      footer={null}
      maskClosable={false}
      closable={true}
      centered={true}
      width={'31.25rem'}
      className="modelMaxHeight"
    >
      <h3 className="text-center mt-4 mb-3 txt-primary ff ff_bd f24 responsiveModalLabel">{`Add ${label}`}</h3>
      <div className="d-flex flex-column justify-content-center">
        <div className="mb-3">
          <InputBox label={`${label} Name`} cla>
            <input
              ref={inputRef}
              type="text"
              value={name}
              onChange={(e) => {
                let inputValue = e.target.value;

                if (inputValue.length > 0) {
                  inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }

                setName(inputValue);
              }}
              className="FormInput form-control"
              autoComplete="off"
              placeholder="Enter Name"
            />
          </InputBox>
          {errors.name && <span className="text-danger">{errors.name}</span>}
        </div>

        <div className="mb-3">
          <InputBox label={`${label} Email`}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="FormInput form-control"
              autoComplete="off"
              placeholder="Enter Email"
            />
          </InputBox>
          {errors.email && <span className="text-danger">{errors.email}</span>}
        </div>
        <div className="d-flex justify-content-center mt-3 mb-4" onClick={addHandler}>
          <button className="btn btn-primary px-5">
            {addEntityLoading ? <BtnLoader height={'30px'} width={'80px'} /> : 'Add'}
          </button>
        </div>
      </div>
    </Modal>
  );
});

// // Add Employee Modal
// export const AddEmployee = () => {
//   const [employeeName, setEmployeeName] = useState("");
//   const [employeeEmail, setEmployeeEmail] = useState("");
//   const [errors, setErrors] = useState({});
//   // const [isOpen, setIsOpen] = useState(true);
//   let { isEmployeeOpen } = useSelector((state) => state.modal);
//   let { addEmployeeLoading } = useSelector((state) => state.loading);

//   const addHandler = () => {
//     // if (employeeEmail === "" || employeeName === "") {
//     //   return;
//     // }

//     const validationErrors = {};
//     if (!employeeEmail.trim()) {
//       validationErrors.email = "Email is required";
//     } else if (!/\S+@\S+\.\S+/.test(employeeEmail)) {
//       validationErrors.email = "Invalid email format";
//     }
//     if (!employeeName.trim()) {
//       validationErrors.name = "Name is required";
//     }

//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//     } else {
//       // setEmployeeEmail("");
//       // setEmployeeName("");
//       // setErrors({});
//       dispatch(
//         AddEmployeeAPI({
//           name: employeeName,
//           email: employeeEmail,
//           role: ROLE.EMPLOYEE,
//         })
//       );
//     }
//   };

//   const dispatch = useDispatch();
//   const cancelSuperHandler = () => {
//     setEmployeeName("");
//     setEmployeeEmail("");
//     setErrors({});
//     dispatch({ type: "CLOSE_ADD_EMPLOYEE_MODAL" });
//   };

//   useEffect(() => {
//     if (!isEmployeeOpen) {
//       setEmployeeName("");
//       setEmployeeEmail("");
//       setErrors({});
//     }
//   }, [isEmployeeOpen]);

//   return (
//     <Modal
//       open={isEmployeeOpen}
//       onOk={addHandler}
//       onCancel={cancelSuperHandler}
//       footer={null}
//       maskClosable={false}
//       closable={true}
//       centered={true}
//       closeIcon={<CloseIcon />}
//       width={"31.25rem"}
//       className="modelMaxHeight"
//     >
//       <h3 className="text-center mt-4 mb-3 txt-primary ff ff_bd f24 responsiveModalLabel">
//         {"Add Employee"}
//       </h3>
//       <div className="d-flex flex-column justify-content-center">
//         <div className="ff ff_md f16 mx-4 my-2">
//           <InputBox label="Employee Name">
//             <input
//               type="text"
//               value={employeeName}
//               onChange={(e) => {
//                 setEmployeeName(e.target.value);
//               }}
//               className="FormInput form-control"
//               autoComplete="off"
//               placeholder="Enter Name"
//             />
//           </InputBox>
//           {errors.name && <span className="text-danger">{errors.name}</span>}
//         </div>

//         <div className="ff ff_md f16 mx-4 my-2">
//           <InputBox
//             label="Employee Email"
//             // nameOfClass={`ff ff_md f16 mx-4 my-2`}
//           >
//             <input
//               type="email"
//               value={employeeEmail}
//               onChange={(e) => {
//                 setEmployeeEmail(e.target.value);
//               }}
//               className="FormInput form-control"
//               autoComplete="off"
//               placeholder="Enter Email"
//             />
//           </InputBox>
//           {errors.email && <span className="text-danger">{errors.email}</span>}
//         </div>

//         <div
//           className="d-flex justify-content-center mt-3 mb-4"
//           onClick={addHandler}
//         >
//           <button className="adminAntModelAddButton ff ff_rg f16 py-3 txt-white justify-content-center">
//             {addEmployeeLoading ? <BtnLoader /> : "Add"}
//           </button>
//         </div>
//       </div>
//     </Modal>
//   );
// };

// // Add Client Modal
// export const AddClientModal = () => {
//   const [clientName, setClientName] = useState("");
//   const [clientEmail, setClientEmail] = useState();
//   // const [isOpen, setIsOpen] = useState(true);
//   let { isClientOpen } = useSelector((state) => state.modal);

//   const addHandler = () => {
//     console.log(clientName);
//     console.log(clientEmail);
//     // AddEmployeeAPI({name: employeeName, email: employeeEmail, role: ROLE.EMPLOYEE})
//     // dispatch({ type: "CLOSE_ADD_EMPLOYEE_MODAL" });
//   };

//   const dispatch = useDispatch();
//   const cancelSuperHandler = () => {
//     setClientName("");
//     setClientEmail("");
//     dispatch({ type: "CLOSE_ADD_CLIENT_MODAL" });
//   };

//   return (
//     <Modal
//       open={isClientOpen}
//       onOk={addHandler}
//       onCancel={cancelSuperHandler}
//       footer={null}
//       maskClosable={false}
//       closable={true}
//       centered={true}
//       closeIcon={<CloseIcon />}
//       width={"31.25rem"}
//       className="modelMaxHeight"
//     >
//       <h3 className="text-center mt-4 mb-3 txt-primary ff ff_bd f24 responsiveModalLabel">
//         {"Add Client"}
//       </h3>
//       <div className="d-flex flex-column justify-content-center">
//         <InputBox label="Client Name" nameOfClass={`ff ff_md f16 mx-4 my-2`}>
//           <input
//             type="text"
//             value={clientName}
//             onChange={(e) => {
//               setClientName(e.target.value);
//             }}
//             className="FormInput form-control"
//             autoComplete="off"
//             placeholder="Enter Name"
//           />
//         </InputBox>
//         <InputBox label="Client Email" nameOfClass={`ff ff_md f16 mx-4 my-2`}>
//           <input
//             type="email"
//             value={clientEmail}
//             onChange={(e) => {
//               setClientEmail(e.target.value);
//             }}
//             className="FormInput form-control"
//             autoComplete="off"
//             placeholder="Enter Email"
//           />
//         </InputBox>
//         <div
//           className="d-flex justify-content-center mt-3 mb-4"
//           onClick={addHandler}
//         >
//           <button className="adminAntModelAddButton ff ff_rg f16 py-3 txt-white justify-content-center">
//             Add
//           </button>
//         </div>
//       </div>
//     </Modal>
//   );
// };

// // Add PM Modal
// export const AddProjectManagerModal = () => {
//   const [pmName, setPmName] = useState("");
//   const [pmEmail, setPmEmail] = useState();
//   // const [isOpen, setIsOpen] = useState(true);
//   let { isProjectManagerOpen } = useSelector((state) => state.modal);

//   const addHandler = () => {
//     console.log(pmName);
//     console.log(pmEmail);
//     // AddEmployeeAPI({name: employeeName, email: employeeEmail, role: ROLE.EMPLOYEE})
//     // dispatch({ type: "CLOSE_ADD_EMPLOYEE_MODAL" });
//   };

//   const dispatch = useDispatch();
//   const cancelSuperHandler = () => {
//     setPmName("");
//     setPmEmail("");
//     dispatch({ type: "CLOSE_ADD_PROJECTMANAGER__MODAL" });
//   };

//   return (
//     <Modal
//       open={isProjectManagerOpen}
//       onOk={addHandler}
//       onCancel={cancelSuperHandler}
//       footer={null}
//       maskClosable={false}
//       closable={true}
//       centered={true}
//       closeIcon={<CloseIcon />}
//       width={"31.25rem"}
//       className="modelMaxHeight"
//     >
//       <h3 className="text-center mt-4 mb-3 txt-primary ff ff_bd f24 responsiveModalLabel">
//         {"Add Project Manager"}
//       </h3>
//       <div className="d-flex flex-column justify-content-center">
//         <InputBox
//           label="Project Manager Name"
//           nameOfClass={`ff ff_md f16 mx-4 my-2`}
//         >
//           <input
//             type="text"
//             value={pmName}
//             onChange={(e) => {
//               setPmName(e.target.value);
//             }}
//             className="FormInput form-control"
//             autoComplete="off"
//             placeholder="Enter Name"
//           />
//         </InputBox>
//         <InputBox
//           label="Project Manager Email"
//           nameOfClass={`ff ff_md f16 mx-4 my-2`}
//         >
//           <input
//             type="email"
//             value={pmEmail}
//             onChange={(e) => {
//               setPmEmail(e.target.value);
//             }}
//             className="FormInput form-control"
//             autoComplete="off"
//             placeholder="Enter Email"
//           />
//         </InputBox>
//         <div
//           className="d-flex justify-content-center mt-3 mb-4"
//           onClick={addHandler}
//         >
//           <button className="adminAntModelAddButton ff ff_rg f16 py-3 txt-white justify-content-center">
//             Add
//           </button>
//         </div>
//       </div>
//     </Modal>
//   );
// };

//Add New Project Modal
export const AddNewProject = React.memo(() => {
  const socket = useSocket();
  const user = JSON.parse(localStorage.getItem('user'));
  let { employees, clients, projectManagers } = useSelector((state) => state.dashboard);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState('');
  const [employeeDropdown, setEmployeeDropdown] = useState('');
  const [clientDropdown, setClientDropdown] = useState('');
  const [pmDropdown, setPMDropdown] = useState('');
  const [accessType, setAccessType] = useState('Edit');
  const [clientAccessType, setClientAccessType] = useState('Edit');
  const [pmAccessType, setPMAccessType] = useState('Edit');
  const [membersArrayOptions, setMembersArrayOption] = useState([]);
  const [clientsArrayOptions, setClientsArrayOption] = useState([]);
  const [pmArrayOptions, setPMArrayOption] = useState([]);

  const [stagedMembers, setStagedMembers] = useState([]);
  const [stagedClients, setStagedClients] = useState([]);
  const [stagedPMs, setStagedPMs] = useState([]);
  const [newMembersToAdd, setNewMembersToAdd] = useState([]);
  const [newClientsToAdd, setNewClientsToAdd] = useState([]);
  const [newPMsToAdd, setNewPMsToAdd] = useState([]);
  const [existingMembersToAdd, setExistingMembersToAdd] = useState([]);
  const [existingClientsToAdd, setExistingClientsToAdd] = useState([]);
  const [existingPMsToAdd, setExistingPMsToAdd] = useState([]);

  const [freshMembersToAdd, setFreshMembersToAdd] = useState([]);
  const [freshClientsToAdd, setFreshClientsToAdd] = useState([]);
  const [freshPMsToAdd, setFreshPMsToAdd] = useState([]);

  const [removedMembers, setRemovedMembers] = useState([]);

  let {
    isProjectOpen,
    isEditFlow,
    projectName: savedProjectName,
    projectId: savedProjectId,
    projectSlug,
  } = useSelector((state) => state.modal);
  let { members } = useSelector((state) => state.addTask);
  let { addProjectLoading } = useSelector((state) => state.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    const updatedMembersOptions = employees.map((employee) => ({
      value: employee._id,
      label: employee.name,
    }));
    setMembersArrayOption(updatedMembersOptions);

    const updatedClientsOptions = clients.map((client) => ({
      value: client._id,
      label: client.name,
    }));
    setClientsArrayOption(updatedClientsOptions);

    const updatedPMOptions = projectManagers.map((pm) => ({
      value: pm._id,
      label: pm.name,
    }));
    setPMArrayOption(updatedPMOptions);
  }, [employees, clients, projectManagers]);

  const handleChange = (value) => {
    setAccessType(value);
  };

  const handleClientAccessChange = (value) => {
    setClientAccessType(value);
  };

  const handlePMAccessChange = (value) => {
    setPMAccessType(value);
  };

  // const handleChange = (value) => {
  //   setAccessType(value);
  // };

  useEffect(() => {
    if (!isEditFlow) {
      return;
    }
    setProjectName(savedProjectName);
  }, [isEditFlow, savedProjectName]);

  useEffect(() => {
    if (!isEditFlow) {
      return;
    }
    const filteredMembers = members
      .filter((member) => member.role === ROLE.EMPLOYEE)
      .map((member) => ({
        id: member._id,
        name: member.name,
        email: member.email,
        type: member.access,
      }));
    setExistingMembersToAdd(
      filteredMembers.map((member) => {
        return { member: member.id, access: member.type };
      })
    );
    setStagedMembers(filteredMembers);

    const filteredClients = members
      .filter((member) => member.role === ROLE.CLIENT)
      .map((member) => ({
        id: member._id,
        name: member.name,
        email: member.email,
        type: member.access,
      }));
    setExistingClientsToAdd(
      filteredClients.map((member) => {
        return { member: member.id, access: member.type };
      })
    );
    setStagedClients(filteredClients);

    const filteredPMs = members
      .filter((member) => member.role === ROLE.PROJECTMANAGER)
      .map((member) => ({
        id: member._id,
        name: member.name,
        email: member.email,
        type: member.access,
      }));
    setExistingPMsToAdd(
      filteredPMs.map((member) => {
        return { member: member.id, access: member.type };
      })
    );
    setStagedPMs(filteredPMs);
  }, [isEditFlow, members]);

  // useEffect(() => {
  //   console.log("Existing Members :", existingMembersToAdd);
  // }, [existingMembersToAdd]);

  const clearHandler = () => {
    setProjectName('');
    setStagedMembers([]);
    setStagedClients([]);
    setStagedPMs([]);
    setEmployeeDropdown('');
    setClientDropdown('');
    setPMDropdown('');
    setAccessType('Edit');
    setClientAccessType('Edit');
    setPMAccessType('Edit');
    setNewMembersToAdd([]);
    setNewClientsToAdd([]);
    setNewPMsToAdd([]);
    setExistingMembersToAdd([]);
    setExistingClientsToAdd([]);
    setExistingPMsToAdd([]);
  };

  useEffect(() => {
    if (!isProjectOpen) {
      clearHandler();
    }
  }, [isProjectOpen]);

  const cancelSuperHandler = () => {
    clearHandler();
    dispatch({ type: 'CLOSE_ADD_NEW_PROJECT_MODAL' });
  };

  function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const createHandler = () => {
    if (!projectName) {
      return;
    }
    const existingMembersToSend = existingMembersToAdd.concat(existingClientsToAdd, existingPMsToAdd);

    const newMembersToSend = newMembersToAdd.concat(newClientsToAdd, newPMsToAdd);
    const dataToSend = {
      title: projectName,
      startDate: getCurrentDate(),
      teamMembers: existingMembersToSend,
      newMembers: newMembersToSend,
    };
    const freshAllMembers = freshMembersToAdd.concat(freshClientsToAdd, freshPMsToAdd);
    if (isEditFlow) {
      console.log('FreshMembers: ', freshAllMembers);

      dispatch(
        UpdateProjectAPI(savedProjectId, dataToSend, navigate, projectSlug, freshAllMembers, socket, removedMembers)
      );
    } else {
      dispatch(AddProjectAPI(dataToSend, navigate, socket));
    }
  };

  const deleteMemberHandler = (id, email) => {
    if (id) {
      setExistingMembersToAdd((prevState) => {
        return prevState.filter((member) => member.member !== id);
      });
      setStagedMembers((prevState) => {
        return prevState.filter((member) => member.id !== id);
      });

      setRemovedMembers((prevState) => [...prevState, { member: id }]);
    } else {
      setNewMembersToAdd((prevState) => {
        return prevState.filter((member) => member.email !== email);
      });
      setStagedMembers((prevState) => {
        return prevState.filter((member) => member.email !== email);
      });
    }
  };

  const deleteClientHandler = (id, email) => {
    if (id) {
      setExistingClientsToAdd((prevState) => {
        return prevState.filter((member) => member.member !== id);
      });
      setStagedClients((prevState) => {
        return prevState.filter((member) => member.id !== id);
      });
      setRemovedMembers((prevState) => [...prevState, { member: id }]);
    } else {
      setNewClientsToAdd((prevState) => {
        return prevState.filter((member) => member.email !== email);
      });
      setStagedClients((prevState) => {
        return prevState.filter((member) => member.email !== email);
      });
    }
  };

  const deletePMHandler = (id, email) => {
    if (id) {
      setExistingPMsToAdd((prevState) => {
        return prevState.filter((member) => member.member !== id);
      });
      setStagedPMs((prevState) => {
        return prevState.filter((member) => member.id !== id);
      });
      setRemovedMembers((prevState) => [...prevState, { member: id }]);
    } else {
      setNewPMsToAdd((prevState) => {
        return prevState.filter((member) => member.email !== email);
      });
      setStagedPMs((prevState) => {
        return prevState.filter((member) => member.email !== email);
      });
    }
  };

  useEffect(() => {
    if (isProjectOpen) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100); // 100ms delay
      setFreshClientsToAdd([]);
      setFreshMembersToAdd([]);
      setFreshPMsToAdd([]);
      setRemovedMembers([]);
    }
  }, [isProjectOpen]);

  const filterMembersOption = (option, inputValue) => {
    // Check if the option is already selected
    const isSelected = stagedMembers.some((selected) => selected.id === option.value);
    return !isSelected && option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const filterClientsOption = (option, inputValue) => {
    const isSelected = stagedClients.some((selected) => selected.id === option.value);
    return !isSelected && option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const filterPMsOption = (option, inputValue) => {
    const isSelected = stagedPMs.some((selected) => selected.id === option.value);
    return !isSelected && option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <Modal
      open={isProjectOpen}
      onCancel={cancelSuperHandler}
      footer={null}
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={'31.25rem'}
      className=""
    >
      <h3 className="text-center mt-4 mb-3 txt-primary ff ff_bd f24 responsiveModalLabel">
        {isEditFlow ? 'Edit Project' : 'New Project'}
      </h3>
      <div className="d-flex flex-column">
        <InputBox label="Project Name*" nameOfClass={`ff ff_md f16 mb-3`}>
          <input
            ref={inputRef}
            type="text"
            value={projectName}
            onChange={(e) => {
              let inputValue = e.target.value;
              if (inputValue.length > 25) {
                inputValue = inputValue.slice(0, 25);
              }
              if (inputValue.length > 0) {
                inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
              }

              setProjectName(inputValue);
            }}
            className="FormInput form-control"
            autoComplete="off"
            placeholder="Enter Project Name"
          />
        </InputBox>
        <div className="row align-items-end gx-2 gy-2 mb-2">
          <div className="col-md-6 col-8">
            <label className="ff ff_md f16 pb-1">{'Members'}</label>
            {user.role === ROLE.ADMIN ? (
              <CreatableSelect
                className="ff"
                value={employeeDropdown}
                placeholder="Select Members"
                options={membersArrayOptions}
                onChange={setEmployeeDropdown}
                filterOption={filterMembersOption}
              />
            ) : (
              <Select
                className="ff"
                value={employeeDropdown}
                placeholder="Select Members"
                options={membersArrayOptions}
                onChange={setEmployeeDropdown}
                filterOption={filterMembersOption}
              />
            )}
          </div>
          <div className="col-md-3 col-4">
            <CustomDropDown
              label="Access"
              nameOfClass="w-100"
              type={accessType}
              defaultValue={'Edit'}
              setAccessType={(value) => handleChange(value)}
              accessType={accessType}
              nameOfClass3="w-100"
            />
          </div>
          <div className="col-md-3">
            <button
              className="btn btn-primary w-100"
              onClick={() => {
                let nameToAdd;
                let emailToAdd;
                let idToAdd = '';
                let permission = accessType.toLowerCase();
                if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(employeeDropdown.label)) {
                  const parts = employeeDropdown.label.split('@');
                  nameToAdd = parts[0];
                  emailToAdd = employeeDropdown.label;
                  setNewMembersToAdd((prevState) => {
                    return [
                      ...prevState,
                      {
                        email: emailToAdd,
                        access: permission,
                        role: ROLE.EMPLOYEE,
                      },
                    ];
                  });
                } else {
                  const item = employees.find((member) => {
                    return member.name === employeeDropdown.label;
                  });
                  if (!item) {
                    return;
                  }
                  nameToAdd = item.name;
                  idToAdd = item._id;
                  emailToAdd = item.email;

                  setExistingMembersToAdd((prevState) => {
                    return [...prevState, { member: idToAdd, access: permission }];
                  });
                }
                setStagedMembers((prevState) => {
                  return [
                    ...prevState,
                    {
                      id: idToAdd,
                      name: nameToAdd,
                      email: emailToAdd,
                      type: permission,
                    },
                  ];
                });
                setFreshMembersToAdd((prevState) => {
                  return [...prevState, { member: idToAdd }];
                });
                setEmployeeDropdown('');
              }}
            >
              Add
            </button>
          </div>
        </div>
        {Object.keys(stagedMembers).length > 0 &&
          stagedMembers.map((data) => {
            return (
              <EnteredDataRowProject
                email={data.email}
                name={data.name}
                access={data.type}
                deleteEntry={() => {
                  deleteMemberHandler(data.id, data.email);
                }}
              />
            );
          })}
        {user.role === ROLE.ADMIN && (
          <>
            <div className="row align-items-end gx-2 gy-2 mb-2 mt-2 pt-2 border-top">
              <div className="col-md-6 col-8">
                <label className="ff ff_md f16 pb-1">{'Client'}</label>
                <CreatableSelect
                  className="ff"
                  value={clientDropdown}
                  placeholder="Select Client"
                  options={clientsArrayOptions}
                  onChange={setClientDropdown}
                  filterOption={filterClientsOption}
                />
              </div>
              <div class="col-md-3 col-4">
                <CustomDropDown
                  label="Access"
                  nameOfClass="w-100"
                  type={clientAccessType}
                  defaultValue={'Edit'}
                  setAccessType={(value) => handleClientAccessChange(value)}
                  accessType={clientAccessType}
                  nameOfClass3="w-100"
                />
              </div>
              <div class="col-md-3">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    let nameToAdd;
                    let emailToAdd;
                    let idToAdd = '';
                    let permission = clientAccessType.toLowerCase();
                    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(clientDropdown.label)) {
                      const parts = clientDropdown.label.split('@');
                      nameToAdd = parts[0];
                      emailToAdd = clientDropdown.label;
                      setNewClientsToAdd((prevState) => {
                        return [
                          ...prevState,
                          {
                            email: emailToAdd,
                            access: permission,
                            role: ROLE.CLIENT,
                          },
                        ];
                      });
                    } else {
                      const item = clients.find((member) => {
                        return member.name === clientDropdown.label;
                      });
                      if (!item) {
                        return;
                      }
                      nameToAdd = item.name;
                      idToAdd = item._id;
                      emailToAdd = item.email;

                      setExistingClientsToAdd((prevState) => {
                        return [...prevState, { member: idToAdd, access: permission }];
                      });
                    }
                    setFreshClientsToAdd((prevState) => {
                      return [...prevState, { member: idToAdd }];
                    });
                    setStagedClients((prevState) => {
                      return [
                        ...prevState,
                        {
                          id: idToAdd,
                          name: nameToAdd,
                          email: emailToAdd,
                          type: permission,
                        },
                      ];
                    });
                    setClientDropdown('');
                  }}
                >
                  Add
                </button>
              </div>
            </div>
            {Object.keys(stagedClients).length > 0 &&
              stagedClients.map((data) => {
                return (
                  <EnteredDataRowProject
                    email={data.email}
                    name={data.name}
                    access={data.type}
                    deleteEntry={() => {
                      deleteClientHandler(data.id, data.email);
                    }}
                  />
                );
              })}

            <div className="row align-items-end gx-2 gy-2 mb-2 mt-2 pt-2 border-top">
              <div className="col-md-6 col-8">
                <label className="ff ff_md f16 pb-1 z-1">{'Project Manager'}</label>
                <CreatableSelect
                  className="ff"
                  placeholder="Select PM"
                  value={pmDropdown}
                  options={pmArrayOptions}
                  onChange={setPMDropdown}
                  filterOption={filterPMsOption}
                />
              </div>
              <div class="col-md-3 col-4">
                <CustomDropDown
                  label="Access"
                  nameOfClass="w-100"
                  type={pmAccessType}
                  defaultValue={'Edit'}
                  setAccessType={(value) => handlePMAccessChange(value)}
                  accessType={pmAccessType}
                  nameOfClass3="w-100"
                />
              </div>
              <div class="col-md-3">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    let nameToAdd;
                    let emailToAdd;
                    let idToAdd = '';
                    let permission = pmAccessType.toLowerCase();
                    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(pmDropdown.label)) {
                      const parts = pmDropdown.label.split('@');
                      nameToAdd = parts[0];
                      emailToAdd = pmDropdown.label;
                      setNewPMsToAdd((prevState) => {
                        return [
                          ...prevState,
                          {
                            email: emailToAdd,
                            access: permission,
                            role: ROLE.PROJECTMANAGER,
                          },
                        ];
                      });
                    } else {
                      const item = projectManagers.find((member) => {
                        return member.name === pmDropdown.label;
                      });
                      if (!item) {
                        return;
                      }
                      nameToAdd = item.name;
                      idToAdd = item._id;
                      emailToAdd = item.email;

                      setExistingPMsToAdd((prevState) => {
                        return [
                          ...prevState,
                          {
                            member: idToAdd,
                            access: permission,
                          },
                        ];
                      });
                    }
                    setFreshPMsToAdd((prevState) => {
                      return [...prevState, { member: idToAdd }];
                    });
                    setStagedPMs((prevState) => {
                      return [
                        ...prevState,
                        {
                          id: idToAdd,
                          name: nameToAdd,
                          email: emailToAdd,
                          type: permission,
                        },
                      ];
                    });
                    setPMDropdown('');
                  }}
                >
                  Add
                </button>
              </div>
            </div>
            {Object.keys(stagedPMs).length > 0 &&
              stagedPMs.map((data) => {
                return (
                  <EnteredDataRowProject
                    email={data.email}
                    name={data.name}
                    access={data.type}
                    deleteEntry={() => {
                      deletePMHandler(data.id, data.email);
                    }}
                  />
                );
              })}
          </>
        )}

        <div className="d-flex justify-content-center sticky-bottom bg-ffffff py-3">
          <button
            className="ff btn btn-primary btn-lg px-5"
            onClick={() => {
              createHandler();
            }}
          >
            {addProjectLoading ? <BtnLoader /> : isEditFlow ? 'Save' : 'Create'}
          </button>
        </div>
      </div>
    </Modal>
  );
});

//Add New Task Modal
export const AddTaskModal = React.memo(({ modalOpen, projectDetails: passedProjectDetails, passedListId }) => {
  const inputRef = useRef(null);
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  let { projects } = useSelector((state) => state.dashboard);
  let { members } = useSelector((state) => state.addTask);
  let { lists } = useSelector((state) => state.addTask);
  let { status } = useSelector((state) => state.status);
  const { addTaskLoading } = useSelector((state) => state.loading);
  const [projectOptionsArray, setProjectOptionsArray] = useState([]);
  const [listOptionsArray, setListOptionsArray] = useState([]);
  const [membersOptionsArray, setMembersOptionsArray] = useState([]);
  const [dropdownName, setDropdownName] = useState('');
  const [stagedMembers, setStagedMembers] = useState([]);
  const [isNewList, setIsNewList] = useState(false);

  const [projectName, setProjectName] = useState('');
  const [projectId, setProjectId] = useState('');
  const [projectData, setProjectData] = useState('');

  const [taskList, setTaskList] = useState('');
  const [taskListId, setTaskListId] = useState('');

  const [taskTitle, setTaskTitle] = useState('');

  const dispatch = useDispatch();

  const { projectDetails } = useSelector((state) => state.addTask);

  useEffect(() => {
    // if (!projectDetails) {
    //   return;
    // }
    if (Object.keys(projectDetails).length > 0) {
      return;
    }
    const projectSlugToFetch = Object.keys(projectDetails).length > 0 ? projectDetails.slug : projectData.slug;
    const projectIdToFetch = Object.keys(projectDetails).length > 0 ? projectDetails._id : projectData._id;
    if (projectIdToFetch) {
      dispatch(fetchProjectMembers(projectSlugToFetch));
      dispatch(fetchProjectLists(projectSlugToFetch));
    }
  }, [projectDetails, projectId]);

  useEffect(() => {
    dispatch({ type: 'SET_TASK_ADD_FLAG', payload: false });
  }, []);

  useEffect(() => {
    const updatedOptions = projects.map((project) => ({
      value: project._id,
      label: project.title,
    }));
    setProjectOptionsArray(updatedOptions);
  }, [projects]);

  useEffect(() => {
    const updatedOptions = lists.map((list) => ({
      value: list._id,
      label: list.title,
    }));
    setListOptionsArray(updatedOptions);
  }, [lists]);

  useEffect(() => {
    const updatedOptions = members.map((member) => ({
      value: member._id,
      label: member.name,
    }));
    setMembersOptionsArray(updatedOptions);
  }, [members]);

  const cancelSuperHandler = (e) => {
    e.preventDefault();
    clearHandler();
    dispatch({ type: 'SANITIZE_ADD_TASK_STORE' });
    dispatch({ type: 'IS_STEP_ACTIVE', payload: false });
    dispatch({ type: 'CHANGE_STEP', payload: 0 });
  };

  const clearHandler = () => {
    setProjectName('');
    setTaskList('');
    setTaskTitle('');
  };

  const addHandler = (isSave) => {
    if (
      !taskTitle ||
      (!(Object.keys(projectDetails).length > 0) && !projectId) ||
      (!passedListId && !taskList && !taskListId)
    ) {
      return;
    }
    const dataToSend = {
      title: taskTitle,
      assignee: stagedMembers.map((member) => member.id),
      status: [status[0]?._id],
    };

    if (Object.keys(projectDetails).length > 0) {
      dataToSend.project = projectDetails._id;
    } else {
      dataToSend.project = projectId;
    }

    if (passedListId) {
      dataToSend.list = passedListId;
    } else {
      if (isNewList) {
        dataToSend.newlist = taskList;
      } else {
        dataToSend.list = taskListId;
      }
    }
    dispatch(
      AddTaskAPI(
        dataToSend,
        Object.keys(projectDetails).length > 0 ? projectDetails._id : projectId,
        Object.keys(projectDetails).length > 0 ? projectDetails.title : projectName,
        Object.keys(projectDetails).length > 0 ? projectDetails.slug : projectData?.slug,
        navigate,
        isSave
      )
    );
    // dispatch({ type: "IS_ADD_TASK_STEP_ACTIVE", payload: true });
    // dispatch({
    //   type: "CHANGE_STEP_PAYLOAD",
    //   payload: {
    //     step: 2,
    //     projectDetails: projectDetails
    //       ? projectDetails
    //       : { _id: projectId, title: projectName },
    //   },
    // });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const handleProjectSelectChange = (option) => {
    setProjectName(option.label);
    setProjectId(option.value);
    const project = projects.find((project) => project._id === option.value);
    if (project) {
      setProjectData(project);
    }
  };

  // useEffect(() => {
  //   console.log("Project Data:", projectData);
  // }, [projectData]);

  const handleCategorySelectChange = (option) => {
    if (option.__isNew__) {
      setIsNewList(true);
    } else {
      setIsNewList(false);
    }
    setTaskList(option.label);
    setTaskListId(option.value);
  };

  const handleMembersSelectChange = (option) => {
    // setDropdownName(option.label);
    const selectedMember = members.find((member) => {
      return member.name === option.label;
    });
    if (!selectedMember) {
      return;
    }
    setStagedMembers((prevState) => [...prevState, { id: selectedMember._id, name: selectedMember.name }]);
  };

  const filterMembersOption = (option, inputValue) => {
    // Check if the option is already selected
    const isSelected = stagedMembers.some((selected) => selected.id === option.value);
    return !isSelected && option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const deleteAddedMember = (id) => {
    setStagedMembers((prevState) => {
      const members = prevState.filter((member) => member.id !== id);
      return members;
    });
  };

  useEffect(() => {
    if (modalOpen) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    }
  }, [modalOpen]);
  return (
    <>
      <Modal
        open={modalOpen}
        onCancel={cancelSuperHandler}
        maskClosable={false}
        closable={true}
        centered={true}
        closeIcon={<CloseIcon />}
        width={'31.25rem'}
        footer={null}
        className=""
      >
        <h3 className="text-center mt-3 mb-3 txt-primary ff ff_bd f24 responsiveModalLabel mb-1">{'Add Task'}</h3>
        <div className="d-flex flex-column justify-content-center">
          {!(Object.keys(projectDetails).length > 0) && (
            <div className="mb-3">
              <label className="ff ff_md f16 pb-1">{'Project Name'}</label>
              <Select
                ref={inputRef}
                placeholder="Select Project"
                options={projectOptionsArray}
                onChange={handleProjectSelectChange}
              />
            </div>
          )}
          {!passedListId && (
            <div className="mb-3">
              <label className="ff ff_md f16 pb-1">{'Task List'}</label>
              <CreatableSelect
                placeholder="Select Task List"
                options={listOptionsArray}
                onChange={handleCategorySelectChange}
              />
            </div>
          )}

          <AdminInputBoxLarge
            label={'Task Title'}
            taskTitle={taskTitle}
            setTaskTitle={setTaskTitle}
            nameOfClass={`mb-3 adminLargeInput`}
            placeHolderText={'Task Title'}
          />
          {user.role !== ROLE.CLIENT && (
            <div className="row align-items-end gx-2 gy-2 mb-2">
              <div className="col-md-12">
                <label className="ff ff_md f16 pb-1">{'Member'}</label>
                <div style={{ width: '100%' }}>
                  <Select
                    placeholder="Select Member"
                    options={membersOptionsArray}
                    onChange={handleMembersSelectChange}
                    filterOption={filterMembersOption}
                    value={''}
                  />
                </div>
              </div>
              {/* <div className="col-md-3">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    console.log("Add", dropdownName);
                    const selectedMember = members.find((member) => {
                      return member.name === dropdownName;
                    });
                    if (!selectedMember) {
                      return;
                    }
                    console.log(selectedMember);
                    setStagedMembers((prevState) => [
                      ...prevState,
                      { id: selectedMember._id, name: selectedMember.name },
                    ]);
                  }}
                >
                  Add
                </button>
              </div> */}
            </div>
          )}

          {stagedMembers &&
            stagedMembers.map((data) => {
              return (
                <EnteredDataRowTask
                  // email={data.email}
                  name={data.name}
                  deleteEntry={() => deleteAddedMember(data.id)}
                  // access={data.type}
                />
              );
            })}
          <div className="d-flex justify-content-evenly sticky-bottom bg-ffffff py-3">
            <button
              className="btn btn-primary btn-lg px-5"
              onClick={() => {
                if (addTaskLoading) {
                  return;
                }
                addHandler(true);
              }}
            >
              {addTaskLoading ? <BtnLoader /> : 'Save'}
            </button>
            <button
              className="btn btn-primary btn-lg px-5"
              onClick={() => {
                if (addTaskLoading) {
                  return;
                }
                addHandler(false);
              }}
            >
              {addTaskLoading ? <BtnLoader /> : 'Next'}
            </button>
          </div>
        </div>
      </Modal>
      <AddTaskDetailModal />
    </>
  );
});

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

const DeleteConfirmationModal = ({ taskName, modalVisible, setModalVisible, onConfirm }) => {
  const handleDelete = () => {
    if (onConfirm) {
      onConfirm();
    }
    setModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Delete the task"
        open={modalVisible}
        centered
        onCancel={() => setModalVisible(false)}
        footer={[
          <button key="cancel" className="ff f16 c-white" onClick={() => setModalVisible(false)}>
            Cancel
          </button>,
          <button key="delete" className="ff f16 c-white txt-error" onClick={handleDelete}>
            Remove
          </button>,
        ]}
      >
        <p>Aye you sure you want to delete task {<span style={{ fontWeight: 'bold' }}>{taskName}</span>}?</p>
      </Modal>
    </>
  );
};

const ArchiveConfirmationModal = ({ taskName, modalVisible, setModalVisible, onConfirm }) => {
  const handleArchive = () => {
    if (onConfirm) {
      onConfirm();
    }
    setModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Archive the task"
        open={modalVisible}
        centered
        onCancel={() => setModalVisible(false)}
        footer={[
          <button key="cancel" className="ff f16 c-white" onClick={() => setModalVisible(false)}>
            Cancel
          </button>,
          <button key="delete" className="ff f16 c-white txt-yellow" onClick={handleArchive}>
            Archive
          </button>,
        ]}
      >
        <p>Aye you sure you want to archive task {<span style={{ fontWeight: 'bold' }}>{taskName}</span>}?</p>
      </Modal>
    </>
  );
};

//Preview Component
// const AttachmentPreviewModal = () => {
//   const dispatch = useDispatch();
//   const { isVisible, fileExtension, fileUrl } = useSelector(
//     (state) => state.preview
//   );
//   return (
//     <>
//       <Modal
//         open={isVisible}
//         footer={null}
//         onCancel={() => {
//           dispatch({ type: "CLOSE_FILE_PREVIEW" });
//         }}
//         width="80%"
//         style={{ top: 20 }}
//       >
//         <FileViewer fileType={fileExtension} filePath={fileUrl} />
//       </Modal>
//     </>
//   );
// };

const TaskDetailLoader = () => {
  return (
    <>
      <div>
        <div>
          <Skeleton width="100%" height={50} />
          <hr className="antModalDivider" />
          <div className="row gy-2 align-items-baseline">
            <Skeleton width="100%" height={70} />
          </div>
          <hr className="antModalDivider" />
          <div className="row gx-sm-4">
            <div className="col-md-9">
              <Skeleton width="100%" height={350} />
            </div>
            <div className="col-sm-3">
              <div className="p-2">
                <Skeleton width="100%" height={338} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

//NewTaskDetail Modal
export const AddTaskDetailModal = React.memo(({ modalOpen, taskTitle }) => {
  const linkifyOptions = { defaultProtocol: 'https' };
  const user = JSON.parse(localStorage.getItem('user'));
  const { fetchTaskForUpdateLoading } = useSelector((state) => state.loading);
  const { isUpdated } = useSelector((state) => state.addTask);
  let { task } = useSelector((state) => state.addTask);
  const location = useLocation();
  const { projectDetails, comments } = useSelector((state) => state.addTask);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isAddMember, setIsAddMember] = useState(false);
  const [comment, setComment] = useState('');
  const [editorData, setEditorData] = useState('');

  const [deleteCommentId, setDeleteCommentId] = useState(false);
  const [commentToEdit, setCommentToEdit] = useState();
  const [editmentions, setEditMentions] = useState([]);

  const [moveTaskDetails, setMoveTaskDetails] = useState(null);

  const [saveDescLoading, setSaveDescLoading] = useState(false);

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [taskDesc, setTaskDesc] = useState('');

  const [isCheckListModal, setIsCheckListModal] = useState(false);
  const [isAttachment, setIsAttachment] = useState(false);
  const [isLabelPopup, setIsLabelPopup] = useState(false);
  const [startDateTimeRangePicker, setStartDateTimeRangePicker] = useState(false);
  const [isDueDateTimeRangePicker, setIsDueDateTimeRangePicker] = useState(false);
  const [isStartCalenderOpen, setIsStartCalenderOpen] = useState(false);
  const [isDueCalenderOpen, setIsDueCalenderOpen] = useState(false);
  const [checkListInput, setCheckListInput] = useState('');
  const [isCheckList, setIsCheckList] = useState(false);
  const [checkListName, setCheckListName] = useState('');
  const [checkListItems, setCheckListItems] = useState([]);
  const [checkListProgress, setCheckListProgress] = useState(0);
  const [isAttachmentCreated, setIsAttachmentCreated] = useState(false);
  const [decryptedTaskTitle, setDecryptedTaskTitle] = useState('');
  const dispatch = useDispatch();
  const socket = useSocket();

  //Get edited values(status, members) from store after performing updates by various modals
  //Get taskId from store to fetch a particular task whose details are to be shown in details modal
  let { selectedStatus, selectedMembers, taskId, attachments, isEditFlow, access } = useSelector(
    (state) => state.addTask
  );

  const { viewType } = useSelector((state) => state.viewType);

  const memorisedTaskId = useMemo(() => taskId, [taskId]);

  useEffect(() => {
    dispatch({ type: 'SET_UPDATE_FLAG', payload: false });
    dispatch({ type: 'SET_CHECKLIST_ADD_FLAG', payload: false });
  }, []);

  const saveHandler = () => {
    if (checkLists && checkLists.length) {
      const requestData = formatChecklistsForUpdate(checkLists);
      dispatch(updateCheckListAPI(requestData, taskId));
    }
    const dataToSend = {
      title: decryptedTaskTitle,
      scheduledDate: formatDateForDispatch(startDate),
      endDate: formatDateForDispatch(dueDate),
      status: selectedStatus.map((status) => status._id),
      member: selectedMembers.map((member) => member._id),
      attachments: attachments,
      ...(editorData !== '' && { description: editorData }), // Include description only if editorData is not an empty string
    };

    // console.log("Data To Send", dataToSend);
    dispatch(updateTaskAPI(dataToSend, task?._id, projectDetails._id));
    // dispatch({ type: "IS_STEP_ACTIVE", payload: false });
    // dispatch({ type: "CHANGE_STEP", payload: 0 });
  };

  useEffect(() => {
    if (task._id) {
      socket.on(`taskUpdate-${task._id}`, (taskData) => {
        dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
        dispatch({
          type: 'FETCH_TASK_DETAILS_FOR_TASK_DETAILS_MODAL',
          payload: taskData,
        });
      });

      socket.on(`taskUpdateChecklist-${task._id}`, (checklistData) => {
        dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
        dispatch({
          type: 'ADD_CHECKLIST_DATA',
          payload: checklistData,
        });
      });

      socket.on(`taskUpdateChecklistItem-${task._id}`, (checklistItemData) => {
        dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
        dispatch({
          type: 'ADD_CHECKLIST_ITEM_DATA',
          payload: checklistItemData,
        });
      });

      socket.on(`toggleChecklistItem-${task._id}`, (payload) => {
        dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
        dispatch({
          type: 'TOGGLE_CHECKLIST_ITEM',
          payload: payload,
        });
      });

      socket.on(`deleteChecklist-${task._id}`, (payload) => {
        dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
        dispatch(payload);
      });

      socket.on(`taskUpdates-${task._id}`, (payload) => {
        dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
        dispatch(payload);
      });
    }
  }, [dispatch, socket, task._id]);

  useEffect(() => {
    if (taskId) {
      dispatch(fetchTaskDetails(taskId, navigate));
    }
  }, []);

  useEffect(() => {
    // console.log('API Status: ', modalOpen);
    // if (Object.keys(projectDetails).length !== 0 || modalOpen !== true) {
    //   return;
    // }

    dispatch(fetchProjectMembers(projectDetails.slug));
    dispatch(fetchStatusApiForProject(projectDetails.slug));
  }, [dispatch, modalOpen, projectDetails]);

  // useEffect(() => {
  //   if (projectDetails) {
  //     dispatch(fetchStatusApiForProject(projectDetails._id));
  //   }
  // }, [projectDetails]);

  useEffect(() => {
    (async () => {
      try {
        if (task?.title) {
          const decryptedText = await decrypt(task.title);
          setDecryptedTaskTitle(decryptedText);
        }
      } catch (error) {
        console.error('Error decrypting task title:', error);
        // Handle error if necessary
      }
    })();
  }, [task?.title]);

  useEffect(() => {
    (async () => {
      try {
        if (task?.description) {
          const decryptedText = await decrypt(task?.description);
          setEditorData(decryptedText);
          // setIsEditorOpen(false);
          // setTaskDesc(convertToLinks(decryptedText));
          setTaskDesc(decryptedText);
        } else {
          setEditorData('');
          // setIsEditorOpen(false);
          setTaskDesc('');
        }
      } catch (error) {
        console.error('Error decrypting task description:', error);
        // Handle error if necessary
      }
    })();
  }, [task?.description]);

  function formatChecklistsForUpdate(checkLists) {
    return {
      editChecklist: checkLists.map((checklist) => ({
        id: checklist._id,
        items: checklist.items.map((item) => ({
          id: item._id,
          title: item.name,
          isChecked: item.isChecked,
        })),
      })),
    };
  }

  let { checkLists } = useSelector((state) => state.addTask);
  const navigate = useNavigate();

  const isToday = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);

    const inputDate = new Date(year, month - 1, day);

    const today = new Date();

    return (
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()
    );
  };
  const cancelSuperHandler = () => {
    // if (checkLists && checkLists.length) {
    //   const requestData = formatChecklistsForUpdate(checkLists);
    //   dispatch(updateCheckListAPI(requestData, taskId));
    // }

    //Get latest tasks on cancel click(delete checklist -> get latest tasks)

    //Uncomment below lines
    // console.log("URL:", location.pathname.split("/"));

    // dispatch(fetchTaskForProjectViewByStatus(projectDetails._id));
    // dispatch(fetchAllTasksForProjectViewByListAPI(projectDetails._id));
    // dispatch(fetchAllTasksForAdminDashboardByStatusAPI());
    const urlParts = location.pathname.split('/');
    if (urlParts[1] === 'p') {
      if (viewType === 'status') {
        dispatch({ type: 'UNSTAGE_TASK' });
      }
      if (isUpdated) {
        // console.log('View Type: ', viewType);
        dispatch(fetchUpdatedTask(task._id, viewType, socket, projectDetails._id));
      }
      navigate(`/projects/${urlParts[2]}`, {
        state: { doNotRefetch: true },
      });
    } else if (urlParts[1] === 'dashboard') {
      if (!isEditFlow) {
        if (!isToday(startDate)) navigate(`/projects/${projectDetails?.slug}`);
      }
    }
    dispatch({ type: 'SANITIZE_ADD_TASK_STORE' });
    dispatch({ type: 'IS_STEP_ACTIVE', payload: false });
    dispatch({ type: 'CHANGE_STEP', payload: 0 });
  };

  const openAddMemberModal = () => {
    if (access === ACCESS.VIEW) {
      return;
    }
    setIsAddMember(true);
  };

  function formatDateToLocal(isoDateString) {
    // Create a Date object from the ISO string
    const date = new Date(isoDateString);

    // Format the date in local time zone
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  }

  function formatDateForDispatch(inputDate) {
    const parts = inputDate.split('/');

    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    const formattedDate = new Date(year, month - 1, day);

    const formattedYear = formattedDate.getFullYear();
    const formattedMonth = (formattedDate.getMonth() + 1).toString().padStart(2, '0'); // Month needs to be zero-padded
    const formattedDay = formattedDate.getDate().toString().padStart(2, '0'); // Day needs to be zero-padded

    // Construct the final formatted date string
    const formattedDateString = `${formattedYear}-${formattedMonth}-${formattedDay}`;

    return formattedDateString;
  }

  const now = new Date();

  const [startDate, setStartDate] = useState(`${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`);

  function isDateGreater(providedDateStr) {
    function parseDate(dateStr) {
      const [day, month, year] = dateStr.split('/').map(Number);
      return new Date(year, month - 1, day);
    }

    const providedDate = parseDate(providedDateStr);
    const setDate = parseDate(startDate);

    return providedDate >= setDate;
  }

  const handleStartDateChangeTomorrow = () => {
    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);
    const tomorrowDate = `${tomorrow.getDate()}/${tomorrow.getMonth() + 1}/${tomorrow.getFullYear()}`;
    const dataToSend = {
      scheduledDate: formatDateForDispatch(tomorrowDate),
    };
    dispatch(updateTaskAPI(dataToSend, task?._id, projectDetails._id, socket));
    setStartDate(tomorrowDate);
  };

  const handleDueDateChangeTomorrow = () => {
    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);
    const tomorrowDate = `${tomorrow.getDate()}/${tomorrow.getMonth() + 1}/${tomorrow.getFullYear()}`;
    const dataToSend = {
      endDate: formatDateForDispatch(tomorrowDate),
    };
    dispatch(updateTaskAPI(dataToSend, task?._id, projectDetails._id, socket));
    setDueDate(tomorrowDate);
  };

  const [dueDate, setDueDate] = useState(`${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`);

  useEffect(() => {
    if (task?.scheduledDate) {
      setStartDate(formatIncomingDate(task?.scheduledDate));
    } else {
      setStartDate(`${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`);
    }

    if (task?.endDate) {
      setDueDate(formatIncomingDate(task?.endDate));
    } else {
      setDueDate(`${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`);
    }
  }, [task?.endDate, task?.scheduledDate]);

  function formatIncomingDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleDueDateChange = (e) => {
    setDueDate(e.target.value);
  };

  const openStartDatePicker = () => {
    if (access === ACCESS.VIEW) {
      return;
    }
    setStartDateTimeRangePicker(true);
    setIsStartCalenderOpen(true);
  };

  const openDueDatePicker = () => {
    if (access === ACCESS.VIEW) {
      return;
    }
    setIsDueDateTimeRangePicker(true);
    setIsDueCalenderOpen(true);
  };

  const addHandler = () => {};

  const deleteHandler = () => {
    setIsCheckList(false);
    setCheckListInput('');
    setCheckListItems([]);
    setCheckListName('');
    setCheckListProgress(0);
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  useEffect(() => {
    const completedCount = checkListItems.filter((item) => item.isChecked).length;
    const totalCount = checkListItems.length;
    const percentage = totalCount === 0 ? 0 : (completedCount / totalCount) * 100;
    setCheckListProgress(Math.ceil(percentage));
  }, [checkListItems]);

  // function debounce(func, delay) {
  //   let timeoutId;
  //   return function (...args) {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       func.apply(this, args);
  //     }, delay);
  //   };
  // }

  // const saveTitle = (title) => {
  //   const dataToSend = {
  //     description: title,
  //   };
  //   dispatch(updateTaskAPI(dataToSend, taskId));
  // };

  const saveTitle = useCallback(
    (title) => {
      const dataToSend = {
        title: title,
      };
      dispatch(updateTaskAPI(dataToSend, task?._id, '', socket));
    },
    [task?._id]
  );

  // const saveTitleDelayed = debounce(saveTitle, 2000);

  const saveTitleDelayed = useCallback(debounce(saveTitle, 1000), [saveTitle]);

  const handleTaskTitleChange = (e) => {
    saveTitleDelayed(e.target.value);
    setDecryptedTaskTitle(e.target.value);
  };

  const toggleEditor = () => {
    if (access === ACCESS.VIEW) {
      return;
    }
    setIsEditorOpen((prevState) => !prevState);
  };

  const [files, setFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [allAttachments, setAllAttachments] = useState([]);

  useEffect(() => {
    setAllAttachments(attachments.map((attachment) => ({ url: attachment, progress: 101 })));
  }, [attachments]);

  function transformIncomingLink(originalLink) {
    var transformedLink = originalLink.replace('/3rd-task-dev', '/98615ef974e04ff8ae1fbb8a895b72f0:3rd-task-dev');

    return transformedLink;
  }

  const s3 = new AWS.S3({
    accessKeyId: '29f47bfdd00e24edb6aeabc87b53d5b2',
    secretAccessKey: 'f42d1fa109528f9dcbf3c430818f62e6',
    endpoint: 'https://sin1.contabostorage.com/',
    s3ForcePathStyle: true,
  });

  const createFileUrl = (fileName) => {
    return 'https://sin1.contabostorage.com/98615ef974e04ff8ae1fbb8a895b72f0:3rd-task-dev/' + fileName;
  };

  const scrollToAttachment = () => {
    setTimeout(function () {
      scroller.scrollTo('attachment', {
        containerId: 'modal',
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }, 3000);
    // scroller.scrollTo("attachment", {
    //   containerId: "modal",
    //   duration: 800,
    //   delay: 0,
    //   smooth: "easeInOutQuart",
    // });
  };

  const attachmentRef = useRef(null);

  const scrollToRef = () => {
    setTimeout(() => {
      attachmentRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'start',
      });
    }, 1000);
  };

  const uploadToS3 = useCallback(
    async (acceptedFiles) => {
      dispatch({ type: 'SET_UPDATE_FLAG', payload: true });
      scrollToRef();
      try {
        const uploadPromises = acceptedFiles.map((file) => {
          const uniqueFileName = `${uuidv4()}_${file.name}`;
          const fileUrl = createFileUrl(uniqueFileName);
          const params = {
            Bucket: '3rd-task-dev',
            Key: uniqueFileName,
            Body: file,
          };

          return new Promise((resolve, reject) => {
            s3.upload(params)
              .on('httpUploadProgress', (progressEvent) => {
                const progressPercent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setAllAttachments((prevState) => {
                  const index = prevState.findIndex((entry) => entry.url === fileUrl);

                  if (index !== -1) {
                    const newAttachments = [...prevState];
                    newAttachments[index] = {
                      url: fileUrl,
                      progress: progressPercent,
                    };
                    return newAttachments;
                  } else {
                    return [...prevState, { url: fileUrl, progress: progressPercent }];
                  }
                });
              })
              .send((err, data) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(data);
                  setAllAttachments((prevState) => {
                    const index = prevState.findIndex((entry) => entry.url === fileUrl);

                    if (index !== -1) {
                      const newAttachments = [...prevState];
                      newAttachments[index] = {
                        url: fileUrl,
                        progress: 101,
                      };
                      return newAttachments;
                    } else {
                      return [...prevState, { url: fileUrl, progress: 0 }];
                    }
                  });
                }
              });
          });
        });
        const uploadedFiles = await Promise.all(uploadPromises);
        dispatch({
          type: 'UPLOAD_ATTACHMENT_SUCCESS',
          payload: uploadedFiles.map((file) => transformIncomingLink(file.Location)),
        });
        const dataToSend = {
          attachments: {
            add: uploadedFiles.map((file) => transformIncomingLink(file.Location)),
          },
        };
        dispatch(addAttachmentToTaskAPI(dataToSend, task._id, socket));
        message.success('Files uploaded');
      } catch (error) {
        message.error(error);
      }
    },
    [dispatch, s3, task._id]
  );

  useEffect(() => {
    if (task._id) {
      const handlePaste = (event) => {
        const clipboardItems = event.clipboardData.items;
        const files = [];
        for (const item of clipboardItems) {
          if (item.kind === 'file') {
            const file = item.getAsFile();
            files.push(file);
          }
        }
        if (files.length > 0) {
          uploadToS3(files);
        }
      };

      const handleKeyDown = (event) => {
        if (event.ctrlKey && event.key === 'v') {
          document.addEventListener('paste', handlePaste);
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('paste', handlePaste);
      };
    }
  }, [task._id, uploadToS3]);

  const onDrop = async (acceptedFiles) => {
    // scrollToRef();
    setIsDragging(false);
    setFiles(
      acceptedFiles.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file),
      }))
    );
    uploadToS3(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
  });

  const { onClick, ...rootProps } = getRootProps();

  // useEffect(() => {
  //   console.log("IsDragging", isDragging);
  // }, [isDragging]);

  const [isHovered, setIsHovered] = useState(false);
  const defaultStyles = {
    backgroundColor: '#e6e6e6',
    cursor: 'pointer',
    padding: '8px 16px',
    borderRadius: '4px',
  };

  const hoverStyles = {
    backgroundColor: '#f2f2f2',
  };

  const combinedStyles = isHovered ? { ...defaultStyles, ...hoverStyles } : defaultStyles;

  function removeEncodedATags(encodedHtml) {
    let decodedHtml = encodedHtml.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

    decodedHtml = decodedHtml.replace(/<a[^>]*href="([^"]*)"[^>]*>.*?<\/a>/g, '$1');

    return decodedHtml;
  }

  const saveDesc = () => {
    const dataToSend = {
      description: removeEncodedATags(editorData),
    };
    // console.log('TData: ', removeEncodedATags(editorData), '----------', editorData);
    setSaveDescLoading(true);
    dispatch(
      updateTaskDescAPI(dataToSend, task?._id, socket, () => {
        setTaskDesc(removeEncodedATags(editorData));
        setTimeout(() => {
          setSaveDescLoading(false);
          setIsEditorOpen(false);
        }, 500);
      })
    );
  };

  const cancelDesc = () => {
    setEditorData(taskDesc);
    setIsEditorOpen(false);
  };

  const newChecklistRef = useRef(null);

  // useEffect(() => {
  //   console.log("Ref Useeffect");
  //   if (newChecklistRef.current) {
  //     console.log("Scrolling");
  //     newChecklistRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //   }
  // }, [task]);
  const { isChecklistAdded } = useSelector((state) => state.addTask);

  useEffect(() => {
    // console.log("Ref Useeffect");
    if (newChecklistRef.current) {
      if (isChecklistAdded) {
        newChecklistRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [checkLists]);

  const modalRef = useRef(null);
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const [mentions, setMentions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const { members } = useSelector((state) => state.addTask);
  const mentionsRef = useRef(null);
  const editMentionsRef = useRef(null);
  const options = members.map((user) => ({
    value: user.name.split(' ')[0],
    label: user.name,
    _id: user._id,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      position: 'absolute',
      zIndex: 9999,
      width: '300px',
      top: '100%',
      left: 0,
    }),
    option: (provided) => ({
      ...provided,
      padding: '5px 10px',
    }),
  };

  const CustomDropdownIndicator = () => null;
  const CustomIndicatorSeparator = () => null;

  // const handleCommentChange = (e) => {
  //   const value = e.target.value;
  //   let inputValue = e.target.value;

  //   if (inputValue.length > 0) {
  //     inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
  //   }

  //   setComment(inputValue);

  //   const lastChar = value.slice(-1);
  //   const atPosition = value.lastIndexOf('@');
  //   const cursorPosition = e.target.selectionStart;

  //   if (lastChar === '@' && cursorPosition === atPosition + 1) {
  //     setShowDropdown(true);
  //     // inputRef.current.blur(); // Defocus the input field
  //   } else {
  //     setShowDropdown(false);
  //   }

  //   // Check for removed mentions
  //   const newMentions = mentions.filter((mention) => value.includes(`@${mention.name}`));
  //   setMentions(newMentions);
  // };

  const handleCommentChange = (e) => {
    // console.log('Handle: ', e);

    setComment(e);
  };

  // const handleKeyPress = (e) => {
  //   if (e.key === 'Enter') {
  //     if (showDropdown) {
  //       e.preventDefault();
  //       handleSelectChange(options[0]);
  //     } else {
  //       const dataToSend = {
  //         comment,
  //         mention: mentions.map((mention) => mention.id),
  //       };
  //       // console.log(dataToSend);
  //       dispatch(addCommentAPI(dataToSend, task?._id, socket));
  //       setComment('');
  //       setMentions([]);
  //     }
  //   }
  // };

  const handleClickOutside = (e) => {
    // console.log('Clicked outside', e.target.id, editMentionsRef.current.textarea.id);
    if (editMentionsRef.current && e.target.id !== 'editComment') {
      console.log('Clicked outside');
      setCommentToEdit(null);
      setEditMentions([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when component unmounts or state changes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Check if the mention dropdown is open (aria-expanded="true")
      const mentionDropdown = document.querySelector('.ant-mentions-dropdown');
      if (mentionDropdown) {
        return;
      } else {
        // Proceed to save the comment if the dropdown is closed
        const dataToSend = {
          comment,
          mention: mentions.map((mention) => mention.id),
        };
        // console.log('Save');
        dispatch(addCommentAPI(dataToSend, task?._id, socket));
        setComment('');
        setMentions([]);
        if (mentionsRef.current) {
          mentionsRef.current.blur();
        }
      }
    }
  };

  const handleEditKeyPress = async (e) => {
    if (e.key === 'Enter') {
      // Check if the mention dropdown is open (aria-expanded="true")
      const mentionDropdown = document.querySelector('.ant-mentions-dropdown');
      if (mentionDropdown) {
        return;
      } else {
        // Proceed to save the comment if the dropdown is closed
        if (editMentionsRef.current) {
          editMentionsRef.current.blur();
        }
        const dataToSend = {
          taskId: task._id,
          commentId: commentToEdit._id,
          comment: commentToEdit.comment,
          mention: editmentions.map((mention) => mention.id),
        };

        const response = await dispatch(editCommentAPI(dataToSend));
        if (response.taskData) {
          dispatch({
            type: 'FETCH_TASK_DETAILS_FOR_TASK_DETAILS_MODAL',
            payload: response.taskData,
          });
          setCommentToEdit(null);
          setEditMentions([]);
        }
      }
    }
  };

  const handleSelectChange = (selectedOption) => {
    // console.log('Handle select change called: ', selectedOption);
    const mentionName = selectedOption.label.replace(/\s+/g, '');
    const mentionId = selectedOption._id;

    setMentions([...mentions, { id: mentionId, name: mentionName }]);
  };

  const handleEditSelectChange = (selectedOption) => {
    // console.log('Handle select change called: ', selectedOption);
    const mentionName = selectedOption.label.replace(/\s+/g, '');
    const mentionId = selectedOption._id;

    setEditMentions([...mentions, { id: mentionId, name: mentionName }]);
  };

  const renderComment = (text) => {
    const parts = text.split(/(@[\w-]+)/g);
    return parts.map((part, index) =>
      part.startsWith('@') ? (
        <span key={index} className="mention">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  useEffect(() => {
    if (showDropdown) {
      selectRef.current.focus();
    }
  }, [showDropdown]);
  const boldTextRef = useRef(null);
  const [textIndent, setTextIndent] = useState(0);

  useEffect(() => {
    if (boldTextRef.current) {
      // Calculate the width of the bold text
      const width = boldTextRef.current.offsetWidth;
      setTextIndent(width); // Add some extra space for padding
    }
  }, [task.number]);

  function convertUTCToLocalTimeForComments(utcDate) {
    const date = new Date(utcDate);
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    // Convert to local time
    let localTime = date.toLocaleString('en-GB', options);

    // Capitalize AM/PM
    localTime = localTime.replace(/(am|pm)/i, (match) => match.toUpperCase());

    // Add a single comma after the date and before the time
    localTime = localTime.replace(/(\d{2} [A-Za-z]{3} \d{4})(,?) (.*)/, '$1, $3');

    return localTime;
  }

  const handleLinkClick = (e) => {
    e.stopPropagation();
  };

  const deleteComment = async (id) => {
    const dataToSend = {
      taskId: task._id,
      commentId: id,
    };
    const response = await dispatch(deleteCommentAPI(dataToSend));
    if (response.task) {
      dispatch({
        type: 'REMOVE_COMMENT',
        payload: { commentId: id },
      });
    }
  };

  function getLinkifiedHtml(taskDesc) {
    try {
      // Attempt to linkify the HTML content
      return linkifyHtml(taskDesc, linkifyOptions);
    } catch (error) {
      console.error('Error in linkifyHtml:', error);
      // Return fallback content in case of an error
      return taskDesc; // This is the fallback content
    }
  }

  return (
    <>
      <Modal
        ref={modalRef}
        open={modalOpen}
        onCancel={cancelSuperHandler}
        maskClosable={false}
        closable={true}
        centered={true}
        closeIcon={
          <div className="addTaskCloseBtn">
            <CloseIcon />
          </div>
        }
        footer={null}
        width={'47.5rem'}
        className="taskDetailModel"
      >
        <div id="modal">
          <>
            {/* fetchTaskForUpdateLoading */}
            {fetchTaskForUpdateLoading ? (
              <>
                {/* <SiteLoader /> */}
                <TaskDetailLoader />
              </>
            ) : (
              <>
                {isDragging ? (
                  access === ACCESS.EDIT ? (
                    <div {...rootProps} className="dragging align-middle">
                      <p>Drop files here</p>
                    </div>
                  ) : (
                    <div className="dragging align-middle">
                      <p>Drag and drop is disabled</p>
                    </div>
                  )
                ) : (
                  <div {...(access === ACCESS.EDIT ? rootProps : {})}>
                    <Helmet>
                      <title>{task?.slug}</title>
                      <meta property="og:title" content={`${task?.slug}`} />
                    </Helmet>
                    <AttachmentPreviewModal />
                    <ArchiveConfirmationModal
                      modalVisible={isArchiveModalOpen}
                      setModalVisible={setIsArchiveModalOpen}
                      taskName={decryptedTaskTitle}
                      onConfirm={() => {
                        dispatch(archiveTaskAPI(task?._id, { isActive: false }, projectDetails.slug, navigate));
                      }}
                    />
                    <DeleteConfirmationModal
                      modalVisible={isDeleteModalOpen}
                      setModalVisible={setIsDeleteModalOpen}
                      taskName={decryptedTaskTitle}
                      onConfirm={() => {
                        dispatch(deleteTaskAPI(task, projectDetails.slug, navigate, viewType));
                      }}
                    />
                    <p className="ff ff_rg f16 txt-secondary mb-0" style={{ paddingRight: '1.75rem' }}>
                      {projectDetails.title}
                    </p>
                    <div style={{ width: '100%', paddingRight: '1.5rem' }}>
                      <div className="position-relative">
                        <span
                          ref={boldTextRef}
                          className="mt-2 txt-primary ff ff_bd fs-5 mb-0 lh-1 rounded-0 position-absolute top-0 start-0 z-1 bg-white p-1"
                        >
                          #{task.number} -
                        </span>
                        <Input.TextArea
                          className="mt-2 txt-primary ff fs-5 w-100 mb-0 rounded-0 taskModalTextarea"
                          autoSize={{ minRows: 1, maxRows: 6 }}
                          style={{ border: 'none', padding: '0', textIndent: `${textIndent}px` }}
                          value={decryptedTaskTitle}
                          onChange={handleTaskTitleChange}
                          disabled={access === ACCESS.VIEW}
                        />
                      </div>
                    </div>
                    <hr className="antModalDivider" />
                    <div className="row gy-2 align-items-baseline">
                      <div className="col-md-5 col-sm-7">
                        <div className="row">
                          <div className="col-6">
                            <label className="ff ff_rg f12">Start Date</label>
                            <div className="d-flex justify-content-center">
                              <InputBox nameOfClass={`position-relative inputBoxWidth150 w-100`}>
                                <input
                                  type="text"
                                  value={startDate}
                                  onChange={handleStartDateChange}
                                  className="form-control"
                                  autoComplete="off"
                                  placeholder=" DD/MM/YYYY"
                                  disabled={access === ACCESS.VIEW}
                                />
                                <img
                                  className="position-absolute end-0 me-2 cursor-pointer"
                                  src={DatePickerIconSmall}
                                  alt="icon"
                                  onClick={openStartDatePicker}
                                />
                              </InputBox>
                            </div>
                            {access === ACCESS.EDIT && (
                              <Button className="tomorrow-button ff" onClick={handleStartDateChangeTomorrow}>
                                Set Tomorrow
                              </Button>
                            )}
                          </div>
                          <div className="col-6">
                            <label className="ff ff_rg f12">Due Date</label>
                            <div className="d-flex justify-content-center">
                              <InputBox nameOfClass={`position-relative inputBoxWidth150 w-100`}>
                                <input
                                  type="text"
                                  value={dueDate}
                                  onChange={handleDueDateChange}
                                  className="form-control"
                                  autoComplete="off"
                                  placeholder="DD/MM/YYYY"
                                  disabled={access === ACCESS.VIEW}
                                />
                                <img
                                  className="position-absolute end-0 me-2 cursor-pointer"
                                  src={DatePickerIconSmall}
                                  alt="icon"
                                  onClick={openDueDatePicker}
                                />
                              </InputBox>
                            </div>
                            {access === ACCESS.EDIT && (
                              <Button className="tomorrow-button ff" onClick={handleDueDateChangeTomorrow}>
                                Set Tomorrow
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="d-flex flex-row flex-wrap gap-2 gap-sm-3">
                          <div className="d-flex flex-column">
                            <label className="ff ff_rg f12">Label</label>
                            {Object.keys(selectedStatus).length > 0 ? (
                              <div className="d-flex flex-wrap mt-1">
                                {selectedStatus.map((status, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      backgroundColor: status?.color,
                                      marginRight: '4px',
                                      marginBottom: '4px',
                                      display: 'inline-block',
                                      whiteSpace: 'nowrap',
                                    }}
                                    // className="h32 border-5 ff ff_rg f14 txt-white mr-1 px-3 py-3 cursor-pointer"
                                    className="border-5 ff ff_rg f14 txt-white mr-1 px-2 py-2 cursor-pointer"
                                    onClick={() => {
                                      if (access === ACCESS.VIEW) {
                                        return;
                                      }
                                      setIsLabelPopup(true);
                                    }}
                                  >
                                    {status?.title}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div
                                style={{
                                  backgroundColor: '#FF2D00',
                                }}
                                className="h32 border-5 ff ff_rg f14 txt-white px-3 py-3 d-flex align-items-center justify-content-center cursor-pointer"
                                onClick={() => {
                                  setIsLabelPopup(true);
                                }}
                              >
                                {'NONE'}
                              </div>
                            )}
                          </div>

                          {user.role !== ROLE.CLIENT && (
                            <div className="d-flex flex-column">
                              <label className="ff ff_rg f12">Members</label>
                              {/* {Object.keys(selectedMembers).length > 0 && (
                              <label className="ff ff_rg f12">Members</label>
                              )} */}
                              <div className="d-flex mt-1">
                                {selectedMembers?.map((member, index) => (
                                  <Tooltip
                                    placement="bottom"
                                    title={member?.name}
                                    color={'#fff'}
                                    overlayInnerStyle={{
                                      fontFamily: 'Ubuntu',
                                      fontSize: '16px',
                                      color: '#000000',
                                    }}
                                  >
                                    <Avatar
                                      key={index}
                                      size={35}
                                      style={{
                                        backgroundColor: member.role === ROLE.CLIENT ? '#1061e3' : '#589dc2',
                                        marginRight: '1px',
                                      }}
                                      // onClick={openAddMemberModal}
                                      className="cursor-pointer d-flex align-items-center justify-content-center"
                                    >
                                      {member?.name?.charAt(0)}
                                    </Avatar>
                                  </Tooltip>
                                ))}
                                {access === ACCESS.EDIT && (
                                  <Avatar
                                    size={35}
                                    style={{ backgroundColor: '#22495d' }}
                                    onClick={openAddMemberModal}
                                    className="cursor-pointer d-flex align-items-center justify-content-center"
                                  >
                                    <img src={PlusIconLarge} alt="icon" className="pb-1" />
                                  </Avatar>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr className="antModalDivider mt-2" />
                    <div className="row gy-3 gx-sm-4">
                      <div className="col-md-9">
                        <div class="row">
                          <div class="col">
                            <label class="ff ff_md f16 txt-lightGray mb-3">Task Description</label>
                          </div>
                          {/* {!isEditorOpen && (
                      <div class="col-auto" style={{ cursor: "pointer" }}>
                        <span class="text-primary" onClick={toggleEditor}>
                          Edit
                        </span>
                      </div>
                    )} */}
                        </div>
                        <div className="textEditor ff">
                          {/* {!isEditorOpen && 
                          (
                            <>
                              {taskDesc ? (
                                <p
                                  className="f14"
                                  dangerouslySetInnerHTML={{ __html: taskDesc }}
                                  onClick={() => {
                                    if (access === ACCESS.VIEW) {
                                      return;
                                    }
                                    setIsEditorOpen(true);
                                  }}
                                />
                              ) : editorData ? (
                                <p
                                  className="f14"
                                  dangerouslySetInnerHTML={{
                                    __html: editorData,
                                  }}
                                  onClick={() => {
                                    if (access === ACCESS.VIEW) {
                                      return;
                                    }
                                    setIsEditorOpen(true);
                                  }}
                                />
                              ) : (
                                <div
                                  style={combinedStyles}
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                  onClick={() => {
                                    if (access === ACCESS.VIEW) {
                                      return;
                                    }
                                    setIsEditorOpen(true);
                                  }}
                                >
                                  <p>Add a more detailed description</p>
                                </div>
                              )}
                            </>
                          )} */}

                          {isEditorOpen ? (
                            <>
                              <CKEditor
                                editor={ClassicEditor}
                                data={editorData}
                                onChange={handleEditorChange}
                                config={{
                                  allowedContent: true,
                                  extraAllowedContent: '*{*}',
                                  htmlSupport: {
                                    allow: [
                                      {
                                        name: /.*/,
                                        attributes: true,
                                        classes: true,
                                        styles: true,
                                      },
                                    ],
                                  },
                                  plugins: ['AutoLink', 'Link', ...ClassicEditor.builtinPlugins],
                                }}
                              />
                              <div className="d-flex flex-row mt-2 mb-3 gap-1">
                                <button className="adminAntModelAddButton btn btn-primary" onClick={saveDesc}>
                                  {saveDescLoading ? <BtnLoader /> : 'Save'}
                                </button>
                                <button
                                  className="adminAntModelAddButton btn btn-link bg-transparent text-decoration-none txt-lightGray"
                                  onClick={cancelDesc}
                                >
                                  Cancel
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              {taskDesc ? (
                                <p
                                  className="f14 task-description"
                                  dangerouslySetInnerHTML={{ __html: getLinkifiedHtml(taskDesc) }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === 'A') {
                                      // console.log('Event: ', e.target.href);
                                      window.open(e.target.href, '_blank');
                                    } else {
                                      if (access === ACCESS.VIEW) {
                                        return;
                                      }
                                      setIsEditorOpen(true);
                                    }
                                  }}
                                />
                              ) : editorData ? (
                                <p
                                  className="f14 task-description"
                                  dangerouslySetInnerHTML={{
                                    __html: getLinkifiedHtml(editorData),
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (e.target.tagName === 'A') {
                                      // console.log('Event: ', e.target.href);
                                      window.open(e.target.href, '_blank');
                                    } else {
                                      if (access === ACCESS.VIEW) {
                                        return;
                                      }
                                      setIsEditorOpen(true);
                                    }
                                  }}
                                />
                              ) : (
                                <div
                                  style={combinedStyles}
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                  onClick={() => {
                                    if (access === ACCESS.VIEW) {
                                      return;
                                    }
                                    setIsEditorOpen(true);
                                  }}
                                >
                                  <p>Add a more detailed description</p>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <hr className="antModalDivider" />

                        {task?.checkLists && <CheckListComponent ref={newChecklistRef} />}

                        <div ref={attachmentRef}></div>

                        <div>
                          {Object.keys(allAttachments).length > 0 && (
                            <LoadingPreviewComponent files={allAttachments} taskId={task?._id} />
                          )}
                        </div>
                        <div className="d-flex flex-column mb-2">
                          <label className="ff ff_md f16 txt-lightGray pb-2">Activity</label>
                          <div className="d-flex flex-row position-relative">
                            <Avatar
                              style={{
                                backgroundColor: '#589dc2',
                                minWidth: '38px',
                                minHeight: '38px',
                                lineHeight: '38px',
                                fontSize: '16px',
                                fontFamily: 'Ubuntu',
                              }}
                            >
                              {user?.name?.charAt(0)}
                            </Avatar>
                            {/* <InputBox nameOfClass={`ff ff_md f16 mx-2 w642`}> */}
                            <Mentions
                              ref={mentionsRef}
                              autoSize
                              type="text"
                              value={comment}
                              options={options}
                              onSelect={handleSelectChange}
                              onKeyDown={handleKeyPress}
                              onChange={handleCommentChange}
                              className="FormInput form-control ff ff_md f16 mx-2"
                              autoComplete="off"
                              placeholder="Write a comment"
                            />

                            {/* {showDropdown && (
                                <Select
                                  options={options}
                                  onChange={handleSelectChange}
                                  menuIsOpen={true}
                                  styles={customStyles}
                                  ref={selectRef}
                                  className="position-absolute start-0 mw-100 memberDropdownList"
                                />
                              )} */}
                            {/* </InputBox> */}
                          </div>

                          {comments &&
                            comments.map((comment, index) => (
                              <div key={index} className="d-flex flex-row position-relative my-2">
                                <Tooltip title={comment?.commentBy?.name}>
                                  <Avatar
                                    style={{
                                      backgroundColor: '#589dc2',
                                      minWidth: '38px',
                                      minHeight: '38px',
                                      lineHeight: '38px',
                                      fontSize: '16px',
                                      fontFamily: 'Ubuntu',
                                    }}
                                  >
                                    {comment?.commentBy?.name?.charAt(0)}
                                  </Avatar>
                                </Tooltip>
                                <div className="d-flex flex-column w-100 mx-2">
                                  {commentToEdit?._id === comment._id ? (
                                    <Mentions
                                      ref={editMentionsRef}
                                      autoSize
                                      type="text"
                                      id="editComment"
                                      value={commentToEdit.comment}
                                      options={options}
                                      onSelect={handleEditSelectChange}
                                      onKeyDown={handleEditKeyPress}
                                      onChange={(e) =>
                                        setCommentToEdit((prevState) => {
                                          return { ...prevState, comment: e };
                                        })
                                      }
                                      className="FormInput form-control ff "
                                      autoComplete="off"
                                      placeholder="Write a comment"
                                    />
                                  ) : (
                                    <>
                                      <div className="d-flex flex-row break-word">
                                        <div className="mr-auto w-100">
                                          <InputBox nameOfClass={`ff ff_md f16`}>
                                            <label
                                              className="text-break"
                                              onClick={() => {
                                                if (
                                                  user?.role === ROLE.ADMIN ||
                                                  user?._id === comment?.commentBy?._id
                                                ) {
                                                  setCommentToEdit(comment);
                                                  setTimeout(() => {
                                                    const input = editMentionsRef.current;

                                                    if (input) {
                                                      input.focus();
                                                      const length = comment.comment.length;
                                                      input.textarea.setSelectionRange(length, length);
                                                    }
                                                  }, 100);
                                                }
                                              }}
                                            >
                                              {renderComment(comment.comment)}
                                            </label>
                                          </InputBox>
                                        </div>
                                        {(user?.role === ROLE.ADMIN || user?._id === comment?.commentBy?._id) && (
                                          <Col xs="auto" className="ml-auto">
                                            <div
                                              style={{ marginLeft: '20px' }}
                                              onClick={() => {
                                                setDeleteCommentId(comment._id);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faTrash} className="text-danger" />
                                            </div>
                                          </Col>
                                        )}
                                      </div>
                                      <span>
                                        {comment?.date ? convertUTCToLocalTimeForComments(comment.date) : '-'}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>

                      <CommentDeleteConfirmationModal
                        commentId={deleteCommentId}
                        modalVisible={deleteCommentId}
                        setModalVisible={setDeleteCommentId}
                        onConfirm={deleteComment}
                      />

                      {moveTaskDetails && (
                        <MoveTaskModal
                          moveDetails={moveTaskDetails}
                          closeModal={() => setMoveTaskDetails(null)}
                          taskId={moveTaskDetails?._id}
                        />
                      )}

                      <div className="col-sm-3">
                        <div className="p-2 mb-3 addTOcard_mobile">
                          <label className="ff ff_md f12 mb-1">Add to card</label>
                          <div className="d-flex flex-sm-column flex-row gap-2">
                            {access === ACCESS.EDIT && (
                              <>
                                <AddToCard
                                  setIsAttachment={setIsAttachment}
                                  setIsCheckListModal={setIsCheckListModal}
                                  setIsAddMember={setIsAddMember}
                                  setIsLabelPopup={setIsLabelPopup}
                                />
                                <Upload
                                  multiple
                                  disabled={access === ACCESS.VIEW}
                                  showUploadList={false}
                                  className="upload-w-100"
                                  customRequest={({ file, onSuccess, onError }) => {
                                    uploadToS3([file])
                                      .then(() => onSuccess('ok'))
                                      .catch(onError);
                                  }}
                                >
                                  <div
                                    className="addToCardData mb-2 mb-md-0 d-flex flex-row px-2 py-2"
                                    // onClick={clickHandler}
                                  >
                                    <img src={AttachMentIcon} alt="Icon" />
                                    <span className="ps-2 ff ff_rg">{'Attachment'}</span>
                                  </div>
                                </Upload>

                                {user.role !== ROLE.CLIENT && (
                                  <div
                                    className="addToCardData mb-2 mb-md-0 d-flex flex-row px-2 py-2"
                                    onClick={() => {
                                      setIsAddMember(true);
                                    }}
                                  >
                                    <img src={MemberIcon} alt="Icon" />
                                    <span className="ps-2 ff ff_rg">{'Members'}</span>
                                  </div>
                                )}

                                {(user.role === ROLE.ADMIN || user._id === task?.createdBy?._id) && (
                                  <>
                                    <div
                                      className="addToCardData mb-2 mb-md-0 d-flex flex-row px-2 py-2"
                                      onClick={() => {
                                        setIsArchiveModalOpen(true);
                                      }}
                                    >
                                      <DropboxOutlined />
                                      <span className="ps-2 ff ff_rg">{'Archive'}</span>
                                    </div>

                                    <div
                                      className="addToCardData mb-2 mb-md-0 d-flex flex-row px-2 py-2"
                                      style={{ color: 'red' }}
                                      onClick={() => setIsDeleteModalOpen(true)}
                                    >
                                      <DeleteOutlined />
                                      <span className="ps-2 ff ff_rg">{'Delete'}</span>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                            <div
                              className="addToCardData mb-2 mb-md-0 d-flex flex-row px-2 py-2"
                              onClick={() => {
                                const baseUrl = window.location.origin;
                                let urlToCopy;
                                if (baseUrl.indexOf('localhost') > -1) {
                                  urlToCopy = `${window.location.origin}/task/p/${projectDetails.slug}/t/${task.slug}`;
                                } else if (baseUrl.indexOf('dev') > -1) {
                                  urlToCopy = `${window.location.origin}/task/p/${projectDetails.slug}/t/${task.slug}`;
                                } else {
                                  urlToCopy = `${window.location.origin}/p/${projectDetails.slug}/t/${task.slug}`;
                                }
                                navigator.clipboard.writeText(urlToCopy);
                                // message.info(urlToCopy);
                                message.info('Link copied');
                              }}
                            >
                              <ShareAltOutlined />
                              <span className="ps-2 ff ff_rg">{'Copy Link'}</span>
                            </div>
                            {(user?.role === ROLE.ADMIN || user?._id === task?.createdBy?._id) && (
                              <>
                                <div
                                  className="addToCardData mb-2 mb-md-0 d-flex flex-row px-2 py-2"
                                  onClick={() => {
                                    setMoveTaskDetails({ _id: task._id, operation: OPERATION.MOVE });
                                  }}
                                >
                                  <ArrowRightOutlined />
                                  <span className="ps-2 ff ff_rg">{'Move'}</span>
                                </div>
                                <div
                                  className="addToCardData mb-2 mb-md-0 d-flex flex-row px-2 py-2"
                                  onClick={() => {
                                    setMoveTaskDetails({ _id: task._id, operation: OPERATION.COPY });
                                  }}
                                >
                                  <CopyOutlined />
                                  <span className="ps-2 ff ff_rg">{'Copy'}</span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                          <div>
                            <label className="ff ff_rg f12">Created</label>
                            {
                              <div className="d-flex flex-column flex-wrap fs-7 fw-medium">
                                <span className="me-1 ff">
                                  {task?.createdAt ? formatDateToLocal(task.createdAt) : '-'}
                                </span>
                                <span className="d-inline-block ff">
                                  {task?.createdBy?.name ? task?.createdBy?.name : '-'}
                                </span>
                              </div>
                            }
                          </div>
                          {task?.updatedBy && (
                            <div>
                              <label className="ff ff_rg f12">Updated</label>
                              {
                                <div className="d-flex flex-column flex-wrap fs-7 fw-medium">
                                  <span className="me-1 ff">
                                    {task?.updatedAt ? formatDateToLocal(task.updatedAt) : '-'}
                                  </span>
                                  <span className="d-inline-block ff">
                                    {task?.updatedBy?.name ? task?.updatedBy?.name : '-'}
                                  </span>
                                </div>
                              }
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </Modal>
      <AddMember isOpen={isAddMember} setIsAddMember={setIsAddMember} taskId={taskId} />
      <CheckListModal
        isOpen={isCheckListModal}
        setIsCheckListModal={setIsCheckListModal}
        setIsCheckList={setIsCheckList}
        setCheckListName={setCheckListName}
      />
      <AttachmentModal
        isOpen={isAttachment}
        setIsAttachment={setIsAttachment}
        setIsAttachmentCreated={setIsAttachmentCreated}
      />
      <LabelSelector isOpen={isLabelPopup} setIsLabelPopup={setIsLabelPopup} />
      <CustomDateTimeRangePicker
        preSelectedDate={startDate}
        isDateTimeRangePicker={startDateTimeRangePicker}
        isCalenderOpen={isStartCalenderOpen}
        setDateTimeRangePicker={setStartDateTimeRangePicker}
        setIsCalenderOpen={setIsStartCalenderOpen}
        setDate={(date) => {
          const dataToSend = {
            scheduledDate: formatDateForDispatch(date),
          };
          dispatch(updateTaskAPI(dataToSend, task?._id, projectDetails._id, socket));
          setStartDate(date);
        }}
      />
      <CustomDateTimeRangePicker
        preSelectedDate={dueDate}
        isDateTimeRangePicker={isDueDateTimeRangePicker}
        isCalenderOpen={isDueCalenderOpen}
        setDateTimeRangePicker={setIsDueDateTimeRangePicker}
        setIsCalenderOpen={setIsDueCalenderOpen}
        setDate={(date) => {
          if (!isDateGreater(date)) {
            message.error('Due date cannot be earlier than start date');
            return;
          }
          const dataToSend = {
            endDate: formatDateForDispatch(date),
          };
          dispatch(updateTaskAPI(dataToSend, task?._id, projectDetails._id, socket));
          setDueDate(date);
        }}
      />
    </>
  );
});

//AddMember Modal
export const AddMember = ({ isOpen, setIsAddMember, taskId }) => {
  const socket = useSocket();
  const [projectEmployees, setProjectEmployees] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dispatch = useDispatch();
  const { task, members } = useSelector((state) => state.addTask);

  useEffect(() => {
    const updatedOptions = members.map((member) => ({
      value: member._id,
      label: member.name,
      role: member.role,
    }));
    setProjectEmployees(updatedOptions);
  }, [members]);

  useEffect(() => {
    if (!task || Object.keys(task).length === 0 || !task.assignee) {
      return;
    }
    const updatedOptions = task?.assignee.map((member) => ({
      value: member._id,
      label: member.name,
      role: member.role,
    }));
    setSelectedOptions(updatedOptions);
  }, [task]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    const dataToSend = {
      member: selectedOptions.map((member) => member.value),
    };
    dispatch(updateTaskAPI(dataToSend, task?._id, '', socket));
  };

  const createHandler = () => {
    dispatch({
      type: 'SET_SELECTED_MEMBERS_FOR_TASK_UPDATE',
      payload: selectedOptions.map((option) => ({
        _id: option.value,
        name: option.label,
        role: option.role,
      })),
    });
    setIsAddMember(false);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={createHandler}
        footer={null}
        maskClosable={false}
        closable={true}
        centered={true}
        closeIcon={<CloseIcon />}
      >
        <h3 className="text-center mt-3 mb-3 txt-primary ff ff_bd f24 responsiveModalLabel mb-1">{'Member'}</h3>
        <div className="ff m-3">
          <Select
            placeholder="Select Members"
            options={projectEmployees}
            isMulti
            value={selectedOptions}
            onChange={handleSelectChange}
          />
        </div>
        {/* <div className="d-flex justify-content-center mt-3 mb-4">
          <button
            className="adminAntModelAddButton txt-white ff ff_rg f16 py-3 justify-content-center"
            onClick={() => {
              setIsAddMember(false);
            }}
          >
            Save
          </button>
        </div> */}
      </Modal>
    </>
  );
};

export const AddToCard = ({ setIsAttachment, setIsCheckListModal, setIsAddMember, setIsLabelPopup }) => {
  const addToCardData = [
    {
      icon: LabelIcon,
      label: 'Label',
      id: 1,
    },
    // {
    //   icon: MemberIcon,
    //   label: 'Members',
    //   id: 2,
    // },
    {
      icon: CheckListIcon,
      label: 'Checklist',
      id: 3,
    },
  ];

  return addToCardData.map((ele, index) => {
    return (
      <AddToCardIcons
        icon={ele?.icon}
        label={ele?.label}
        key={index}
        setIsAttachment={setIsAttachment}
        setIsCheckListModal={setIsCheckListModal}
        setIsAddMember={setIsAddMember}
        setIsLabelPopup={setIsLabelPopup}
        id={ele.id}
      />
    );
  });
};

export const AddToCardIcons = ({
  icon,
  label,
  setIsCheckListModal,
  setIsAttachment,
  setIsAddMember,
  setIsLabelPopup,
  id,
}) => {
  const { access } = useSelector((state) => state.addTask);
  const clickHandler = () => {
    if (access === ACCESS.VIEW) {
      return;
    }
    if (id === 1) setIsLabelPopup(true);
    if (id === 2) setIsAddMember(true);
    if (id === 3) setIsCheckListModal(true);
    if (id === 4) setIsAttachment(true);
    //
  };

  return (
    <div className="addToCardData mb-2 mb-md-0 d-flex flex-row px-2 py-2" onClick={clickHandler}>
      <img src={icon} alt="Icon" />
      <span className="ps-2 ff ff_rg">{label}</span>
    </div>
  );
};

// CheckList Modal
export const CheckListModal = ({
  isOpen,
  setIsCheckListModal,
  setIsCheckList,
  setCheckListName: setCheckListForEditModal,
}) => {
  const socket = useSocket();
  const [checkListName, setCheckListName] = useState('');
  let { task } = useSelector((state) => state.addTask);
  const dispatch = useDispatch();
  const addHandler = () => {
    dispatch(AddCheckListAPI({ title: checkListName }, task?._id, socket));
    setIsCheckList(true);
    setCheckListForEditModal(checkListName);
  };

  const cancelSuperHandler = () => {
    setIsCheckListModal(false);
    setCheckListName('');
  };

  return (
    <Modal
      open={isOpen}
      onOk={addHandler}
      onCancel={cancelSuperHandler}
      footer={null}
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={'31.25rem'}
    >
      <h3 className="text-center mt-4 mb-3 txt-primary ff ff_bd f24 responsiveModalLabel">{'Create CheckList'}</h3>
      <InputBox label="Checklist Title" nameOfClass={`ff ff_md f14 my-2`}>
        <input
          type="text"
          value={checkListName}
          onChange={(e) => {
            let inputValue = e.target.value;

            if (inputValue.length > 0) {
              inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
            }

            setCheckListName(inputValue);
          }}
          className="FormInput form-control"
          autoComplete="off"
          placeholder="Enter Name"
        />
      </InputBox>
      <div className="d-flex justify-content-center mt-4 mb-4">
        <button
          className="btn btn-primary px-4"
          onClick={() => {
            addHandler();
            cancelSuperHandler();
          }}
        >
          Create
        </button>
      </div>
    </Modal>
  );
};

//Attachment Modal
export const AttachmentModal = ({ isOpen, setIsAttachment, setIsAttachmentCreated }) => {
  const [link, setLink] = useState('');
  const [fileList, setFileList] = useState([]);
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const [uploadedImageLinks, setUploadedImageLinks] = useState([]);
  const dispatch = useDispatch();

  //
  const addHandler = () => {
    dispatch({
      type: 'UPLOAD_ATTACHMENT_SUCCESS',
      payload: uploadedImageLinks,
    });
    setFileList([]);
    setUploadedImageLinks([]);
    setIsAttachmentCreated(true);
    setIsAttachment(false);
  };

  const cancelSuperHandler = () => {
    // setFileList([]);
    setIsAttachment(false);
  };

  function transformLink(originalLink) {
    var transformedLink = originalLink.replace('/3rd-task-dev', '/98615ef974e04ff8ae1fbb8a895b72f0:3rd-task-dev');

    return transformedLink;
  }

  const customRequest = async ({ file, onSuccess, onError }) => {
    const uniqueFileName = `${uuidv4()}_${file.name}`;
    const s3 = new AWS.S3({
      accessKeyId: '29f47bfdd00e24edb6aeabc87b53d5b2',
      secretAccessKey: 'f42d1fa109528f9dcbf3c430818f62e6',
      endpoint: 'https://sin1.contabostorage.com/', // Provide your custom S3 endpoint here
      s3ForcePathStyle: true, // Necessary for MinIO, remove for AWS S3
    });

    const params = {
      Bucket: '3rd-task-dev',
      Key: uniqueFileName,
      Body: file,
    };

    try {
      const data = await s3.upload(params).promise();
      onSuccess(data, file);
      message.success('File uploaded successfully');
      // Capture the URL of the uploaded file from the response
      const fileUrl = data.Location;
      setUploadedImageLinks((prevState) => [...prevState, transformLink(fileUrl)]);
    } catch (error) {
      console.error('Error uploading file:', error);
      onError(error);
      message.error('Error uploading file');
    }
  };

  return (
    <Modal
      open={isOpen}
      onOk={addHandler}
      onCancel={cancelSuperHandler}
      footer={null}
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={'31.25rem'}
    >
      <h3 className="text-center mt-4 mb-3 txt-primary ff ff_bd f24 responsiveModalLabel">{'Attachment'}</h3>
      <div className="d-flex justify-content-center flex-column w-100">
        <Upload
          customRequest={customRequest}
          className="d-flex flex-column justify-content-center"
          // accept=".pdf,.doc,.docx,image/png,image/jpg,image/webp,image/jpeg"
          listType="picture"
          maxCount={5}
          multiple={true}
          method="POST"
          withCredentials
          fileList={fileList}
          size={{ strokeWidth: 8 }}
          showUploadList={{
            showDownloadIcon: false,
            showRemoveIcon: false,
          }}
          onChange={handleChange}
        >
          <div className="attachment d-flex justify-content-center mx-4">
            <div className="attachmentStyle text-center px-3">
              <div className="py-4">
                <img src={FileUpload} alt="" className="img-fluid mb-3" />
                <p className="ff ff_md mb-1 f16">
                  Drag & Drop File/ <span className="ff ff_md f16 txt-secondary">Click Here To Upload File</span>
                </p>
                <p className="ff ff_rg txt-light-md mb-1 f14">You can upload JPG, PNG, PDF, Etc...</p>
              </div>
            </div>
          </div>
        </Upload>

        {/* <hr className="antModalDivider" /> */}
        <InputBox label="Add Link" nameOfClass={`ff ff_md f16  mx-4 my-3`}>
          <input
            type="text"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
            className="FormInput form-control"
            autoComplete="off"
            placeholder="Add Link"
          />
        </InputBox>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <button
            className="ff ff_rg py-3 f16 adminAntModelAddButton txt-white justify-content-center"
            onClick={() => {
              addHandler();
              // cancelSuperHandler();
            }}
          >
            Insert
          </button>
        </div>
      </div>
    </Modal>
  );
};

//LabelSelector
export const LabelSelector = ({ isOpen, setIsLabelPopup }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const { status, selectedStatus: selectedStatusStore, task, access } = useSelector((state) => state.addTask);
  const { viewType } = useSelector((state) => state.viewType);
  const { task: stagedTask } = useSelector((state) => state.stage);
  const dispatch = useDispatch();
  const [projectlabels, setProjectLabels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState(task.status);
  const [isLabelCreator, setIsLabelCreator] = useState(false);

  const socket = useSocket();

  useEffect(() => {
    setProjectLabels(status);
  }, [status]);

  useEffect(() => {
    setSelectedLabels(task?.status);
  }, [task?.status]);

  const addHandler = () => {};

  const cancelSuperHandler = () => {
    setIsLabelPopup(false);
    dispatch({
      type: 'SET_SELECTED_STATUS_FOR_TASK_UPDATE',
      payload: selectedLabels,
    });
    // setSelectedLabels("Pending");
  };
  const handleLabelToggle = (label) => {
    const statusToUpdate = status.find((item) => item.title === label);

    const labelExists = selectedLabels.some((selectedLabel) => selectedLabel.title === label);
    if (labelExists) {
      // If the label already exists, remove it from selectedLabels
      const dataToSend = {
        status: {
          remove: [statusToUpdate._id],
        },
      };
      // console.log('Status: ', statusToUpdate);
      if (viewType === 'status') {
        dispatch({
          type: 'REMOVE_TASK_FROM_STATUS_FOR_UPDATE',
          payload: { statusIdToRemove: statusToUpdate._id, taskIdToRemove: task._id },
        });
      }
      dispatch(updateTaskAPI(dataToSend, task._id, '', socket));
      setSelectedLabels(selectedLabels.filter((status) => status.title !== label));
    } else {
      // If the label doesn't exist, add it to selectedLabels
      const dataToSend = {
        status: {
          add: [statusToUpdate._id],
        },
      };
      // console.log('Status: ', statusToUpdate);
      if (viewType === 'status') {
        dispatch({
          type: 'ADD_TASK_INTO_STATUS_FOR_UPDATE',
          payload: { statusId: statusToUpdate._id, taskData: stagedTask },
        });
      }
      dispatch(updateTaskAPI(dataToSend, task._id, '', socket));
      setSelectedLabels([...selectedLabels, { ...statusToUpdate }]);
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onOk={addHandler}
        onCancel={cancelSuperHandler}
        footer={null}
        maskClosable={false}
        closable={true}
        centered={true}
        closeIcon={<CloseIconSmall />}
        width={'17rem'}
      >
        <h3 className="text-center mt-2 mb-2 txt-primary ff ff_bd f20 responsiveModalLabel">{'Label'}</h3>
        <div className="d-flex flex-column gap-2">
          {projectlabels?.map((ele, index) => {
            return (
              <SingleLableComponent
                ele={ele}
                selectedLabels={selectedLabels}
                handleLabelToggle={handleLabelToggle}
                key={ele?._id}
                role={user.role}
                access={access}
              />
            );
          })}
        </div>
        {access === ACCESS.EDIT && (
          <div
            className="addCard d-flex flex-row justify-content-center mt-3"
            onClick={() => {
              setIsLabelCreator(true);
            }}
          >
            <img src={PlusIcon} alt="icon" className="cursor-pointer" />
            <p className="ff ff_rg f16 txt-secondary mb-0 py-2 ms-2">Create Label</p>
          </div>
        )}
      </Modal>
      <CreateLabel
        isOpen={isLabelCreator}
        setIsLabelCreator={setIsLabelCreator}
        setSelectedLabels={setSelectedLabels}
      />
    </>
  );
};

//CreateLabel Popup
export const CreateLabel = ({ isOpen, setIsLabelCreator, setSelectedLabels }) => {
  const [selectedColor, setSelectedColor] = useState('#B5B5B5');
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();
  const { projectDetails } = useSelector((state) => state.addTask);

  useEffect(() => {
    if (isOpen) {
      setInputValue('');
    }
  }, [isOpen]);

  const addHandler = () => {
    setIsLabelCreator(true);
    setSelectedLabels(['Pending']);
  };

  const cancelSuperHandler = () => {
    setIsLabelCreator(false);
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
    // setInputValue("");
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const colors = [
    '#B5B5B5',
    '#FFCD91',
    '#84CB79',
    '#FF7272',
    '#72D5FF',
    '#E2B203',
    '#454F59',
    '#E774BB',
    '#5D1F1A',
    '#352C63',
    '#5E4DB2',
    '#4C6B1F',
    '#50253F',
    '#164B35',
    '#09326C',
    '#164555',
  ];

  return (
    <Modal
      open={isOpen}
      onOk={addHandler}
      onCancel={cancelSuperHandler}
      footer={null}
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIconSmall />}
      destroyOnClose
      width={'17rem'}
    >
      <h3 className="text-center my-2 txt-primary ff ff_bd f20 responsiveModalLabel">{'Create Label'}</h3>
      <div className="d-flex flex-column">
        <input
          type="text"
          id="textInput"
          value={inputValue}
          onChange={handleInputChange}
          style={{ backgroundColor: selectedColor, color: 'white' }}
          className="ff ff_rg f16 p-2 rounded-2 mb-2 text-center"
          placeholder="Type Here"
        />
        <div className="">
          <label className="ff ff_md f14 txt-lightGray mb-1">Select Color</label>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: '8px',
            }}
          >
            {colors.map((color, index) => (
              <div
                key={index}
                onClick={() => handleColorClick(color)}
                style={{
                  width: '40px',
                  height: '24px',
                  backgroundColor: color,
                  cursor: 'pointer',
                  border: selectedColor === color ? '1px solid #000' : 'none',
                  borderRadius: '5px',
                }}
              />
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3 mb-2">
          <button
            className="adminAntModelAddButton ff ff_rg py-3 f16 txt-white justify-content-center"
            onClick={() => {
              const dataToSend = {
                title: inputValue,
                color: selectedColor,
                project: projectDetails._id,
              };
              dispatch(AddStatusAPI(dataToSend, projectDetails.slug));
              setIsLabelCreator(false);

              // setSelectedLabels(["Pending"]);
              // cancelSuperHandler();
            }}
          >
            Create
          </button>
        </div>
      </div>
    </Modal>
  );
};

const CommentDeleteConfirmationModal = ({ commentId, modalVisible, setModalVisible, onConfirm }) => {
  const handleDelete = () => {
    if (onConfirm) {
      onConfirm(commentId);
    }
    setModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Delete the comment"
        open={modalVisible}
        centered
        onCancel={() => setModalVisible(null)}
        footer={[
          <button key="cancel" className="ff f16 c-white" onClick={() => setModalVisible(false)}>
            Cancel
          </button>,
          <button key="delete" className="ff f16 c-white txt-error" onClick={handleDelete}>
            Remove
          </button>,
        ]}
      >
        <p>Aye you sure you want to delete comment?</p>
      </Modal>
    </>
  );
};
