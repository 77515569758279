import React, { useState, useEffect, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';

const MyPdfViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(0);
  const [pdfDocument, setPdfDocument] = useState(null);
  const canvasRefs = useRef([]);

  const { pdfDocument: loadedDocument } = usePdf({ file });

  useEffect(() => {
    if (loadedDocument) {
      setPdfDocument(loadedDocument);
      setNumPages(loadedDocument.numPages);
    }
  }, [loadedDocument]);

  useEffect(() => {
    // Cleanup PDF document and canvas references when the file changes
    return () => {
      setPdfDocument(null);
      setNumPages(0);
      canvasRefs.current = []; // Clear canvas references
    };
  }, [file]);

  const renderPage = (pageNum, canvas) => {
    if (!pdfDocument || !canvas) return;

    const context = canvas.getContext('2d');
    pdfDocument.getPage(pageNum).then((page) => {
      const viewport = page.getViewport({ scale: 1 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      // Clear the canvas before rendering
      context.clearRect(0, 0, canvas.width, canvas.height);

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      page.render(renderContext);
    });
  };

  return (
    <div>
      {!pdfDocument && <span>Loading...</span>}
      {pdfDocument && (
        <div style={{ overflowY: 'auto', maxHeight: '80vh', textAlign: 'center' }}>
          {Array.from({ length: numPages }, (_, index) => (
            <canvas
              key={`page_${index + 1}`}
              ref={(canvas) => {
                if (canvas) {
                  canvasRefs.current[index] = canvas; // Save canvas reference
                  renderPage(index + 1, canvas);
                }
              }}
              style={{ marginBottom: '20px', display: 'block' }}
            />
          ))}
        </div>
      )}
      {pdfDocument && (
        <nav>
          <p>Total Pages: {numPages}</p>
        </nav>
      )}
    </div>
  );
};

export default MyPdfViewer;
