import { Col, Input, Modal, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateLabelTitleAPI } from '../Redux/updateItemAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { deleteStatusAPI } from '../Redux/deleteItemAPI';
import { ACCESS, ROLE } from '../../utils';

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

const DeleteConfirmationModal = ({ statusTitle, modalVisible, setModalVisible, onConfirm }) => {
  const handleDelete = () => {
    if (onConfirm) {
      onConfirm();
    }
    setModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Delete the status"
        open={modalVisible}
        centered
        onCancel={() => setModalVisible(false)}
        footer={[
          <button key="cancel" className="ff f16 c-white" onClick={() => setModalVisible(false)}>
            Cancel
          </button>,
          <button key="delete" className="ff f16 c-white txt-error" onClick={handleDelete}>
            Remove
          </button>,
        ]}
      >
        <p>Aye you sure you want to delete status {<span style={{ fontWeight: 'bold' }}>{statusTitle}</span>}?</p>
      </Modal>
    </>
  );
};

const SingleLableComponent = ({ ele, selectedLabels, handleLabelToggle, role, access }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(ele?.title);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const saveTitle = useCallback(
    (title) => {
      const dataToSend = {
        title: title,
      };
      dispatch(updateLabelTitleAPI(ele?._id, dataToSend));
    },
    [ele?._id]
  );
  const saveTitleDelayed = useCallback(debounce(saveTitle, 1000), [saveTitle]);
  const handleLabelTitleChange = (e) => {
    saveTitleDelayed(e.target.value);
    setTitle(e.target.value);
  };

  return (
    <>
      <DeleteConfirmationModal
        modalVisible={deleteModalOpen}
        setModalVisible={setDeleteModalOpen}
        statusTitle={ele?.title}
        onConfirm={() => {
          dispatch(deleteStatusAPI(ele?._id));
          dispatch({ type: 'REMOVE_STATUS', payload: { statusId: ele?._id } });
        }}
      />
      <div className="d-flex flex-row h32 align-items-center" key={ele?._id}>
        <div>
          <input
            type="checkbox"
            checked={selectedLabels?.some((label) => label.title === ele.title)}
            onChange={() => handleLabelToggle(`${ele?.title}`)}
          />
        </div>
        <div className={`ms-1 label`}>
          <div>
            <Input.TextArea
              className="ff ff_rg f14 p-2"
              autoSize={{ minRows: 1, maxRows: 6 }}
              style={{
                border: 'none',
                padding: '0',
                backgroundColor: `${ele.color}`,
                color: 'white',
              }}
              value={title}
              onChange={handleLabelTitleChange}
              // disabled={access === ACCESS.VIEW}
            />
          </div>
        </div>
        {role !== ROLE.CLIENT && access === ACCESS.EDIT && (
          <Col xs="auto" className="ml-auto">
            <div
              style={{ marginLeft: '20px' }}
              onClick={() => {
                //   message.info("Delete");
                setDeleteModalOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} className="text-danger" />
            </div>
          </Col>
        )}
      </div>
    </>
  );
};

export default SingleLableComponent;
