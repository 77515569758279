import React, { useEffect, useState } from 'react';
import { Image, Button, message, Progress, Space, Modal, Tooltip } from 'antd';
import AWS from 'aws-sdk';
import { FilePdfOutlined, FileOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAttachmentInTaskAPI } from '../Redux/deleteItemAPI';
import { ACCESS } from '../../utils';
import { useSocket } from '../../helpers/SocketContext';

const LoadingPreviewComponent = ({ files, taskId }) => {
  const [attachments, setAttachments] = useState([]);
  useEffect(() => {
    setAttachments(files);
  }, [files]);

  const getExtension = (url) => {
    return url.split('.').pop().toLowerCase();
  };

  return (
    <>
      {Object.keys(attachments).length > 0 && (
        <>
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-row justify-content-between align-items-center gap-3">
              <p className="ff ff_md f16 txt-lightGray mb-2">{`Attachments (${attachments.length})`}</p>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {attachments.map((file, index) => (
              <div key={index} className="mb-3 me-sm-3 me-2">
                {/* {renderPreview(file, index)} */}
                <FilePreview file={file} index={index} taskId={taskId} />
              </div>
            ))}
          </div>

          <hr className="antModalDivider" />
        </>
      )}
    </>
  );
};

export default LoadingPreviewComponent;

const getExtension = (url) => {
  return url?.split('.').pop().toLowerCase();
};

const FilePreview = ({ file, index, taskId }) => {
  const socket = useSocket();
  const { access } = useSelector((state) => state.addTask);
  const [loading, setLoading] = useState(false);
  const { url, progress } = file;
  // const fileName = url?.split("/").pop().split("_").slice(1).join("_");
  const fileName = decodeURIComponent(url?.split('/').pop().split('_').slice(1).join('_'));
  // console.log('File Name: ', fileName)
  const extension = getExtension(url);
  const dispatch = useDispatch();

  // const downloadFile = (url, customName) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  //   const link = document.createElement('a');
  //   link.href = url;
  //   // link.download = url?.split('/').pop();
  //   console.log('Name: ', customName);
  //   link.download = 'some name.png';
  //   // link.click();
  //   console.log('Link: ', link);
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const downloadFile = async (url, customName) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch file');

      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = customName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const deleteFile = (url) => {
    if (access === ACCESS.VIEW) {
      return;
    }
    message.success('File deleted successfully');
    const dataToSend = {
      attachments: {
        remove: [url],
      },
    };
    dispatch(deleteAttachmentInTaskAPI(dataToSend, taskId, socket));
    dispatch({ type: 'DELETE_ATTACHMENT_SUCCESS', payload: url });

    const fileName = url?.split('/').pop();
    const s3 = new AWS.S3({
      accessKeyId: '29f47bfdd00e24edb6aeabc87b53d5b2',
      secretAccessKey: 'f42d1fa109528f9dcbf3c430818f62e6',
      endpoint: 'https://sin1.contabostorage.com/',
      s3ForcePathStyle: true,
    });

    const params = {
      Bucket: '3rd-task-dev',
      Key: fileName,
    };

    s3.deleteObject(params, (err, data) => {
      if (err) {
        console.error('Error deleting image:', err);
      }
    });
  };

  const containerStyles = {
    position: 'relative',
    width: '150px',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ccc',
    overflow: 'hidden',
    marginBottom: '0',
    cursor: 'pointer',
  };

  const iconStyles = {
    position: 'absolute',
    top: '10px',
    left: '10px',
    zIndex: '1',
  };

  const imageStyles = {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
  };

  const handlePreviewClick = (index) => {
    console.log(index);
    dispatch({
      type: 'OPEN_FILE_PREVIEW',
      payload: index,
    });
  };

  return (
    <div key={index}>
      <div
        style={containerStyles}
        className="attachmentsBox"
        onClick={() => {
          if (progress === 101) handlePreviewClick(index);
        }}
      >
        {progress > 100 && (
          <div style={iconStyles}>
            <Button
              type="primary"
              loading={loading}
              icon={<DownloadOutlined style={{ fontSize: '12px' }} />}
              onClick={(e) => {
                e.stopPropagation();
                downloadFile(url, fileName);
              }}
              style={{
                width: '25px',
                height: '25px',
                padding: '0',
                marginRight: '5px',
              }}
            />
            {access === ACCESS.EDIT && (
              <Button
                danger
                icon={<DeleteOutlined style={{ fontSize: '12px' }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteFile(url);
                }}
                style={{ width: '25px', height: '25px', padding: '0' }}
              />
            )}
          </div>
        )}
        {progress > 100 ? (
          <>
            {['png', 'jpg', 'jpeg'].includes(extension) ? (
              <Image style={imageStyles} src={url} preview={false} />
            ) : extension === 'pdf' ? (
              <FilePdfOutlined style={{ fontSize: '68px', color: '#ff0000' }} />
            ) : (
              <FileOutlined style={{ fontSize: '68px', color: '#0000ff' }} />
            )}
          </>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Progress type="circle" percent={progress} />
          </div>
        )}
      </div>
      <div
        className="attachmentFileName text-truncate ff"
        style={{
          textAlign: 'center',
          marginTop: '0',
          width: '150px',
          fontWeight: 'bold',
        }}
      >
        <Tooltip
          placement="bottom"
          title={fileName}
          color={'#fff'}
          overlayInnerStyle={{
            fontFamily: 'Ubuntu',
            fontSize: '16px',
            color: '#000000',
          }}
        >
          {fileName}
        </Tooltip>
      </div>
    </div>
  );
};

// const FilePreview = ({ file, index, taskId }) => {
//   const { access } = useSelector((state) => state.addTask);
//   const [loading, setLoading] = useState(false);
//   const { url, progress } = file;
//   const fileName = url?.split("/").pop().split("_").slice(1).join("_");
//   const extension = getExtension(url);
//   const dispatch = useDispatch();

//   const downloadFile = (url) => {
//     setLoading(true);
//     setTimeout(() => {
//       setLoading(false);
//     }, 2000);
//     const link = document.createElement("a");
//     link.href = url;
//     link.download = url?.split("/").pop();
//     link.click();
//   };

//   const deleteFile = (url) => {
//     if (access === ACCESS.VIEW) {
//       return;
//     }
//     message.success("File deleted successfully");
//     const dataToSend = {
//       attachments: {
//         remove: [url],
//       },
//     };
//     dispatch(deleteAttachmentInTaskAPI(dataToSend, taskId));
//     dispatch({ type: "DELETE_ATTACHMENT_SUCCESS", payload: url });
//     // Implement your delete logic here
//     const fileName = url?.split("/").pop();
//     const s3 = new AWS.S3({
//       accessKeyId: "29f47bfdd00e24edb6aeabc87b53d5b2",
//       secretAccessKey: "f42d1fa109528f9dcbf3c430818f62e6",
//       endpoint: "https://sin1.contabostorage.com/",
//       s3ForcePathStyle: true,
//     });

//     const params = {
//       Bucket: "3rd-task-dev",
//       Key: fileName,
//     };

//     s3.deleteObject(params, (err, data) => {
//       if (err) {
//         console.error("Error deleting image:", err);
//       } else {
//         // message.success("File deleted successfully");
//         // const dataToSend = {
//         //   attachments: {
//         //     remove: [url],
//         //   },
//         // };
//         // dispatch(deleteAttachmentInTaskAPI(dataToSend, taskId));
//         // dispatch({ type: "DELETE_ATTACHMENT_SUCCESS", payload: url });
//       }
//     });
//   };

//   const containerStyles = {
//     position: "relative",
//     width: "150px",
//     height: "150px",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     border: "1px solid #ccc",
//     overflow: "hidden",
//     marginBottom: "10px",
//     marginRight: "10px",
//   };

//   const iconStyles = {
//     position: "absolute",
//     top: "10px",
//     left: "10px",
//     zIndex: "1",
//   };

//   const imageStyles = {
//     maxWidth: "100%",
//     maxHeight: "100%",
//     objectFit: "cover",
//   };

//   return (
//     <div key={index}>
//       <div style={containerStyles}>
//         {progress > 100 && (
//           <div style={iconStyles}>
//             <Button
//               type="primary"
//               loading={loading}
//               icon={<DownloadOutlined style={{ fontSize: "12px" }} />}
//               onClick={() => downloadFile(url)}
//               style={{
//                 width: "25px",
//                 height: "25px",
//                 padding: "0",
//                 marginRight: "5px",
//               }}
//             />
//             {access === ACCESS.EDIT && (
//               <Button
//                 danger
//                 icon={<DeleteOutlined style={{ fontSize: "12px" }} />}
//                 onClick={() => deleteFile(url)}
//                 style={{ width: "25px", height: "25px", padding: "0" }}
//               />
//             )}
//           </div>
//         )}
//         {progress > 100 ? (
//           <>
//             {["png", "jpg", "jpeg"].includes(extension) ? (
//               <Image
//                 style={imageStyles}
//                 src={url}
//                 preview={{
//                   toolbarRender: (
//                     _,
//                     {
//                       transform: { scale },
//                       actions: {
//                         onFlipY,
//                         onFlipX,
//                         onRotateLeft,
//                         onRotateRight,
//                         onZoomOut,
//                         onZoomIn,
//                         onReset,
//                       },
//                     },
//                   ) => (
//                     <Space size={12} className="toolbar-wrapper">
//                     </Space>
//                   ),
//                 }}
//               />
//             ) : extension === "pdf" ? (
//               <FilePdfOutlined style={{ fontSize: "68px", color: "#ff0000" }} />
//             ) : (
//               <FileOutlined style={{ fontSize: "68px", color: "#0000ff" }} />
//             )}
//           </>
//         ) : (
//           <div style={{ textAlign: "center" }}>
//             <Progress type="circle" percent={progress} />
//           </div>
//         )}
//       </div>
//       <div
//         style={{
//           textAlign: "center",
//           marginTop: "5px",
//           width: "150px",
//           whiteSpace: "normal",
//           wordWrap: "break-word",
//           fontWeight: "bold",
//         }}
//       >
//         {fileName}
//       </div>
//     </div>
//   );
// };
