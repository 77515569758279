import { message } from "antd";
import axios from "../../helpers/axios";
import { API_URL, dispatchToast } from "../../utils";
import { dispatchLoading } from "../../utils";
import {
  fetchAllTasksForAdminDashboardByStatusAPI,
  fetchAllTasksForProjectViewByListAPI,
} from "./fetchItemsAPI";

const BACKEND_API_URL = process.env.REACT_APP_API_URL;
export const reorderTaskWithinGroup = (data) => async (dispatch) => {
  axios
    .put(`${BACKEND_API_URL}/tasks/reorder`, data)
    .then((result) => {
      message.success("Task Reordered");
    })
    .catch((error) => {
      message.error(error);
    });
};

export const reorderListsHorizontal = (data) => async (dispatch) => {
  axios
    .put(`${BACKEND_API_URL}/dashboard/reorder`, data)
    .then((result) => {
      message.success("Task Reordered");
    })
    .catch((error) => {
      message.error(error);
    });
};

export const reorderTaskToOtherListHorizontally = (data) => (dispatch) => {
  axios
    .put(`${BACKEND_API_URL}/tasks/change-group`, data)
    .then((result) => {
      message.success("Changed category");
    })
    .catch((error) => {
      message.error(error);
    });
};

export const reorderTaskToOtherStatusHorizontally = (data) => (dispatch) => {
  axios
    .put(`${BACKEND_API_URL}/tasks/change-group`, data)
    .then((result) => {
      message.success("Changed category");
    })
    .catch((error) => {
      message.error(error);
    });
};

export const reorderChecklist =
  (data, mainChecklistId, startIndex, endIndex) => (dispatch) => {
    console.log("API called");
    axios
      .put(`${BACKEND_API_URL}/tasks/reorder-checklist-item`, data)
      .then((result) => {
        dispatch({
          type: "REORDER_CHECKLIST_ITEMS",
          payload: {
            mainChecklistId: mainChecklistId,
            startIndex: startIndex,
            endIndex: endIndex,
          },
        });
        message.success("Checklist reordered");
      })
      .catch((error) => {
        message.error(error);
      });
  };

export const moveChecklist =
  (
    data,
    sourceChecklistId,
    destinationChecklistId,
    checklistItemId,
    destinationIndex
  ) =>
  (dispatch) => {
    console.log("API called");
    axios
      .put(`${BACKEND_API_URL}/tasks/reorder-checklist-item`, data)
      .then((result) => {
        dispatch({
          type: "MOVE_CHECKLIST_ITEM",
          payload: {
            sourceChecklistId: sourceChecklistId,
            destinationChecklistId: destinationChecklistId,
            checklistItemId: checklistItemId,
            destinationIndex: destinationIndex,
          },
        });
        message.success("Checklist reordered");
      })
      .catch((error) => {
        message.error(error);
      });
  };
