import { useEffect, useRef, useState } from "react";
import DownArrow from "../../Assets/downArrow.svg";
import { Select } from "antd";
import { useSelector } from "react-redux";

const CustomDropDown = ({
  label,
  defaultValue,
  nameOfClass,
  type,
  accessType,
  setAccessType,
  nameOfClass2,
  nameOfClass3,
}) => {
  const [open, setOpen] = useState(true);
  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef?.current?.contains(e.target)) {
      setOpen(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={`${nameOfClass}`}>
      <div className="">
        <label htmlFor={label} className="ff ff_md f16 pb-1">
          {label}
        </label>
        <div
          className={`form-control d-flex justify-content-between ${nameOfClass3}`}
          onClick={(e) => setOpen(!open)}
        >
          <span
            className={`mb-0 ${
              nameOfClass2 ? "ff ff_lt txt-dark" : "ff ff_rg txt-dark"
            }`}
          >
            {/* {console.log("Access: ", accessType)} */}
            {accessType === "Edit" ? "Edit" : "View"}
          </span>
          <img src={DownArrow} alt="Down Arrow" className="img-fluid" />
        </div>
      </div>
      <div
        ref={myRef}
        className={`customDropdownData bg-white p-3 pb-0 mt-1 ms-3 ${
          open ? "" : "is-active"
        } position-absolute z-1 `}
      >
        {accessType &&
          ["View", "Edit"].map((ele, index) => {
            return (
              <label
                className={`customDropdownLabel d-flex pb-3 ${
                  type === ele ? "txt-secondary" : ""
                } cursor-pointer`}
                key={index}
              >
                <p
                  className="mb-0 ff_rg f14 d-block w-100"
                  data-type={ele}
                  onClick={(e) => {
                    if (type === e.target.getAttribute("data-type")) {
                      setOpen(!open);
                    }
                  }}
                >
                  {ele === "Edit" ? "Edit" : "View"}
                </p>
                <input
                  type={"radio"}
                  className="form-check-input d-inline"
                  id={ele === "Edit" ? "Edit" : "View"}
                  value={ele}
                  data-id={index}
                  name={"AccessType"}
                  onChange={(e) => {
                    setAccessType(e.target.value);
                    setOpen(!open);
                  }}
                />
              </label>
            );
          })}
      </div>
    </div>
  );
};

export default CustomDropDown;

export const SelectDropDown = ({
  labelText,
  dataList,
  placeHolderText,
  setToggle,
  toggle,
  name,
  state,
  setDataId,
  setState,
}) => {
  return (
    <div className="selectProjectName mx-3 position-relative mb-3">
      <label className="ff ff_md f16 pb-1">{labelText}</label>
      <div
        className="cancelLocation border-s minHeight46 px-3 d-flex align-items-center justify-content-between cursor-pointer text-truncate"
        onClick={(e) => {
          setToggle(!toggle);
        }}
      >
        <p className="ff ff_rg f14 mb-0 ">
          {state !== "" ? (
            state
          ) : (
            <span className="ff_lt txt-light-sm">{placeHolderText}</span>
          )}
        </p>
        <img src={DownArrow} alt="icon" className="img-fluid" />
      </div>
      <div
        className={`projectData border-s bg-white p-4 mt-1 ${
          toggle ? "is-active" : ""
        } customPopup pb-2`}
      >
        {dataList !== undefined &&
          dataList?.map((ele, index) => (
            <label
              htmlFor={ele?.name}
              key={index}
              className={`cursor-pointer projectLabel d-block ${
                state === ele?.name ? "is-active" : "not-active"
              }`}
            >
              <div className="d-flex">
                <div className="ps-1">
                  <p className="mb-0 ff ff_md f14">{ele?.name}</p>
                </div>
              </div>
              <input
                type={"radio"}
                className="form-check-input"
                id={ele?.name}
                value={ele?.id}
                name={name}
                onChange={(e) => {
                  setToggle(!toggle);
                  setDataId(e.target.value);
                  setState(e.target.id);
                }}
              />
            </label>
          ))}
      </div>
    </div>
  );
};

export const SelectDropDownAddTask = ({
  labelText,
  dataList,
  placeHolderText,
  setToggle,
  toggle,
  name,
  state,
  setDataId,
  setState,
  setProject,
}) => {
  return (
    <div className="selectProjectName mx-3 position-relative mb-3">
      <label className="ff ff_md f16 pb-1">{labelText}</label>
      <div
        className="cancelLocation border-s minHeight46 px-3 d-flex align-items-center justify-content-between cursor-pointer text-truncate"
        onClick={(e) => {
          setToggle(!toggle);
        }}
      >
        <p className="ff ff_rg f14 mb-0 ">
          {state !== "" ? (
            state
          ) : (
            <span className="ff_lt txt-light-sm">{placeHolderText}</span>
          )}
        </p>
        <img src={DownArrow} alt="icon" className="img-fluid" />
      </div>
      <div
        className={`projectData border-s bg-white p-4 mt-1 ${
          toggle ? "is-active" : ""
        } customPopup pb-2`}
      >
        {dataList !== undefined &&
          dataList?.map((ele, index) => (
            <label
              htmlFor={ele?.title}
              key={index}
              className={`cursor-pointer projectLabel d-block ${
                state === ele?.title ? "is-active" : "not-active"
              }`}
            >
              <div className="d-flex">
                <div className="ps-1">
                  <p className="mb-0 ff ff_md f14">{ele?.title}</p>
                </div>
              </div>
              <input
                type={"radio"}
                className="form-check-input"
                id={ele?.title}
                value={ele?._id}
                name={name}
                onChange={(e) => {
                  setToggle(!toggle);
                  setDataId(e.target.value);
                  setState(e.target.id);
                  // setProject();
                }}
              />
            </label>
          ))}
      </div>
    </div>
  );
};

export const SearchableSelect = ({
  existingMembers,
  setExistingMembers,
  selectedMember,
  setDropdownMember,
  divClass,
  dataList,
  dataId,
  labelText,
  initialValue,
  setFieldValue,
  error,
  // isModalOpen,
}) => {
  // const [searchValue, setSearchValue] = useState("");
  // const [options, setOptions] = useState(dataList);
  // const [selectedItems, setSelectedItems] = useState([]);
  let { isProjectOpen } = useSelector((state) => state.modal);
  // const filteredOptions = existingMembers.filter(
  //   (o) => !selectedItems.includes(o)
  // );

  const { Option } = Select;

  // const handleSearch = (value) => {
  //   setSearchValue(value);
  //   if (value) {
  //     const filteredOptions = dataList.filter((option) =>
  //       option.name.toLowerCase().startsWith(value.toLowerCase())
  //     );
  //     setExistingMembers(filteredOptions);
  //   }
  // };

  // useEffect(() => {
  //   if (!isProjectOpen) {
  //     setSelectedItems([]);
  //   }
  // }, [isProjectOpen]);

  // useEffect(() => {
  //   return setSelectedItems([]);
  // }, []);

  return (
    <div className={`${divClass} mt-2`}>
      <label htmlFor={`${dataId}`} className="ff f16 ff_md mb-1">
        {labelText}
      </label>
      <Select
        mode="tags"
        showSearch
        // defaultValue={initialValue || null}
        // value={selectedItems}
        onChange={setDropdownMember}
        id={`${dataId}`}
        placeholder={"Enter Name"}
        // style={{
        //   width: "100%",
        // }}
        style={{ maxHeight: "200px", overflowY: "auto", width: "100%" }}
        // className={``}
        // onSearch={handleSearch}
        // onChange={(e) => {
        //   setFieldValue(`${dataId}`, e);
        // }}
        // suffixIcon={null}
        // notFoundContent={null}
        // options={filteredOptions.map((item) => ({
        //   value: item,
        //   label: item,
        // }))}
      >
        {existingMembers.map((option, index) => (
          <Option key={index} value={option.id} className="ff f16 ff_rg">
            {`${option.name}`}
          </Option>
        ))}
      </Select>
      {error && (
        <small className="text-danger d-block">
          {error}
          &nbsp;
        </small>
      )}
    </div>
  );
};

export const DropdownForUsers = ({ data, dropdownName, setDropdownName }) => {
  const handleChange = (e) => {
    console.log(e.target.value);
  };
  return (
    <>
      <div class="form-group searchableSelect col-6 ms-4">
        <label for="input-datalist" className="ff f16 ff_md mb-1">
          Member
        </label>
        <input
          type="text"
          class="form-control"
          placeholder="Member"
          list="list-users"
          id="input-datalist"
          onChange={setDropdownName}
          value={dropdownName}
        />
        <datalist id="list-users">
          {data.map((user) => (
            <option className="ff f16 ff_rg">{user.name}</option>
          ))}
        </datalist>
      </div>
    </>
  );
};

export const CustomDropdownForUsers = ({
  data,
  dropdownName,
  setDropdownName,
}) => {
  const handleChange = (e) => {
    console.log(e.target.value);
  };

  return (
    <>
      <div
        class="form-group addMemberToTaskDropdown me-4"
        style={{ width: "600px" }}
      >
        <label for="input-datalist" className="ff ff_md f16 pb-1">
          Member
        </label>
        <input
          type="text"
          class="form-control"
          placeholder="Member"
          list="list-users"
          id="input-datalist"
          onChange={setDropdownName}
          value={dropdownName}
        />
        <datalist id="list-users">
          {data.map((user) => (
            <option key={user._id} className="ff f16 ff_rg">
              {user.name}
            </option>
          ))}
        </datalist>
      </div>
    </>
  );
};
