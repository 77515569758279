import axios from '../../helpers/axios';
import { API_URL, convertDateFormat, dispatchToast, ViewType } from '../../utils';
const BACKEND_API_URL = process.env.REACT_APP_API_URL;
export const fetchUsersByRole = () => async (dispatch) => {
  dispatch({
    type: 'SET_LOADING',
    payload: { scope: 'navbarUsers', status: true },
  });
  axios
    .get(`${BACKEND_API_URL}/users/users-by-role`)
    .then((response) => {
      if (response.data && response.data.usersByRole) {
        response.data.usersByRole.forEach((role) => {
          switch (role._id) {
            case 'project_manager':
              dispatch({
                type: 'FETCH_ALL_PROJECTMANAGER_SUCCESS',
                payload: role.users,
              });
              break;
            case 'client':
              dispatch({
                type: 'FETCH_ALL_CLIENTS_SUCCESS',
                payload: role.users,
              });
              break;
            case 'employee':
              dispatch({
                type: 'FETCH_ALL_EMPLOYEES_SUCCESS',
                payload: role.users,
              });
              break;
            default:
              break;
          }
        });
      }
      dispatch({
        type: 'SET_LOADING',
        payload: { scope: 'navbarUsers', status: false },
      });
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

export const fetchAllProjects = () => async (dispatch) => {
  dispatch({
    type: 'SET_LOADING',
    payload: { scope: 'navbarProjects', status: true },
  });
  axios
    .get(`${BACKEND_API_URL}/projects`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: 'FETCH_ALL_PROJECTS_SUCCESS',
          payload: response.data.projects,
        });
      }

      dispatch({
        type: 'SET_LOADING',
        payload: { scope: 'navbarProjects', status: false },
      });
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

//Fetch project details after selecting a particular project in add task modal 1
export const fetchProjectMembers = (id) => async (dispatch) => {
  dispatch({
    type: 'SET_LOADING',
    payload: { scope: 'fetchMembers', status: true },
  });
  axios
    .get(`${BACKEND_API_URL}/projects/${id}/team-members`)
    .then((response) => {
      if (response.data.data && response.data.data.teamMembers && response.data.data.teamMembers.length > 0) {
        dispatch({
          type: 'FETCH_MEMBERS_SUCCESS',
          payload: response.data.data.teamMembers,
        });
      } else {
        dispatch({
          type: 'FETCH_MEMBERS_SUCCESS',
          payload: [],
        });
      }
      dispatch({
        type: 'SET_LOADING',
        payload: { scope: 'fetchMembers', status: false },
      });
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

export const fetchProjectLists = (id) => async (dispatch) => {
  axios
    .get(`${BACKEND_API_URL}/lists/${id}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: 'FETCH_LISTS_SUCCESS_FOR_ADD_TASK',
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

export const fetchAllStatusAPI = () => async (dispatch) => {
  axios
    .get(`${BACKEND_API_URL}/status`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: 'FETCH_ALL_STATUS_SUCCESS',
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

export const fetchAllTasksForAdminDashboardByStatusAPI = () => async (dispatch) => {
  axios
    .get(`${BACKEND_API_URL}/dashboard/status-view`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: 'FETCH_ADMIN_DASHBOARD_DATA_SUCCESS',
          payload: response.data,
        });
      }
      dispatch({
        type: 'SET_LOADING',
        payload: { scope: 'fetchTasks', status: false },
      });
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

export const getAnalytics = () => async (dispatch) => {
  try {
    const response = await axios.get(`${BACKEND_API_URL}/analytics`);
    if (response.data) {
      return response.data.data;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

export const fetchAllTasksForEmployeeViewDashboardByStatusAPI = (employeeId, date) => async (dispatch) => {
  axios
    .get(`${BACKEND_API_URL}/dashboard/status-view`, {
      params: {
        member: employeeId,
        date: date,
      },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: 'FETCH_ADMIN_DASHBOARD_DATA_SUCCESS',
          payload: response.data,
        });
      }
      dispatch({
        type: 'SET_LOADING',
        payload: { scope: 'fetchTasks', status: false },
      });
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

export const fetchAllTasksForProjectViewByListAPI =
  (projectSlug, member, navigate, projectId, isUpdateReFetch, socket) => async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: { scope: 'fetchTasks', status: true },
    });
    // dispatch({ type: "SET_LOADING", payload: { scope: "fetchTasks", status: true } });
    axios
      .get(`${BACKEND_API_URL}/dashboard`, {
        params: {
          project: projectSlug,
          member: member,
        },
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: 'FETCH_TASKS_SUCCESS',
            payload: response.data,
          });
          if (isUpdateReFetch) {
            socket.emit('taskUpdateProject', projectId, response.data.data, false);
          }
        }
        dispatch({
          type: 'SET_LOADING',
          payload: { scope: 'fetchTasks', status: false },
        });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate('/unauthorized');
        }
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        // setTimeout(() => {
        //   dispatch({
        //     type: 'SET_LOADING',
        //     payload: { scope: 'fetchTasks', status: false },
        //   });
        // }, 0);
      });
  };

export const fetchProjectListsNew = (projectSlug, navigate) => async (dispatch) => {
  dispatch({
    type: 'SET_LOADING',
    payload: { scope: 'fetchNewLists', status: true },
  });
  // dispatch({ type: "SET_LOADING", payload: { scope: "fetchTasks", status: true } });
  axios
    .get(`${BACKEND_API_URL}/lists/${projectSlug}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: 'FETCH_PROJECT_LISTS_SUCCESS',
          payload: response.data,
        });
      }
      dispatch({
        type: 'SET_LOADING',
        payload: { scope: 'fetchNewLists', status: false },
      });
    })
    .catch((error) => {
      if (error.response.status === 403) {
        navigate('/unauthorized');
      }
      console.error('Error fetching data:', error);
    })
    .finally(() => {
      // setTimeout(() => {
      //   dispatch({
      //     type: 'SET_LOADING',
      //     payload: { scope: 'fetchTasks', status: false },
      //   });
      // }, 0);
    });
};

export const fetchListTasksNew = (listId, listTitle, projectDetails, searchMemberId, date) => async (dispatch) => {
  dispatch({
    type: 'SET_LOADING',
    payload: { scope: `fetchTasksNew-${listId}`, status: true },
  });
  axios
    .get(`${BACKEND_API_URL}/dashboard/tasks-by-lists/${listId}`, {
      params: {
        project: projectDetails.slug,
        member: searchMemberId ? searchMemberId : '',
        date: date ? convertDateFormat(date) : '',
      },
    })
    .then((response) => {
      if (response.data) {
        const data = {
          _id: listId,
          title: listTitle,
          project: {
            _id: projectDetails._id,
            title: projectDetails.title,
          },
          tasks: response.data.data,
        };
        dispatch({
          type: 'FETCH_LIST_TASKS_SUCCESS',
          payload: data,
        });
      }
      dispatch({
        type: 'SET_LOADING',
        payload: { scope: `fetchTasksNew-${listId}`, status: false },
      });
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    })
    .finally(() => {
      // setTimeout(() => {
      //   dispatch({
      //     type: 'SET_LOADING',
      //     payload: { scope: 'fetchTasks', status: false },
      //   });
      // }, 0);
    });
};

export const fetchStatusTasksNew =
  (statusId, title, color, searchMemberId, date, projectDetails) => async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: { scope: `fetchTasksNew-${statusId}`, status: true },
    });
    axios
      .get(`${BACKEND_API_URL}/dashboard/tasks-by-status/${statusId}`, {
        params: {
          project: projectDetails.slug,
          member: searchMemberId ? searchMemberId : '',
          date: date ? convertDateFormat(date) : '',
        },
      })
      .then((response) => {
        if (response.data) {
          const data = {
            _id: statusId,
            status: title,
            color: color,
            tasks: response.data.data,
          };
          dispatch({
            type: 'FETCH_STATUS_TASKS_SUCCESS',
            payload: data,
          });

          // if (response.data) {
          //   const data = {
          //     _id: listId,
          //     title: listTitle,
          //     project: {
          //       _id: projectDetails._id,
          //       title: projectDetails.title,
          //     },
          //     tasks: response.data.data,
          //   };
        }
        dispatch({
          type: 'SET_LOADING',
          payload: { scope: `fetchTasksNew-${statusId}`, status: false },
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        // setTimeout(() => {
        //   dispatch({
        //     type: 'SET_LOADING',
        //     payload: { scope: 'fetchTasks', status: false },
        //   });
        // }, 0);
      });
  };

export const fetchListApiForProject = (projectId) => async (dispatch) => {
  axios
    .get(`${BACKEND_API_URL}/lists/${projectId}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: 'FETCH_LISTS_SUCCESS',
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

export const fetchStatusApiForProject = (projectId) => async (dispatch) => {
  axios
    .get(`${BACKEND_API_URL}/status/${projectId}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: 'FETCH_PROJECT_ALL_STATUS_FOR_ADD_TASK_SUCCESS',
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

export const fetchTaskDetails = (taskId, navigate) => async (dispatch) => {
  dispatch({
    type: 'SET_LOADING',
    payload: { scope: 'fetchTaskForUpdate', status: true },
  });
  axios
    .get(`${BACKEND_API_URL}/tasks/${taskId}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: 'FETCH_TASK_DETAILS_FOR_TASK_DETAILS_MODAL',
          payload: response.data.data,
        });
      }
      // setTimeout(() => {
      //   dispatch({
      //     type: "SET_LOADING",
      //     payload: { scope: "fetchTaskForUpdate", status: false },
      //   });
      // }, 200);
      dispatch({
        type: 'SET_LOADING',
        payload: { scope: 'fetchTaskForUpdate', status: false },
      });
    })
    .catch((error) => {
      if (error.response.status === 403) {
        dispatch({ type: 'SANITIZE_ADD_TASK_STORE' });
        dispatch({ type: 'IS_STEP_ACTIVE', payload: false });
        dispatch({ type: 'CHANGE_STEP', payload: 0 });
        navigate('/unauthorized');
      }
      console.error('Error fetching data:', error);
    });
};

export const fetchUpdatedTask = (taskId, viewType, socket, projectId) => async (dispatch) => {
  axios
    .get(`${BACKEND_API_URL}/tasks/dashboard-view/${taskId}`)
    .then((response) => {
      if (response.data) {
        // dispatch({
        //   type: 'FETCH_TASK_DETAILS_FOR_TASK_DETAILS_MODAL',
        //   payload: response.data.data,
        // });
        // console.log(response.data);
        const taskData = response.data;
        socket.emit('updateSingleTask', projectId, taskData);
        if (viewType === ViewType.ListView) {
          const listID = taskData.listDetails._id;
          const taskID = taskData._id;
          const taskDataObject = taskData;
          dispatch({ type: 'REPLACE_UPDATED_TASK_LIST', payload: { listID, taskID, taskDataObject } });
        } else if (viewType === ViewType.StatusView) {
          const statusIds = taskData.statusDetails.map((status) => status._id);
          const taskId = taskData._id;
          const taskDataObject = taskData;
          dispatch({ type: 'REPLACE_UPDATED_TASK_STATUS', payload: { statusIds, taskId, taskDataObject } });
        }
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

export const fetchTaskForProjectViewByStatus =
  (projectSlug, member, projectId, isUpdateReFetch, socket) => async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: { scope: 'fetchTasks', status: true },
    });
    axios
      .get(`${BACKEND_API_URL}/dashboard/status-view`, {
        params: {
          project: projectSlug,
          member: member,
        },
      })
      .then((response) => {
        dispatch({
          type: 'FETCH_TASKS_FOR_STATUS_VIEW',
          payload: response.data,
        });
        if (isUpdateReFetch) {
          socket.emit('taskUpdateProject', projectId, response.data.data, true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({
          type: 'SET_LOADING',
          payload: { scope: 'fetchTasks', status: false },
        });
      });
  };

export const fetchTaskForProjectViewByStatusNew = (projectSlug) => async (dispatch) => {
  dispatch({
    type: 'SET_LOADING',
    payload: { scope: 'fetchNewLists', status: true },
  });
  axios
    .get(`${BACKEND_API_URL}/status/${projectSlug}`)
    .then((response) => {
      dispatch({
        type: 'FETCH_TASKS_FOR_STATUS_VIEW',
        payload: response,
      });
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      dispatch({
        type: 'SET_LOADING',
        payload: { scope: 'fetchNewLists', status: false },
      });
    });
};

export const getAnalyticsStatusAPI = () => async (dispatch) => {
  try {
    const response = await axios.get(`${BACKEND_API_URL}/status/categorized-status-titles`);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting data:', error.response.data.message);
    // message.error(error.response.data.message);
    return null;
  }
};
